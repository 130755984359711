import {CpmTaskModel} from "../../../../models/responses/cpm-task.model";
import {convertDateToIndex, convertIndexToSeconds} from "../../cpm-app/functions/handleEvent";
import TaskStatusModel from "../../../../models/responses/task-status.model";

export default function forecastTasks (
    racingLinePoints: any,
    incompleteTasks: {cal_id: CpmTaskModel[]},
    calendarMap: Map<string, any>,
) {
    const timeStart = new Date().getTime();
    let incompleteTasksOut: CpmTaskModel[] = []
    for (let key in racingLinePoints) {
        let points = racingLinePoints[key];
        let tasks = incompleteTasks[key];
        if (points[points.length - 1][1] === 0) continue;
        for (let i = 0; i < points.length - 1; i++) {
            const point = points[i];
            const nextPoint = points[i + 1];
            const yDistance = nextPoint[1] - point[1];
            const pointIndex = convertDateToIndex(point[0], key, calendarMap);
            const nextPointIndex = convertDateToIndex(nextPoint[0], key, calendarMap);
            const xDistance = nextPointIndex - pointIndex;
            if (xDistance === 0) continue;
            let totalTaskForecast = 0;
            let j= 0
            let tasksToForecast: CpmTaskModel[] = []
            for (let task of tasks) {
                j++;
                totalTaskForecast += task.duration;
                tasksToForecast.push(task)
                if (totalTaskForecast > yDistance) {
                    tasks = tasks.slice(j)
                    break;
                }
            }
            let k = 0;
            for (let task of tasksToForecast) {
                k++;
                //@ts-ignore
                const forecastFinish = Math.min(Math.floor(pointIndex + ((nextPointIndex - pointIndex) * (k / tasksToForecast.length))), task.lf);
                //@ts-ignore
                const forecastStart = Math.max(forecastFinish - task.duration, task.es);
                task.fs = forecastStart;
                task.ff = forecastStart + task.duration;
                incompleteTasksOut.push(task)
            }

        }
    }

    console.log('forecastTasks', new Date().getTime() - timeStart, 'ms')
    return incompleteTasksOut.sort((a, b) => convertIndexToSeconds(a.ls, a.cal_id, calendarMap) - convertIndexToSeconds(b.ls, b.cal_id, calendarMap))
}