import firebase from 'firebase/app';
import * as firestore from "firebase/firestore";
import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  Timestamp,
  doc,
  runTransaction,
  writeBatch,
  getDoc,
  query,
  getDocs,
  where,
  limit,
  updateDoc,
  setDoc,
  orderBy,
  deleteDoc,
  collection,
  connectFirestoreEmulator,
  addDoc,
  onSnapshot,
} from 'firebase/firestore';
import {
  getAuth,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  connectAuthEmulator,
  signInWithRedirect,
  getRedirectResult,
  signInWithPopup,
  signInWithCredential,
  signOut,
  GoogleAuthProvider,
  OAuthProvider,
} from 'firebase/auth';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { getMessaging, onMessage } from 'firebase/messaging';
import * as messaging from 'firebase/messaging';
import { getInstallations, getToken } from 'firebase/installations';
import {getStorage, ref, uploadBytes, getBytes, getDownloadURL, deleteObject } from 'firebase/storage';

import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/auth';
import 'firebase/messaging';
import 'firebase/installations';
import 'firebase/performance';

import firebaseConfig from '../config/firebase.config';
import {SmartRef, SmartTransaction} from "./helpers";
import ProjectFileModel from "../models/responses/project-file.model";
import ImageModel from "../models/responses/image.model";
import {COLLECTIONS} from "./constants";
import TaskModel from '../models/responses/task.model';


export default class FirebaseUsage {
  public static app: firebase.FirebaseApp = initializeApp(firebaseConfig);

  public static functions(functionName: string, options?: any) {
    if (!options) options = {timeout: 300000};
    return httpsCallable(getFunctions(FirebaseUsage.app), functionName, options);
  }

  public static functionsNoCors(functionName: string, options?: any) {
    if (!options) options = {timeout: 300000, headers: {'Access-Control-Allow-Origin': '*'}, mode: 'no-cors'};
    return httpsCallable(getFunctions(FirebaseUsage.app), functionName, options);
  }

  public static auth() {
    return getAuth(FirebaseUsage.app);
  }

  public static signOutOfAuth() {
    return signOut(FirebaseUsage.auth());
  }

  public static signInWithRedirectMS() {
    const provider = new OAuthProvider('microsoft.com');
    return signInWithRedirect(FirebaseUsage.auth(), provider).catch((error) => console.error(error));
  }

  public static getRedirectResultMS() {
    return getRedirectResult(FirebaseUsage.auth())
  }

  public static signInWithPopupMS() {
    const provider = new OAuthProvider('microsoft.com');
    return signInWithPopup(FirebaseUsage.auth(), provider)
  }

  public static signInWithCredentialMS(result) {
    const credential = OAuthProvider.credentialFromResult(result);
    return credential ? signInWithCredential(FirebaseUsage.auth(), credential) : new Promise(() => "No credential found");
  }

  public static getCredentialFromResultMS(result) {
    return OAuthProvider.credentialFromResult(result);
  }

  public static signInWithPopupGoogle() {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(FirebaseUsage.auth(), provider);
  }

  public static sendSignInLinkToEmail(email: string, actionCodeSettings: any) {
    return sendSignInLinkToEmail(FirebaseUsage.auth(), email, actionCodeSettings);
  }

  public static isSignInWithEmailLink(email: string) {
    return isSignInWithEmailLink(FirebaseUsage.auth(), email);
  }

  public static signInWithEmailLink(email: string, link: string) {
    return signInWithEmailLink(FirebaseUsage.auth(), email, link);
  }

  public static database() {
    return getFirestore(FirebaseUsage.app);
  }

  public static storage() {
    return getStorage(FirebaseUsage.app, `gs://${firebaseConfig.storageBucket}`);
  }

  public static uploadFileToStorage(path: string, file: ArrayBuffer) {
    const storageRef = ref(FirebaseUsage.storage(), path);
    return uploadBytes(storageRef, file);
  }

  public static async downloadFileFromStorage(file: ProjectFileModel) {
    const storageRef = ref(FirebaseUsage.storage(), file.storagePath);
    const bytes = await getBytes(storageRef);
    let blob = new Blob([bytes], {type: file.type});
    let url = URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = file.fileName;
    a.click();
  }

  public static async downloadImageFromStorage(file: ImageModel) {
    const storageRef = ref(FirebaseUsage.storage(), file.storagePath);
    const bytes = await getBytes(storageRef);
    let blob = new Blob([bytes], {type: file.type});
    let url = URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = file.fileName;
    a.click();
  }

  public static async downloadImageAsBlob(file: ImageModel | {storagePath: string, type: string}) {
    const storageRef = ref(FirebaseUsage.storage(), file.storagePath);
    const bytes = await getBytes(storageRef);
    const blob = new Blob([bytes], {type: file.type});
    return URL.createObjectURL(blob);
  }

  public static async downloadFileWithURL(file: ProjectFileModel) {
    const storageRef = ref(FirebaseUsage.storage(), file.storagePath);
    const url = await getDownloadURL(storageRef);
    const xhr = new XMLHttpRequest();

    xhr.responseType = 'blob';
    xhr.onload = (event) => {
      const blob = xhr.response;
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.fileName;
      a.click();
    };
    xhr.open('GET', url);
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.send()
  }

  public static async getDownloadURLFromStorage(path: string) {
    const storageRef = ref(FirebaseUsage.storage(), path);
    return await getDownloadURL(storageRef);
  }

  public static async deleteFileFromStorage(path: string) {
    const storageRef = ref(FirebaseUsage.storage(), path);
    return await deleteObject(storageRef);
  }


  public static updateDBSettings(setting: {}) {
    getFirestore(FirebaseUsage.app);
  }

  public static async getDoc(collectionRef: string, id) {
    const doc = FirebaseUsage.doc(collectionRef, id);
    return getDoc(doc);
  }

  public static async getDocWithRef(doc: firestore.DocumentReference<unknown>) {
    return getDoc(doc);
  }

  public static async getDocsFromQuery(query: firestore.Query<unknown>) {
    return getDocs(query);
  }

  public static getBlankDoc(collectionRef: string) {
    return doc(collection(FirebaseUsage.database(), collectionRef));
  }

  public static async getDocFromSubCollection(collectionRef, id, collectionRef2, id2) {
    return getDoc(doc(FirebaseUsage.database(), collectionRef, id, collectionRef2, id2));
  }

  public static getBlankDocFromSubCollection(collectionRef, docId, subCollection) {
    return doc(collection(FirebaseUsage.database(), collectionRef, docId, subCollection));
  }

  public static async getCollection(collectionRef: string) {
    return getDocs(collection(FirebaseUsage.database(), collectionRef));
  }

  public static async getSubCollection(collectionRef: string, id: string, collectionRef2: string) {
    return getDocs(collection(FirebaseUsage.database(), collectionRef, id, collectionRef2));
  }

  public static async updateDoc(collectionRef: string, id: string, data: any) {
    const doc = FirebaseUsage.doc(collectionRef, id);
    return updateDoc(doc, data);
  }

  public static updateUserStatus(collectionRef: string, id: string, status: boolean) {
    const docRef = FirebaseUsage.doc(collectionRef, id);
    return updateDoc(docRef, {
      live: status,
      lastViewed: FirebaseUsage.timestamp()
    });
  }

  public static async deleteDocument(collectionRef, id) {
    const doc = FirebaseUsage.doc(collectionRef, id);
    return deleteDoc(doc);
  }

  public static async deleteDocumentFromSubCollection(collectionRef, id, collectionRef2, id2) {
    const docToDelete = doc(FirebaseUsage.database(), collectionRef, id, collectionRef2, id2)
    return deleteDoc(docToDelete);
  }

  public static async setDocument(collection, id, data, options?) {
    const docRef = FirebaseUsage.doc(collection, id);
    return setDoc(docRef, data, options);
  }

  public static async setDocumentWithDoc(doc, data) {
      return setDoc(doc, data);
  }

  public static async addDocument(collectionRef, data) {
    return addDoc(collection(FirebaseUsage.database(), collectionRef), data);
  }

  public static async getDocWithSubCollection(collectionRef, id, collectionRef2) {
    return getDoc(doc(FirebaseUsage.database(), collectionRef, id, collectionRef2));
  }

  public static async getQuery(collectionRef, condition) {
    return getDocs(query(collection(FirebaseUsage.database(), collectionRef),
        where(condition[0], condition[1], condition[2])));
  }

  public static async getCompoundQuery(collectionRef, conditions) {
    const queries = conditions.map(condition => where(condition[0], condition[1], condition[2]));
    return getDocs(query(collection(FirebaseUsage.database(), collectionRef), ...queries));
  }

  public static async getCompoundQueryAsQuery(collectionRef, conditions) {
    const queries = conditions.map(condition => where(condition[0], condition[1], condition[2]));
    return query(collection(FirebaseUsage.database(), collectionRef), ...queries);
  }

  public static async getQueryWithOrder(collectionRef, condition, order) {
    return getDocs(query(collection(FirebaseUsage.database(), collectionRef),
        where(condition[0], condition[1], condition[2]), orderBy(order[0], order[1])));
  }

  public static async getQueryWithOrderAndLimit(collectionRef, conditions, order, limitNumber) {
    const queries = conditions.map(condition => where(condition[0], condition[1], condition[2]))
    return getDocs(query(collection(FirebaseUsage.database(), collectionRef),
        ...queries, orderBy(order[0], order[1]), limit(limitNumber)));
  }

  public static async getQueryWithLimit(collectionRef, condition, limitNumber) {
    return getDocs(query(collection(FirebaseUsage.database(), collectionRef),
        where(condition[0], condition[1], condition[2]), limit(limitNumber)));
  }

  public static async queryListener(collectionRef, conditions, callback) {
    const queries = conditions.map(condition => where(condition[0], condition[1], condition[2]));
    const q = query(collection(FirebaseUsage.database(), collectionRef), ...queries);
    return onSnapshot(q, callback);
  }

  public static async queryListenerWithOrder(collectionRef: string, conditions: any, order, callback: any) {
      const queries = conditions.map(condition => where(condition[0], condition[1], condition[2]));
      const q = query(collection(FirebaseUsage.database(), collectionRef), ...queries, orderBy(order[0], order[1]));
      return onSnapshot(q, callback);
  }

  public static async queryListenerRef(query, callback) {
      return onSnapshot(query, callback);
    }

  public static async documentListener(collectionRef: string, id: string, callback) {
    const docRef = FirebaseUsage.doc(collectionRef, id);
    return onSnapshot(docRef, callback);
  }

  public static runTransaction<T>(transaction: (transaction) => Promise<T>) {
    return runTransaction(FirebaseUsage.database(), transaction);
  }

  public static batch() {
    return writeBatch(FirebaseUsage.database());
  }

  public static doc(collectionRef: string, id: string) {
    return doc(FirebaseUsage.database(), collectionRef, id);
  }

  public static getDocumentRef(collectionRef: string, id: string) {
    return doc(FirebaseUsage.database(), collectionRef, id);
  }

  public static getSmartRef(smartRef: SmartRef) {
    return typeof smartRef === 'string' ? firestore.doc(FirebaseUsage.database(), smartRef) : smartRef;
  }

  public static messaging() {
    return getMessaging(FirebaseUsage.app);
  }

  public static getTokenFromMessaging(vapidKey?: {}) {
    return messaging.getToken(FirebaseUsage.messaging(), vapidKey)
  }

  public static onMessage(payload: any) {
    return onMessage(FirebaseUsage.messaging(), payload);
  }

  public static installations() {
    return getInstallations(FirebaseUsage.app);
  }

  public static getToken() {
    return getToken(FirebaseUsage.installations());
  }

  public static timestamp(date?) {
    const d = date ? date : new Date();
    return Timestamp.fromDate(d);
  }

  public static useFunctionsEmulator() {
    return connectFunctionsEmulator(getFunctions(FirebaseUsage.app), "localhost", 5001);
  }

  public static useDatabaseEmulator() {
    return connectFirestoreEmulator(FirebaseUsage.database(), "localhost", 8080);
  }

  public static useAuthEmulator() {
    return connectAuthEmulator(FirebaseUsage.auth(), "http://localhost:9099");

  }
}
