import React, {useState} from 'react';
import {useProjectMemberListSelector} from "../../../../../store/selectors/team.selectors";
import UserImage from "../../../../../images/UserImage";
import {useUserSelector} from "../../../../../store/selectors/authorization.selectors";
import RecentInput from "./RecentUserInput";

export default function OnlineUsers() {
    const projectMembers = useProjectMemberListSelector();
    const user = useUserSelector();
    const [tooltipText, setTooltipText] = useState<any>(null);
    const [tooltipPosition, setTooltipPosition] = useState<any>(null);
    const [userClicked, setUserClicked] = useState<any>(null);

    const onlineMembers = projectMembers.filter(member => member.live
        && member.userId !== user?.userId
    )

    return (
        <div style={{display: "flex", flexDirection: "row", marginLeft: "15px"}}>
            {
                onlineMembers.map((member) => (
                <div key={`online-${member.memberId}`} style={{marginLeft: "-5px"}}
                     onClick={() => setUserClicked(member)}
                     onMouseOver={(e) => {
                         setTooltipText(`${member.userEmail} is here`)
                         setTooltipPosition({top: e.clientY + 20, left: e.clientX})
                     }} onMouseOut={() => setTooltipText(null)}>
                    <UserImage user={member} width={28} height={28} key={member.userId} />
                </div>)
                )
            }
            {tooltipText && onlineMembers.length > 0 &&
                <div style={{position: "fixed",
                    marginLeft: "10px",
                    top: tooltipPosition.top,
                    left: tooltipPosition.left,
                    backgroundColor: "white",
                    border: "1px solid #d0cece",
                    padding: "10px",
                    borderRadius: "5px",
                    zIndex: 401,
                }}>
                    {tooltipText}
                </div>
            }
            {userClicked && <RecentInput member={userClicked} setOpen={setUserClicked}/>}
        </div>
    )
}
