import { Dispatch, MiddlewareAPI } from "redux";
import { RootStateModel } from "../../reducers";
import * as projectActions from '../../actions/project.actions';
import * as taskActions from '../../actions/task.actions';
import * as searchActions from '../../actions/search.actions';
import { subsManager } from "../subs-manager/subs-manager";
import { matchPath } from "react-router";
import FirebaseUsage from "../../../firebase/firebase.usage";
import {COLLECTIONS} from "../../../firebase/constants";


export function projectMiddleware(api: MiddlewareAPI<Dispatch, RootStateModel>) {
    return (next: Dispatch) => (action: projectActions.Actions) => {
        const returnValue = next(action);

        switch (action.type) {
            case projectActions.ActionNames.SET_PROJECT_LIST: {
                // console.log("doing project middleware")
                const projectList = api.getState().project.projectList;
                const matchPathname = matchPath( {path: "/:project_id/flow/:task_id"}, window.location.pathname);
                const params: any = matchPathname ? matchPathname.params : {}
                const activeProjectId = params.project_id || localStorage.getItem('projectId')
                const activeProject = activeProjectId ? projectList.find(el => el.projectId === activeProjectId) : null;

                api.dispatch(taskActions.Actions.taskListLoading());
                if (activeProject) {
                    api.dispatch(projectActions.Actions.setActiveProjectId(activeProjectId));
                } else {
                    api.dispatch(projectActions.Actions.setActiveProject(null));
                }
                break;
            }
            case projectActions.ActionNames.SET_ACTIVE_PROJECT_ID: {
                const activeProjectId = action.payload.projectId;
                const projectList = api.getState().project.projectList;
                const prevActiveProject = api.getState().project.activeProject;
                const statuses = api.getState().search.chipsList.filter(chip => chip.type === 'status').map(el => el.text);
                const searchParameters = api.getState().search.parameters;
                const projectMember = api.getState().team.projectMember;
                const hasProjectChanged = !api.getState().project.activeProject || api.getState().project.activeProject!.projectId !== activeProjectId
                let userName = api.getState().authorization.user?.displayName || api.getState().authorization.user?.userEmail || 'User';
                if (userName.split(' ').length > 1) {
                    userName = userName.split(' ')[0];
                }

                if (activeProjectId) {
                    localStorage.setItem('projectId', activeProjectId);
                }

                const activeProject = activeProjectId ? projectList.find(el => el.projectId === activeProjectId) : null;

                if (hasProjectChanged) {
                    if (api.getState().project.view !== 'task') {
                        api.dispatch(projectActions.Actions.setView('task'));
                    }
                    api.dispatch(taskActions.Actions.setActiveTask(null));
                    api.dispatch(taskActions.Actions.setTaskListCount(0));
                    api.dispatch(projectActions.Actions.setCpmComplete(false))
                    api.dispatch(searchActions.SearchActions.resetAllFilters());

                    api.dispatch(projectActions.Actions.setActiveProject(activeProject));
                    api.dispatch(projectActions.Actions.setChatLog([{role: 'assistant', content: `Hi ${userName}! What do you want to know about ${activeProject?.name}?`}]));
                    api.dispatch(projectActions.Actions.setChatInitialised(false));
                    api.dispatch(projectActions.Actions.setVectorStore(null));

                    if (prevActiveProject && activeProject && (prevActiveProject.projectId === activeProject.projectId)) {
                        break;
                    }

                    if (projectMember) FirebaseUsage.updateUserStatus(COLLECTIONS.PROJECT_MEMBERS, projectMember.memberId, false).catch((err) => console.log(err));
                    subsManager.removeTaskListSubscription('in-progress-task-list');
                    subsManager.removeTaskListSubscription('declared-complete-task-list');
                    subsManager.removeTaskListSubscription('confirmed-complete-task-list');
                    subsManager.removeTaskListSubscription('queued-task-list');
                    subsManager.removeTaskListSubscription('pending-task-list')

                    console.log('Unsubscribing from project members, pending members, reports and relationships');
                    subsManager.removeSubscription('member-list');
                    subsManager.removeSubscription('report-list');
                    subsManager.removeSubscription('relationships-list');
                    subsManager.removeSubscription('tracked-milestones');
                    subsManager.removeSubscription('wbs-list');
                    subsManager.removeSubscription('geo-data')
                    subsManager.removeSubscription('project-member');
                    subsManager.removeSubscription('pending-members');
                    // subsManager.removeSubscription('data-versions');

                    if (activeProjectId && activeProject) {
                        // const parameters = {
                        //     project_id: activeProjectId,
                        //     page: api.getState().task.page,
                        //     taskForces: searchParameters.taskForces,
                        //     statuses,
                        //     alert: searchParameters.alert
                        // };
                        // subsManager.subscribeInProgressTaskList(parameters).catch((err) => console.log(err));
                        // subsManager.subscribeConfirmedCompleteTaskList(parameters).catch((err) => console.log(err));
                        // subsManager.subscribeDeclaredCompleteTaskList(parameters).catch((err) => console.log(err));
                        // subsManager.subscribeQueuedTaskList(parameters).catch((err) => console.log(err));
                        // subsManager.subscribePendingTaskList(parameters).catch((err) => console.log(err));
                        subsManager.subscribeTrackedMilestones(activeProjectId);
                        subsManager.subscribeWbsList(activeProjectId);
                        subsManager.subscribeGeoData(activeProjectId);
                        subsManager.subscribeProjectMember(activeProjectId, api.getState().authorization.user?.userId || '');
                        if (activeProject.contractId) {
                            subsManager.subscribeDataVersions(activeProject.contractId);
                        }
                        console.log('Subscribing to project members, pending members, reports and relationships');
                        subsManager.subscribeMemberList(activeProjectId)
                            .then(() => subsManager.subscribeAllTasklist(activeProjectId).catch((err) => console.log(err)))
                            .catch((err) => console.log(err));
                        subsManager.subscribePendingMemberList(activeProjectId);
                        subsManager.subscribeReportList(activeProjectId);
                        setTimeout(() => {
                            subsManager.subscribeRelationshipsList(activeProjectId);
                        }, 1000);
                    }
                }
                break;
            }
            default: {
                break;
            }
        }

        return returnValue
    }
}
