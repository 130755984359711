import { useTypedSelector } from "./selectors.utils";
import { Maybe } from "@martin_hotell/rex-tils";
import { UserDocModel } from "../../models/responses/user.model";
import { MemberOrganizationEnum } from "../../models/member-organization.enum";
import TaskModel from "../../models/responses/task.model";
import { createSelector } from "reselect";
import { GateKeeperEum } from "../../models/gate-keeper.enum";

export const useUserAsMemberMapSelector = () =>
  useTypedSelector((state) => state.authorization.membersMap);

export const useUserAsMemberForActiveProjectSelector = () =>
  useTypedSelector(
    (state) =>
      state.authorization.membersMap[state.project.activeProject!.projectId]
  );

const userGradeSelector = createSelector(
  [(state) => state.membersMap, (state) => state.activeProject],
  (membersMap, activeProject) => {
    const grade = {};
    if (activeProject) {
      const member = membersMap[activeProject.projectId];
      Object.keys(MemberOrganizationEnum).forEach((key) => {
        grade[MemberOrganizationEnum[key]] = !!(
          member && member.grade === MemberOrganizationEnum[key]
        );
      });
    }
    return grade as { [key in MemberOrganizationEnum]: boolean };
  }
);

export function useUserGradeSelector() {
  const membersMap = useTypedSelector(
    (state) => state.authorization.membersMap
  );
  const activeProject = useTypedSelector(
    (state) => state.project.activeProject
  );
  return userGradeSelector({ membersMap, activeProject });
}

const userGateKeeperSelector = createSelector(
  [(state) => state.membersMap, (state) => state.activeProject],
  (membersMap, activeProject) => {
    const gateKeeper = {};
    if (activeProject) {
      const member = membersMap[activeProject.projectId];
      Object.keys(GateKeeperEum).forEach((key) => {
        gateKeeper[GateKeeperEum[key]] = !!(
          member && member.gateKeeper === GateKeeperEum[key]
        );
      });
    }
    return gateKeeper as { [key in GateKeeperEum]: boolean };
  }
);

export function useUserGateKeeperSelector() {
  const membersMap = useTypedSelector(
    (state) => state.authorization.membersMap
  );
  const activeProject = useTypedSelector(
    (state) => state.project.activeProject
  );
  return userGateKeeperSelector({ membersMap, activeProject });
}

export const useUserSelector = () =>
  useTypedSelector<Maybe<UserDocModel>>((state) => state.authorization.user);
export const isUserMemberOfCurrentTaskSelector = createSelector(
  [
    (state) => state.task,
    (state) => state.taskId,
    (state) => state.type,
    (state) => state.user,
  ],
  (task, taskId, type, user) => {
    const taskList = task.tasks
    if (type) {
      if (type === "pred") {
        task = task.predecessors.list.find((task) => task.task_id === taskId);
      } else if (type === "succ") {
        task = task.successors.list.find((task) => task.task_id === taskId);
      }
    } else {
      task = taskList.find((task) => task.task_id === taskId);
    }

    if (user && task) {
      return task.taskForce.some((el) => el === user.userId);
    }
    return false;
  }
);

export function useIsUserMemberOfCurrentTaskSelector(
  taskId: string,
  type?: string
) {
  const authorization = useTypedSelector((state) => state.authorization);
  const task = useTypedSelector((state) => state.task);
  return authorization.user
    ? isUserMemberOfCurrentTaskSelector({
        task: task,
        taskId: taskId,
        type: type,
        user: authorization.user,
      })
    : false;
}
