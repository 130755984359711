import * as searchActions from '../../actions/search.actions';
import { SearchActions } from '../../actions/search.actions';
import { Chip, ReportItem } from "../../store.types";
import { createReducer } from "typesafe-actions";


export class SearchParametersModel {
    alert = false;
    taskForces: (string | null)[] = [];
    chipList: Chip[] = [];
    searchText = ''
    startDate: number | null = null;
    finishDate: number | null = null;
    includeMilestones = false;
    noTaskForce = false;
}

export class StateModel {
    searchValue = '';
    loading = false;
    chipsList: Chip[] = [];
    reportList: ReportItem[] = [];
    parameters = new SearchParametersModel();
    mapSearchText = '';
}


export const reducer = createReducer<StateModel, searchActions.Actions>(new StateModel())
    .handleAction(SearchActions.setSearchValue, (state, action) => ({
        ...state,
        parameters: {
            ...state.parameters,
            searchText: action.payload.toLowerCase()
        }
    }))
    .handleAction(SearchActions.setReportList, (state, action) => ({
        ...state,
        reportList: action.payload
    }))
    .handleAction(SearchActions.setTaskforceFilter, (state, action) => {
        // console.log('state setTaskforceFilter ', state)
        // console.log('action setTaskforceFilter ', action)
        return {
            ...state,
            parameters: {
                ...state.parameters,
                taskForces: action.payload,
                noTaskForce: false
            }
        }
    }).handleAction(SearchActions.removeIdFromTaskforecFilter, (state, action) => {
        const removeIndexes = action.payload.map(el => state.parameters.taskForces.findIndex(id => id === el));
        const newArr = [...state.parameters.taskForces];

        removeIndexes.forEach(el => {
            newArr.splice(el, 1);
        });

        return {
            ...state,
            parameters: {
                ...state.parameters,
                taskForces: [...newArr]
            }
        };
    })
    .handleAction(SearchActions.toggleAlert, (state, action) => ({
        ...state,
        parameters: {
            ...state.parameters,
            alert: !state.parameters.alert
        }
    }))
    .handleAction(SearchActions.setSearchBarLoading, (state, action) => ({
        ...state,
        loading: action.payload
    }))
    .handleAction(SearchActions.addChip, (state, action) => ({
        ...state,
        parameters: {
            ...state.parameters,
            chipList: [...state.parameters.chipList, ...action.payload],
            includeMilestones: true
        }
    }))
    .handleAction(SearchActions.removeChip, (state, action) => {
        const newChipArr = [...state.parameters.chipList];
        const removeChipIndex = newChipArr.findIndex(el => el.text.toLowerCase() === action.payload.toLowerCase());

        newChipArr.splice(removeChipIndex, 1);

        return {
            ...state,
            parameters: {
                ...state.parameters,
                chipList: [...newChipArr]
            }
        }
    })
    .handleAction(SearchActions.removeAllChip, (state, action) => {
        return {
            ...state,
            parameters: {
                ...state.parameters,
                chipList: []
            }
        }
    })
    .handleAction(SearchActions.setReport, (state, action) => {
        return {
            ...state,
            parameters: {
                ...state.parameters,
                ...action.payload
            }
        }
    })
    .handleAction(SearchActions.setStartDate, (state, action) => {
        return {
            ...state,
            parameters: {
                ...state.parameters,
                startDate: action.payload
            }
        }
    })
    .handleAction(SearchActions.setFinishDate, (state, action) => {
        return {
            ...state,
            parameters: {
                ...state.parameters,
                finishDate: action.payload
            }
        }
    })
    .handleAction(SearchActions.setMapSearchText, (state, action) => {
        return {
            ...state,
            mapSearchText: action.payload
        }
    })
    .handleAction(SearchActions.resetAllFilters, (state, action) => {
        return {
            ...state,
            parameters: new SearchParametersModel(),
            mapSearchText: '',
            chipsList: [],
            loading: false,
            searchValue: '',
            reportList: []
        }
    })
    .handleAction(SearchActions.setIncludeMilestones, (state, action) => {
        return {
            ...state,
            parameters: {
                ...state.parameters,
                includeMilestones: action.payload
            }
        }
    })
    .handleAction(SearchActions.setNoTaskForceFilter, (state, action) => {
        return {
            ...state,
            parameters: {
                ...state.parameters,
                noTaskForce: action.payload,
                taskForces: action.payload ? [] : state.parameters.taskForces
            }
        }
    })



// export function reducer(state = initialState, action: searchActions.Actions) {
//     switch (action.type) {
//         case searchActions.ActionNames.SET_SEARCH_VALUE: {
//             return {
//                 ...state,
//                 searchValue: action.payload.value
//             }
//         }
//         case searchActions.ActionNames.SET_SEARCH_BAR_LOADING: {
//             return {
//                 ...state,
//                 loading: action.payload.state,
//             }
//         }
//         case searchActions.ActionNames.SET_SEARCH_BAR_CHIP: {
//             const isExist = state.chipsList.some(chip => action.payload.chips.some(el => chip.text === el.text));
//             return {
//                 ...state,
//                 searchValue: '',
//                 chipsList: isExist ? state.chipsList : [...state.chipsList, ...action.payload.chips.map(chip => ({
//                     type: chip.type,
//                     text: chip.text,
//                     value: chip.text,
//                 }))],
//             }
//
//         }
//         case searchActions.ActionNames.ONLY_MY_TASKS_TOGGLE: {
//             return {
//                 ...state,
//                 onlyMyTask: !state.onlyMyTask
//             }
//         }
//         case searchActions.ActionNames.REMOVE_SEARCH_BAR_CHIP: {
//             const removalFilterIndexes = Array.isArray(action.payload.name)
//                 ? action.payload.name.map(id => state.chipsList.findIndex(el => el.text === id))
//                 : [state.chipsList.findIndex(el => el.text === action.payload.name)];
//             const newFilterItem = [...state.chipsList];
//             removalFilterIndexes.forEach(index => {
//                 newFilterItem.splice(index, 1);
//             });
//
//             console.log({newFilterItem, removalFilterIndexes});
//
//             return {
//                 ...state,
//                 chipsList: [...newFilterItem]
//             }
//         }
//         case searchActions.ActionNames.REMOVE_ALL_CHIPS: {
//             return {
//                 ...state,
//                 chipsList: []
//             }
//         }
//         case searchActions.ActionNames.SET_REPORT_LIST: {
//             return {
//                 ...state,
//                 reportList: action.payload.reportList
//             }
//         }
//         case searchActions.ActionNames.ADD_REPORT_ITEM: {
//             return {
//                 ...state,
//                 reportList: [...state.reportList, {
//                     text: action.payload.item,
//                     chips: action.payload.chips,
//                     statuses: action.payload.statuses,
//                     onlyMyTasks: action.payload.onlyMyTasks
//                 }]
//             }
//         }
//         case searchActions.ActionNames.REMOVE_REPORT_ITEM: {
//             const removalFilterIndex = state.reportList.findIndex(item => item.uuid === action.payload.id);
//             const newFilterItem = [...state.reportList];
//
//             newFilterItem.splice(removalFilterIndex, 1);
//
//             return {
//                 ...state,
//                 reportList: [...newFilterItem]
//             }
//         }
//         case searchActions.ActionNames.TOGGLE_VISIBLE_SEARCH_BAR: {
//             return {
//                 ...state,
//                 visible: action.payload.visible
//             }
//         }
//         default:
//             return {...state};
//     }
// }
