import FirebaseUsage from "../firebase.usage";
import {COLLECTIONS} from "../constants";
import {DefaultSubscribeCallback} from "../../store/middleware/middlewares";
import ProjectMembersModel from "../../models/responses/project-members.model";
import {ReportItem} from "../../store/store.types";
import {PendingMembershipsModel} from "../../models/pending-memberships.model";

const colorPalette = [
    '#e879f9', '#c084fc', '#f472b6', '#38bdf8', '#2dd4bf', '#f87171', '#fb923c', '#a3e635'
]

export class MemberSubscriptions {
    static projectPermission(userId: string | null, callback: DefaultSubscribeCallback<ProjectMembersModel[]>) {
        return FirebaseUsage.getQuery(COLLECTIONS.PROJECT_MEMBERS, ['userId', '==', userId])
            .then(data => {
                const members: ProjectMembersModel[] = data.docs.map(el => el.data() as ProjectMembersModel);
                callback(members);
            });
    }

    static async memberList(projectId: string, callback: DefaultSubscribeCallback<ProjectMembersModel[]>) {
        return FirebaseUsage.queryListener(COLLECTIONS.PROJECT_MEMBERS, [['projectId', '==', projectId]], (data) => {
                callback(data.docs.map((el) => ({...el.data(),
                    userColour: colorPalette[(el.data().userEmail.charCodeAt(0) * el.data().userEmail.charCodeAt(1)) % colorPalette.length]
                }) as ProjectMembersModel)
                    .sort((a, b) => b.userEmail.localeCompare(a.userEmail))
                    .sort((a, b) => a.grade === "Groups" ? 1 : -1)
                );
        })
    }

    static pendingMembers(projectId: string, callback: DefaultSubscribeCallback<PendingMembershipsModel[]>) {
        return FirebaseUsage.getQuery(COLLECTIONS.PENDING_MEMBERSHIPS, ['projectId', '==', projectId])
            .then(data => {
                callback(data.docs.map(el => el.data() as PendingMembershipsModel));
            });
    }

    static projectMember(projectId: string, userId: string, callback: DefaultSubscribeCallback<ProjectMembersModel>) {
        return FirebaseUsage.queryListener(COLLECTIONS.PROJECT_MEMBERS,
            [['projectId', '==', projectId],
                ['userId', '==', userId]], (data) => {
            callback(data.docs[0].data() as ProjectMembersModel);
        });
    }

    static reportList(projectId: string, callback: DefaultSubscribeCallback<ReportItem[]>) {
        return FirebaseUsage.getQuery(COLLECTIONS.REPORTS, ['projectId', '==', projectId])
            .then(data => {
                callback(
                    data.docs.map(el => ({
                        // @ts-ignore
                        uuid: el.id, ...el.data()
                    })) as ReportItem[]
                );
            });
    }
}
