import {useTypedSelector} from "./selectors.utils";
import ProjectMembersModel from "../../models/responses/project-members.model";
import { MemberOrganizationEnum } from "../../models/member-organization.enum";
import {PendingMembershipsModel} from "../../models/pending-memberships.model";

// Team
export const useTaskforceListSelector = () =>
  useTypedSelector<ProjectMembersModel[]>((state) =>
    state.team.memberList
      .filter(
        (member) =>
          state.task.activeTask &&
          state.task.activeTask.taskForce.some((el) =>
            member.grade === MemberOrganizationEnum.GROUPS
              ? el === member.groupId
              : el === member.userId
          )
      )
      .filter((member) => {
        if (!state.search.chipsList.length) {
          return true;
        }
        return state.search.chipsList.some((report) =>
          report.type === "report"
            ? report.text.toLowerCase().includes(member.userEmail.toLowerCase())
            : true
        );
      })
  );
export const useProjectMemberListSelector = () => useTypedSelector<ProjectMembersModel[]>(state =>
    state.team.memberList.filter(member => {
        if (!state.search.chipsList.length) {
            return true;
        }
        return state.search.chipsList.some(report => report.type === 'report' ? member.userEmail.toLowerCase().includes(report.text.toLowerCase()) : true)
    })
);

export const usePendingProjectMemberListSelector = () => useTypedSelector<PendingMembershipsModel[]>(state =>
    state.team.pendingMemberList.filter(member => {
        if (!state.search.chipsList.length) {
            return true;
        }
        return state.search.chipsList.some(report => report.type === 'report' ? member.email.toLowerCase().includes(report.text.toLowerCase()) : true)
    })
);

// export const useProjectMemberListSelector3 = createSelector([state => state.team.memberList, state => state.search.chipsList], (memberList, chipsList) =>
//     memberList.filter(member => {
//         if (!chipsList.length) {
//             return true;
//         }
//         return chipsList.some(report => report.type === 'report' ? member.userEmail.toLowerCase().includes(report.text.toLowerCase()) : true)
//     })
// ) as (state: any) => ProjectMembersModel[];
//
// export const useProjectMemberListSelector2 = (state: RootStateModel) => useProjectMemberListSelector3(state)

export const useTeamLoadingSidebarSelector = () => useTypedSelector<boolean>(state => state.team.loadingSidebar);
export const useLoadingMemberGradeSelector = () => useTypedSelector<boolean>(state => state.team.loadingMemberGrade);
export const useProjectMemberSelector = () => useTypedSelector<ProjectMembersModel | null>(state => state.team.projectMember);
