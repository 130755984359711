import {combineReducers} from 'redux';
import {reducer} from 'redux-form';
import * as fromAuthorization from './authorization';
import * as fromProject from './project';
import * as fromTask from './task';
import * as fromWbs from './wbs';
import * as fromLedger from './ledger';
import * as fromTeam from './team';
import * as fromSearch from './search';
import * as fromRelationships from './relationships';
import * as fromEventLog from './event-log';

export interface RootStateModel {
    authorization: fromAuthorization.StateModel;
    project: fromProject.StateModel;
    task: fromTask.StateModel;
    wbs: fromWbs.StateModel;
    ledger: fromLedger.StateModel;
    team: fromTeam.StateModel;
    search: fromSearch.StateModel;
    relationships: fromRelationships.StateModel,
    eventLog: fromEventLog.StateModel
    form: any;
}

export const reducers: any = combineReducers({
    authorization: fromAuthorization.reducer,
    project: fromProject.reducer,
    task: fromTask.reducer,
    wbs: fromWbs.reducer,
    ledger: fromLedger.reducer,
    team: fromTeam.reducer,
    search: fromSearch.reducer,
    relationships: fromRelationships.reducer,
    eventLog: fromEventLog.reducer,
    form: reducer
});
