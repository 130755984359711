import XLSX from "xlsx";
import { saveAs } from "file-saver";
import moment from "moment";
import FirebaseUsage from "../firebase/firebase.usage";
import { COLLECTIONS } from "../firebase/constants";
import { generateTaskFromSnapshot } from "../firebase/subscriptions/taskSubscriptions";
import ProjectModel from "../models/responses/project.model";
import { CalendarModel } from "../models/responses/calendar.model";
import logError from "./error-logger.util";
import TaskModel from "../models/responses/task.model";
import TaskStatusModel from "../models/responses/task-status.model";
import store from "../store/store";
import {convertDateToIndex} from "./cpm-functions/cpm-app/functions/handleEvent";
import {CpmTaskModel} from "../models/responses/cpm-task.model";

function s2ab(s) {
    let buf = new ArrayBuffer(s.length);
    let view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
}

export async function generateP6Import (project: ProjectModel, e, dataDate, userId, projectId) {

    e.persist()

    const cpmMap = store.getState().project.cpmMap
    const projectCalendars = store.getState().project.activeProjectCalendars

    function handleRemainingDurationUnits (units: string, seconds: number, calendar: CalendarModel) {
        if (units === "")
            return Math.round(seconds / 3600)
        else if (units === "QT_Day") {
            return Math.round(seconds / 3600 / calendar.working_hours_per_day)
        }
        else if (units === "QT_Week") {
            return Math.round(seconds / 3600 / calendar.working_hours_per_week)
        } else if (units === "QT_Month") {
            return Math.round(seconds / 3600 / calendar.working_hours_per_week / 4.345)
        } else {
            return Math.round(seconds / 3600 / calendar.working_hours_per_week / 50)
        }
    }

    try {
        let features = {
            FL_Actual_Start: "",
            FL_Actual_Finish: "",
            FL_Remaining_Duration: "",
            FL_Remaining_Duration_Units: "",
            FL_Go_No_Go: "",
            FL_Expected_Finish: "",
            Date_Format: [],
            Date_Type: 'text',
        }

        const taskDict: {string: TaskModel} | {} =
            await FirebaseUsage.getQuery(COLLECTIONS.TASKS, ["projectId", "==", project.projectId])
            .then((data) => {
                const listObject = generateTaskFromSnapshot(data)
                let outputDict: any = {}
                listObject.forEach((task: TaskModel) => {
                    outputDict = {...outputDict, [task.task_code]: task}
                })
                return outputDict
            });

        const calendarDict = await FirebaseUsage.getQuery(COLLECTIONS.CALENDAR, ["project_id", "==", project.projectId])
            .then((data) => {
                const output = data.docs.map((el) => el.data() as CalendarModel)
                let outputDict = {}
                output.forEach(cal => {
                    outputDict = {...outputDict, [cal.calendar_id]: cal}
                })
                return outputDict
            })

        const reader = new FileReader()

        reader.onload = function (e) {

            const workbook = XLSX.read(e.target?.result, {type: 'binary'})

            let headers: any = {}
            let sheetData: any = {}

            workbook.SheetNames.forEach(function (sheetName) {
                const XL_row_object = XLSX.utils['sheet_to_row_object_array'](workbook.Sheets[sheetName]);
                const json_object = JSON.stringify(XL_row_object);
                const excelObject = JSON.parse(json_object)

                let header: string[] = []
                let data: any[] = []
                for (let item in excelObject[0]) {
                    header.push(item)
                }

                excelObject.forEach((item, i) => {
                    data.push(item)
                })

                headers = {...headers, [sheetName]: [...header]}
                sheetData = {...sheetData, [sheetName]: [...data]}
            })

            for (let item in sheetData['TASK'][0]) {
                if (sheetData['TASK'][0][item] === 'FL Actual Start') {
                    features.FL_Actual_Start = item
                }
                if (sheetData['TASK'][0][item] === 'FL Actual Finish') {
                    features.FL_Actual_Finish = item
                }
                if (sheetData['TASK'][0][item] === 'FL Remaining Duration') {
                    features.FL_Remaining_Duration = item
                }
                if (sheetData['TASK'][0][item] === 'FL Go/No-Go') {
                    features.FL_Go_No_Go = item
                }
                if (sheetData['TASK'][0][item] === 'FL Expected Finish') {
                    features.FL_Expected_Finish = item
                }
            }

            function convertToUpper(str: string) {
                return str.toUpperCase()
            }

            if (sheetData['USERDATA'][1]['user_data']) {
                sheetData['USERDATA'][1]['user_data'].split('\r\n').forEach((item) => {
                    const splitItem = item.split('=')
                    if (splitItem[0] === 'DurationQtyType') {
                        features.FL_Remaining_Duration_Units = splitItem[1]
                    }
                    if (splitItem[0] === 'DateFormat') {
                        features.Date_Format = splitItem[1].split("'")
                        if (features.Date_Format.length === 1) {
                            features.Date_Type = 'number'
                            features.Date_Format = splitItem[1].split('/')
                            if (features.Date_Format.length === 1) {
                                features.Date_Format = splitItem[1].split('.')
                                if (features.Date_Format.length === 1) {
                                    features.Date_Format = splitItem[1].split('-')
                                    // @ts-ignore
                                    features.Date_Format.splice(1, 0, '-')
                                    // @ts-ignore
                                    features.Date_Format.splice(3, 0, '-')
                                } else {
                                    // @ts-ignore
                                    features.Date_Format.splice(1, 0, '.')
                                    // @ts-ignore
                                    features.Date_Format.splice(3, 0, '.')
                                }
                            } else {
                                // @ts-ignore
                                features.Date_Format.splice(1, 0, '/')
                                // @ts-ignore
                                features.Date_Format.splice(3, 0, '/')
                            }
                        }
                    }
                })
            }

            const dataDateSeconds = Math.round((dataDate.getTime() / 1000) / 1800) * 1800

            const formatDate = (date: Date, type: string) => {
                let output: any = null
                const timeZoneOffset = date.getTimezoneOffset() * 60 * 1000
                // const timeZoneOffset = 0
                const finalDate = new Date(date.getTime() - timeZoneOffset)
                if (type === 'text') {
                    output = moment(finalDate)
                        .format(`${convertToUpper(features.Date_Format[0])}${features.Date_Format[1]}${convertToUpper(features.Date_Format[2])}${features.Date_Format[3]}${convertToUpper(features.Date_Format[4])} HH:mm`)}
                else {
                    output = 25569.0 + (finalDate.getTime() / (1000 * 60 * 60 * 24))
                }
                return output
            }

            const convertTaskType = {
                TT_Task: 'Task Dependent',
                TT_Mile: 'Start Milestone',
                TT_FinMile: 'Finish Milestone',
                TT_WBS: 'WBS Summary',
                TT_LOE: 'Level of Effort',
                TT_RSRC: 'Resource Dependent',
                TT_TASK: 'Task Dependent',
                TT_Rsrc: 'Resource Dependent',
            }

            const updateTasks = (sheetData: any) => {
                const checkTemplate = sheetData['TASK'] ?
                    // @ts-ignore
                    features.Date_Format !== [] &&
                    features.FL_Actual_Finish !== "" &&
                    features.FL_Actual_Start !== "" &&
                    features.FL_Go_No_Go !== "" &&
                    features.FL_Remaining_Duration !== "" &&
                    features.FL_Expected_Finish !== "" &&
                    features.FL_Remaining_Duration_Units !== "" ?
                        sheetData['TASK'].map((item: any, i: number) => {
                            const task: TaskModel = taskDict[item.task_code]
                            const cpmTask: CpmTaskModel = task ? cpmMap.get(task.task_id) : null
                            const dataDateIndex = task ? convertDateToIndex(dataDateSeconds, cpmTask.cal_id, projectCalendars) : null
                            let changesMade = i === 0
                            let outDict = i > 0 && task ? {
                                ...item,
                                task_name: task.task_name,
                                task_type: convertTaskType[task.task_type],
                                [features.FL_Go_No_Go]: null,
                                [features.FL_Remaining_Duration]: null,
                                [features.FL_Actual_Start]: null,
                                [features.FL_Actual_Finish]: null,
                                [features.FL_Expected_Finish]: null,
                            } : item
                            if (!item.act_start_date && i > 0) {  // excel indicates task has not started
                                if (task && task.act_start_date &&
                                    task.act_start_date.seconds * 1000 < dataDate.getTime()
                                ) { // FlowLedger indicates task has started
                                    changesMade = task.task_type !== 'TT_FinMile'
                                    if (task.task_type === 'TT_Mile') {
                                        outDict[features.FL_Actual_Start] = formatDate(task.act_start_date.toDate(), features.Date_Type)
                                        outDict.status_code = "Completed"
                                        outDict[features.FL_Go_No_Go] = "Red"
                                        outDict[features.FL_Remaining_Duration] = 0
                                    }
                                     else if (task.task_type !== 'TT_FinMile') {
                                        outDict[features.FL_Actual_Start] = formatDate(task.act_start_date.toDate(), features.Date_Type)
                                        outDict.status_code = "In Progress"
                                        outDict[features.FL_Go_No_Go] = "Blue"
                                        outDict[features.FL_Expected_Finish] = task.expiryDate ?
                                            formatDate(task.expiryDate.toDate(), features.Date_Type) :
                                            null
                                        outDict[features.FL_Remaining_Duration] = task.expiryDate ?
                                            task.expiryDate.seconds * 1000 < dataDate.getTime() ? 0 :
                                                // @ts-ignore
                                                handleRemainingDurationUnits(features.FL_Remaining_Duration_Units, Math.max(cpmTask.ef - dataDateIndex, 0) * 1800, calendarDict[task.calendar_id]).toString() : 0
                                    }
                                }
                            }
                            if (item.status_code !== "Completed" && i > 0) { // excel indicates task is not completed
                                if (task && ((task.act_end_date && task.act_end_date.seconds * 1000 <= dataDate.getTime()) ||
                                    (task.declaredCompleteTimestamp && task.declaredCompleteTimestamp.seconds * 1000 <= dataDate.getTime()))) {
                                    changesMade = true
                                    outDict[features.FL_Actual_Finish] = task.task_type === 'TT_Mile' ? null :
                                        formatDate(task.act_end_date ?
                                        task.act_end_date.toDate() :
                                        task.declaredCompleteTimestamp!.toDate(), features.Date_Type)
                                    outDict.status_code = "Completed"
                                    outDict[features.FL_Go_No_Go] = task.task_type === 'TT_FinMile' ||
                                    task.task_type === 'TT_Mile' ?
                                        "Red" : task.status === TaskStatusModel.DECLARED_COMPLETE ? "Yellow" : "Green"
                                }
                            }
                            else if (item.act_start_date && i > 0 && task && task.task_type !== 'TT_Mile' && item.status_code !== "Completed") {
                                outDict.status_code = "In Progress"
                                outDict[features.FL_Go_No_Go] = "Blue"
                                outDict[features.FL_Expected_Finish] = task.expiryDate ?
                                    formatDate(task.expiryDate.toDate(), features.Date_Type) : null
                                outDict[features.FL_Remaining_Duration] = task.expiryDate ?
                                    task.expiryDate.seconds * 1000 < dataDate.getTime() ? 0 :
                                        handleRemainingDurationUnits(features.FL_Remaining_Duration_Units,
                                            // @ts-ignore
                                            Math.max(cpmTask.ef - dataDateIndex, 0) * 1800,
                                            calendarDict[task.calendar_id]).toString() : 0
                                changesMade = true
                            }
                            return changesMade ? i === 0 ? {
                                task_code: outDict.task_code,
                                wbs_id: outDict.wbs_id,
                                task_name: 'Activity Name',
                                task_type: 'Activity Type',
                                [features.FL_Go_No_Go]: outDict[features.FL_Go_No_Go],
                                [features.FL_Remaining_Duration]: outDict[features.FL_Remaining_Duration],
                                [features.FL_Actual_Start]: outDict[features.FL_Actual_Start],
                                [features.FL_Actual_Finish]: outDict[features.FL_Actual_Finish],
                                [features.FL_Expected_Finish]: outDict[features.FL_Expected_Finish],
                                delete_record_flag: "Delete This Row"
                            } : {
                                task_code: outDict.task_code,
                                wbs_id: outDict.wbs_id,
                                task_name: outDict.task_name,
                                task_type: outDict.task_type,
                                [features.FL_Go_No_Go]: outDict[features.FL_Go_No_Go],
                                [features.FL_Remaining_Duration]: outDict[features.FL_Remaining_Duration],
                                [features.FL_Actual_Start]: outDict[features.FL_Actual_Start],
                                [features.FL_Actual_Finish]: outDict[features.FL_Actual_Finish],
                                [features.FL_Expected_Finish]: outDict[features.FL_Expected_Finish],
                                taskUpdated: 1
                            } : {
                                task_code: outDict.task_code,
                                wbs_id: outDict.wbs_id,
                                task_name: outDict.task_name,
                                task_type: outDict.task_type,
                                [features.FL_Go_No_Go]: outDict[features.FL_Go_No_Go],
                                [features.FL_Remaining_Duration]: outDict[features.FL_Remaining_Duration],
                                [features.FL_Actual_Start]: outDict[features.FL_Actual_Start],
                                [features.FL_Actual_Finish]: outDict[features.FL_Actual_Finish],
                                [features.FL_Expected_Finish]: outDict[features.FL_Expected_Finish],
                                taskUpdated: 0
                            }


                        }) : alert('Some required columns are missing from your excel export. See guidance notes for more information.') :
                    alert('No TASK sheet found in file, please see guidance notes for more information.')

                return checkTemplate
                    .filter((item: any) => item !== null)
                    .sort((a: any, b: any) => b.taskUpdated - a.taskUpdated)
                    .map((item: any, i: number) => {
                        if (i > 0) {
                            delete item.taskUpdated
                            return item
                        } else {
                            return item
                        }
                    })
            }

            const taskOutput = () => {
                const taskSheetData = updateTasks(sheetData)

                if (taskSheetData) {
                    const outWorkbook = XLSX.utils.book_new()
                    outWorkbook.SheetNames.push('TASK')
                    outWorkbook.SheetNames.push('USERDATA')

                    const taskSheet = XLSX.utils.json_to_sheet(taskSheetData,
                        {
                            header: [
                                'task_code',
                                // 'status_code',
                                'wbs_id',
                                'task_name',
                                'task_type',
                                features.FL_Go_No_Go,
                                features.FL_Remaining_Duration,
                                features.FL_Actual_Start,
                                features.FL_Actual_Finish,
                                features.FL_Expected_Finish,
                                'delete_record_flag',
                            ]
                        })

                    outWorkbook.Sheets['TASK'] = taskSheet

                    if (features.Date_Type === 'number') {
                        let fmt = ([...features.Date_Format, " hh:mm"]).join('')
                        let ws = outWorkbook.Sheets['TASK']
                        let range = {s: {r: 2, c: 6}, e: {r: taskSheetData.length, c: 8}};
                        for (let R = range.s.r; R <= range.e.r; ++R) {
                            for (let C = range.s.c; C <= range.e.c; ++C) {
                                let cell = ws[XLSX.utils.encode_cell({r: R, c: C})];
                                if (!cell || cell.t !== 'n') continue; // only format numeric cells
                                cell.z = fmt;
                            }
                        }
                    }

                    // const userDataSheet = XLSX.utils.json_to_sheet(sheetData['USERDATA'],{header: ['user_data']})
                    const userDataSheetOriginal = workbook.Sheets['USERDATA']

                    outWorkbook.Sheets['USERDATA'] = userDataSheetOriginal

                    const saveWorkbook = XLSX.write(outWorkbook, {bookType: "xlsx", type: "binary"});
                    saveAs(
                        new Blob([s2ab(saveWorkbook)], {type: "application/octet-stream"}),
                        `${FirebaseUsage.timestamp().toMillis()}_${project.name}_P6Import.xlsx`
                    );
                }
            }

            taskOutput()
        };

        reader.readAsBinaryString(e.target.files[0]);
    }
    catch (e: any) {
        logError("p6 import", e.toString(), userId, projectId).catch((err) => console.log(err))
        console.log(e)
    }

}