import XLSX from "xlsx";
import { saveAs } from "file-saver";
import moment from "moment";
import FirebaseUsage from "../firebase/firebase.usage";
import { COLLECTIONS } from "../firebase/constants";
import ProjectModel from "../models/responses/project.model";
import TaskModel from "../models/responses/task.model";
import { MessageType } from "../models/responses/message.model";
import {
  transformEventDetails,
  transformEventType,
  transformPredStatus, transformTaskType,
} from "./transformDataForSpreadsheet";
import {  ukTimezonify } from "./calculations.utils";
import LedgerEntry from "../models/responses/ledger-entry.model";
import ProjectMembersModel from "../models/responses/project-members.model";
import store from "../store/store";
import {BaselineModel} from "../models/responses/baseline.model";
import {CpmTaskModel} from "../models/responses/cpm-task.model";
import {convertIndexToSeconds} from "./cpm-functions/cpm-app/functions/handleEvent";

function s2ab(s) {
  let buf = new ArrayBuffer(s.length);
  let view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}

export async function generateSpreadsheet(
    project: ProjectModel,
    cpmMap: Map<string, CpmTaskModel>,
    calendarsMap: Map<string, any>) {
  const state = store.getState();
  const projectMembers: ProjectMembersModel[] = state.team.memberList;
  const taskList = state.task.tasks;
  const baselines =
      await FirebaseUsage.getQuery(COLLECTIONS.BASELINES, ['relatesToProject', '==', project.projectId])
            .then((data) => data.docs.map((el) => el.data() as BaselineModel));

  let baselineDict: any = {proj: null, bl1: null, bl2: null}

  if (baselines) {
    for (const baseline of baselines) {
      if (baseline && baseline.role === "Project") {
        baselineDict.proj = new Map()
        await FirebaseUsage.getCompoundQuery(COLLECTIONS.BASELINE_TASKS, [
            ['baselineId', '==', baseline.baselineId],
            ['projectId', '==', project.projectId]
        ])
            .then((data) =>
                data.docs.map((el) => baselineDict.proj.set(el.data().taskId, el.data())));
      } else if (baseline && baseline.role === "Primary") {
        baselineDict.bl1 = new Map()
        await FirebaseUsage.getCompoundQuery(COLLECTIONS.BASELINE_TASKS, [
            ['baselineId', '==', baseline.baselineId],
            ['projectId', '==', project.projectId]
        ])
            .then((data) =>
                data.docs.map((el) => baselineDict.bl1.set(el.data().taskId, el.data())));
      } else if (baseline && baseline.role === "Secondary") {
        baselineDict.bl2 = new Map()
        await FirebaseUsage.getCompoundQuery(COLLECTIONS.BASELINE_TASKS, [
            ['baselineId', '==', baseline.baselineId],
            ['projectId', '==', project.projectId]
        ])
            .then((data) =>
                data.docs.map((el) => baselineDict.bl2.set(el.data().taskId, el.data())));
      }
    }
  }

  let taskMap: Map<string, TaskModel> = new Map();
  taskList.forEach((task) => taskMap.set(task.task_id, task));

  // const taskList: TaskModel[] = await FirebaseUsage.getQuery(COLLECTIONS.TASKS, ['projectId', '==', project.projectId])
  //   .then((data) => {
  //     data.docs.map((el) => taskMap.set(el.id, el.data() as TaskModel));
  //     return generateTaskFromSnapshot(data)
  //   });

  let events = await FirebaseUsage.getQuery(COLLECTIONS.LEDGER_ENTRY, ['projectId', '==', project.projectId])
    .then((data) => data.docs.map((el) => el.data() as LedgerEntry));

  events = events.sort((a, b) => {
    return b.timestamp.toDate().getTime() - a.timestamp.toDate().getTime();
  });
  // const projectMembers =
  //     await FirebaseUsage.getQuery(COLLECTIONS.PROJECT_MEMBERS, ['projectId', '==', project.projectId])
  //       .then((data) => data.docs.map((el) => el.data() as ProjectMembersModel));

  let projectMembersMap: Map<string, ProjectMembersModel> = new Map();
  projectMembers.forEach((member) => projectMembersMap.set(member.userId, member));

  const wb = XLSX.utils.book_new();
  wb.SheetNames.push("Tasks");
  wb.SheetNames.push("Events");
  wb.SheetNames.push("Project Details");

  const tasks_sheet_data = taskList.map((task: TaskModel) => {
    const cpmTask = cpmMap.get(task.task_id);
    let actualEndDate = task.act_end_date ? task.act_end_date :
        task.declaredCompleteTimestamp ? task.declaredCompleteTimestamp :
            null;

    const projBaselineTask = baselineDict.proj ?  baselineDict.proj.get(task.task_id) : null;
    const projBaselineStart = projBaselineTask ? projBaselineTask.actStartDate ? projBaselineTask.actStartDate.toDate() : projBaselineTask.earlyStartDate.toDate() : null;
    const projBaselineFinish = projBaselineTask ? projBaselineTask.actEndDate ? projBaselineTask.actEndDate.toDate() : projBaselineTask.earlyEndDate.toDate() : null;

    const bl1BaselineTask = baselineDict.bl1 ? baselineDict.bl1.get(task.task_id) : null;
    const bl1BaselineStart = bl1BaselineTask ? bl1BaselineTask.actStartDate ? bl1BaselineTask.actStartDate.toDate() : bl1BaselineTask.earlyStartDate.toDate() : null;
    const bl1BaselineFinish = bl1BaselineTask ? bl1BaselineTask.actEndDate ? bl1BaselineTask.actEndDate.toDate() : bl1BaselineTask.earlyEndDate.toDate() : null;

    const bl2BaselineTask = baselineDict.bl2 ? baselineDict.bl2.get(task.task_id) : null;
    const bl2BaselineStart = bl2BaselineTask ? bl2BaselineTask.actStartDate ? bl2BaselineTask.actStartDate.toDate() : bl2BaselineTask.earlyStartDate.toDate() : null;
    const bl2BaselineFinish = bl2BaselineTask ? bl2BaselineTask.actEndDate ? bl2BaselineTask.actEndDate.toDate() : bl2BaselineTask.earlyEndDate.toDate() : null;

    const cD = (index: any)=> convertIndexToSeconds(index, cpmTask?.cal_id, calendarsMap) * 1000;

    return {
      Name: task.task_name,
      Status: task.status,
      "Task Type": transformTaskType(task.task_type),
      "Task Code": task.task_code,
      WBS: task.wbs,
      Taskforce: task.taskForce.length > 0 ?
          task.taskForce.map((member) => projectMembersMap.get(member)?.userEmail || "unknown user").join(", ") :
          null,
      Priority: task.index ? task.index : null,
      "Early Start": {t: 'n', v: cpmTask && !task.act_start_date
        ? 25569.0 + ((cD(cpmTask?.es) / (1000 * 60 * 60 * 24)))
        : "", z: 'dd/mm/yyyy hh:mm:ss'},
      // "Forcast Start": {t: 'n', v: forecastStart
      //   ? 25569.0 + ((ukTimezonify(forecastStart).getTime() - (forecastStart.getTimezoneOffset() * 60 * 1000)) / (1000 * 60 * 60 * 24))
      //   : "", z: 'dd/mm/yyyy hh:mm:ss'},
      // "Forcast Finish": {t: 'n', v: forecastEnd
      //   ? 25569.0 + ((ukTimezonify(forecastEnd).getTime() - (forecastEnd.getTimezoneOffset() * 60 * 1000)) / (1000 * 60 * 60 * 24))
      //   : "", z: 'dd/mm/yyyy hh:mm:ss'},
      "Early Finish": {t: 'n', v: cpmTask && !actualEndDate
        ? 25569.0 + ((cD(cpmTask.ef) / (1000 * 60 * 60 * 24)))
        : "", z: 'dd/mm/yyyy hh:mm:ss'},
      "Late Start": {t: 'n', v: cpmTask && !task.act_start_date
        ? 25569.0 + ((cD(cpmTask.ls) / (1000 * 60 * 60 * 24)))
        : "", z: 'dd/mm/yyyy hh:mm:ss'},
      "Late Finish": {t: 'n', v: cpmTask && !actualEndDate
        ? 25569.0 + ((cD(cpmTask.ls) / (1000 * 60 * 60 * 24)))
        : "", z: 'dd/mm/yyyy hh:mm:ss'},
      "Actual Start": {t: 'n', v: task.act_start_date
        ? 25569.0 + (((task.act_start_date.seconds * 1000) - (task.act_start_date.toDate().getTimezoneOffset() * 60000)) / (1000 * 60 * 60 * 24))
        : "", z: 'dd/mm/yyyy hh:mm:ss'},
      "Actual Finish": {t: 'n', v: actualEndDate
        ? 25569.0 + (((actualEndDate.seconds * 1000) - (actualEndDate.toDate().getTimezoneOffset() * 60000)) / (1000 * 60 * 60 * 24))
        : "", z: 'dd/mm/yyyy hh:mm:ss'},
      "Project Baseline Start": {t: 'n', v: projBaselineStart
        ? 25569.0 + ((projBaselineStart.getTime() / (1000 * 60 * 60 * 24)))
        : "", z: 'dd/mm/yyyy hh:mm:ss'},
      "Project Baseline Finish": {t: 'n', v: projBaselineFinish
        ? 25569.0 + ((projBaselineFinish.getTime() / (1000 * 60 * 60 * 24)))
        : "", z: 'dd/mm/yyyy hh:mm:ss'},
      "Primary Baseline Start": {t: 'n', v: bl1BaselineStart
        ? 25569.0 + ((bl1BaselineStart.getTime() / (1000 * 60 * 60 * 24)))
        : "", z: 'dd/mm/yyyy hh:mm:ss'},
      "Primary Baseline Finish": {t: 'n', v: bl1BaselineFinish
        ? 25569.0 + ((bl1BaselineFinish.getTime() / (1000 * 60 * 60 * 24)))
        : "", z: 'dd/mm/yyyy hh:mm:ss'},
      "Secondary Baseline Start": {t: 'n', v: bl2BaselineStart
        ? 25569.0 + ((bl2BaselineStart.getTime() / (1000 * 60 * 60 * 24)))
        : "", z: 'dd/mm/yyyy hh:mm:ss'},
      "Secondary Baseline Finish": {t: 'n', v: bl2BaselineFinish
        ? 25569.0 + ((bl2BaselineFinish.getTime() / (1000 * 60 * 60 * 24)))
        : "", z: 'dd/mm/yyyy hh:mm:ss'},
    };
  });
  const tasks_sheet = XLSX.utils.json_to_sheet(tasks_sheet_data, {
    header: [
      "Name",
      "Status",
      "Task Type",
      "Task Code",
      "WBS",
      "Taskforce",
      "Priority",
      "Early Start",
      "Early Finish",
      // "Forcast Start",
      // "Forcast Finish",
      "Late Start",
      "Late Finish",
      "Actual Start",
      "Actual Finish",
      "Project Baseline Start",
      "Project Baseline Finish",
      "Primary Baseline Start",
      "Primary Baseline Finish",
      "Secondary Baseline Start",
      "Secondary Baseline Finish",
    ],
  });
  const tasks_sheet_cols = [
    { wch: 30 },
    { wch: 15 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
  ];
  tasks_sheet["!cols"] = tasks_sheet_cols;

  let events_sheet_data: any[] = [];
 for (const event of events) {
    const relevantTask = taskMap.get(event.taskId);

    if (event.type === MessageType.IMG || event.type === MessageType.FIL) {
      for (const attachment of event.attachments!) {
        events_sheet_data.push({
          "Logged by": event.userEmail ? event.userEmail : "Flowbot",
          "Type": transformEventType(event.type as MessageType),
          "Details": await transformEventDetails(event, attachment),
          "Task Code": relevantTask ? relevantTask.task_code : null,
          "Pred Status": transformPredStatus(relevantTask?.predStatus as number),
          "Log Timestamp": event.logTimestamp ? moment(event.logTimestamp.toDate()).format(
            "DD-MMM-YY HH:mm"
          ) : moment(event.timestamp.toDate()).format(
            "DD-MMM-YY HH:mm"
        ),
          "Event Timestamp": moment(event.timestamp.toDate()).format(
            "DD-MMM-YY HH:mm"
          ),
        });
      }
    } else {
      events_sheet_data.push({
        "Logged by": event.userEmail ? event.userEmail : "Flowbot",
        "Type": transformEventType(event.type as MessageType),
        "Details": await transformEventDetails(event),
        "Task Code": relevantTask ? relevantTask.task_code : null,
        "Pred Status": transformPredStatus(relevantTask?.predStatus as number),
        "Priority": relevantTask ? relevantTask.index ? relevantTask.index : null : null,
        "Log Timestamp": event.logTimestamp ? moment(event.logTimestamp.toDate()).format(
          "DD-MMM-YY HH:mm"
        ) : moment(event.timestamp.toDate()).format(
          "DD-MMM-YY HH:mm"
      ),
        "Event Timestamp": moment(event.timestamp.toDate()).format(
          "DD-MMM-YY HH:mm"
        ),
      });
    }
  }
  const events_sheet = XLSX.utils.json_to_sheet(events_sheet_data, {
    header: [
      "Logged by",
      "Type",
      "Details",
      "Task Code",
      "Pred Status",
      "Priority",
      "Log Timestamp",
      "Event Timestamp",
    ],
  });
  const events_sheet_cols = [
    { wch: 30 },
    { wch: 25 },
    { wch: 30 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 30 },
    { wch: 30 },
  ];
  events_sheet["!cols"] = events_sheet_cols;

  wb.Sheets["Tasks"] = tasks_sheet;
  wb.Sheets["Events"] = events_sheet;

  const exportDate = FirebaseUsage.timestamp();

  const project_sheet_data = [
      {
      "Project Name": project.name,
      "Export Date": {t: 'n',
        v: 25569.0 + ((ukTimezonify(exportDate).getTime() - (exportDate.toDate().getTimezoneOffset() * 60 * 1000)) / (1000 * 60 * 60 * 24)),
        z: 'dd/mm/yyyy hh:mm:ss'},
      "Last Update": {t: 'n', v: project.lastUpdate
          ? 25569.0 + ((ukTimezonify(project.lastUpdate).getTime() - (project.lastUpdate.toDate().getTimezoneOffset() * 60 * 1000)) / (1000 * 60 * 60 * 24))
          : "", z: 'dd/mm/yyyy hh:mm:ss'},
      "Upload Date": {t: 'n', v: project.updateDate
          ? 25569.0 + ((ukTimezonify(project.updateDate).getTime() - (project.updateDate.toDate().getTimezoneOffset() * 60 * 1000)) / (1000 * 60 * 60 * 24))
          : "", z: 'dd/mm/yyyy hh:mm:ss'},
      }
  ];

    const project_sheet = XLSX.utils.json_to_sheet(project_sheet_data, {
        header: [
        "Project Name",
        "Export Date",
        "Last Update",
        "Upload Date",
        ],
    });
    const project_sheet_cols = [
        { wch: 30 },
        { wch: 30 },
        { wch: 30 },
        { wch: 30 },
    ];
    project_sheet["!cols"] = project_sheet_cols;
    wb.Sheets["Project Details"] = project_sheet;

  const wbOut = XLSX.write(wb, { bookType: "xlsx", type: "binary"});
  saveAs(
    new Blob([s2ab(wbOut)], { type: "application/octet-stream" }),
    `${FirebaseUsage.timestamp().toMillis()}_${project.name}.xlsx`
  );
}
