import { Form, Icon, Button } from "semantic-ui-react";
import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import { wrapperOfClickOutside } from "../../../../../utils/handle-click-outside";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { ReportItem, Chip } from "../../../../../store/store.types";
import { SearchThunk } from "../../../../../store/thunk/search.thunk";
import { Maybe } from "@martin_hotell/rex-tils";
import { useTypedSelector } from "../../../../../store/selectors/selectors.utils";
import { SearchActions } from "../../../../../store/actions/search.actions";
import { useSearchParamatersSelector } from "../../../../../store/selectors/search.selectors";
import styles from "./ReportsDropdown.module.scss";
import classNames from "classnames";
import { toast } from "react-toastify";

const InputWrap = styled.div`
  margin: 1.14285714rem 0.78571429rem;
`;

// const options = [
//     { key: 1, text: 'Choice 1', value: 1 },
//     { key: 2, text: 'Choice 2', value: 2 },
//     { key: 3, text: 'Choice 3', value: 3 },
// ]

interface ChosenReport {
  alert: boolean;
  taskForces: (string | null)[];
  chipList: Chip[];
  name: string;
}

export const ReportDropdown: React.FC = () => {
  const [isOpenReportsDropdown, setOpenReportsDropdown] =
    useState<boolean>(false);
  const [editReport, setEditReport] = useState<Maybe<ReportItem>>(null);
  const reportsDropdownRef = useRef<any>(null);
  const reportList = useTypedSelector((state) => state.search.reportList);
  const searchParams = useSearchParamatersSelector();
  const [newReport, setNewReport] = useState<string>("");
  const [chosenReport, setChosenReport] = useState<ChosenReport | null>(null);
  const dispatch = useDispatch();
  const includeMilestones = useTypedSelector((state) => state.search.parameters.includeMilestones);
  const hasProjectBeenSelected = useTypedSelector((state) =>
    Boolean(state.project.activeProject)
  );
  // const statusList = useStatusListSelector();

  const handleClickOutside = useCallback((event) => {
    wrapperOfClickOutside(reportsDropdownRef.current, event, () => {
      setEditReport(null);
      setOpenReportsDropdown(false);
    });
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const removeReportItem = useCallback(
    (item: ReportItem) => {
        // @ts-ignore
      dispatch(SearchThunk.removeReportItem(item.uuid));
      // setOpenReportsDropdown(false);
    },
    [dispatch]
  );

  const editReportItem = useCallback(
    (evt?) => {
      if (evt) {
        evt.preventDefault();
      }
      if (editReport) {
        // @ts-ignore
        dispatch(SearchThunk.editReportItem(editReport));
        setEditReport(null);
      }
    },
    [dispatch, editReport]
  );

  const ShareReportItem = (report) => {
    const reportId = report.uuid;
    const url = `${window.location.origin}/reports/${reportId}`;
    navigator.clipboard.writeText(url);
    toast.success("Shared link copied succssfully", {
      autoClose: 2000,
    });
  };

  const addNewReport = useCallback(() => {
    if (newReport) {
      if (searchParams.searchText !== "" && searchParams.chipList.length === 0 && searchParams.searchText.length > 2) {
          searchParams.chipList.push({type: "filter", text: searchParams.searchText})
      }
      // @ts-ignore
      dispatch(SearchThunk.addReportItem(newReport, searchParams));
      setNewReport("");
      setChosenReport({
        alert: searchParams.alert,
        taskForces: searchParams.taskForces,
        chipList: searchParams.chipList,
        name: newReport,
      });
      setOpenReportsDropdown(false);
    }
  }, [dispatch, searchParams, newReport]);

  const selectReport = useCallback(
    (id: string) => {
      const myReport = reportList.find((item) => item.uuid === id);
      if (myReport) {
        dispatch(
          SearchActions.setReport({
            alert: myReport.alert,
            taskForces: myReport.taskForces,
            chipList: myReport.chipList,
            startDate: myReport.startDate,
            finishDate: myReport.finishDate,
            noTaskForce: myReport.noTaskForce,
            includeMilestones
          })
        );
        setChosenReport({
          alert: myReport.alert,
          taskForces: myReport.taskForces,
          chipList: myReport.chipList,
          name: myReport.name,
        });
      }
    },
    [dispatch, reportList]
  );

  const checkReports = useMemo(() => {
    let isSame = false;
    if (chosenReport) {
      if (chosenReport.alert === searchParams.alert) {
        isSame = true;
      } else {
        isSame = false;
        return isSame;
      }

      if (
        chosenReport.chipList.length === searchParams.chipList.length &&
        chosenReport.chipList.every(
          (el, i) => el.text === searchParams.chipList[i].text
        )
      ) {
        isSame = true;
      } else {
        isSame = false;
        return isSame;
      }

      if (
        chosenReport.taskForces.length === searchParams.taskForces.length &&
        chosenReport.taskForces.every(
          (el, i) => el === searchParams.taskForces[i]
        )
      ) {
        isSame = true;
      } else {
        isSame = false;
        return isSame;
      }
    }
    return isSame;
  }, [chosenReport, searchParams]);

  return (
    <div className="search-bar-item">
      {hasProjectBeenSelected ? (
        <div ref={reportsDropdownRef as any}>
          <Button
            onClick={() =>
              isOpenReportsDropdown
                ? setOpenReportsDropdown(false)
                : setOpenReportsDropdown(true)
            }
            className={classNames(styles.ReportDropdownButton, {
              [styles.ActiveReport]:
                chosenReport && chosenReport.name && checkReports,
            })}
            circular
            size="tiny"
          >
            {chosenReport && chosenReport.name && checkReports
              ? `Report: ${chosenReport.name}`
              : `Reports`}
          </Button>
          <div
            className={classNames(styles.ReportsDropdown, {
              [styles.Active]: isOpenReportsDropdown,
            })}
          >
            <InputWrap>
              <Form onSubmit={addNewReport}>
                <Form.Group>
                  <Form.Input
                    action={{ icon: "check" }}
                    value={newReport}
                    name="newReport"
                    placeholder="Save current view"
                    focus={isOpenReportsDropdown}
                    onChange={(evt) => {
                      setNewReport(evt.target.value);
                    }}
                  />
                </Form.Group>
              </Form>
            </InputWrap>
            <div>
              {reportList.map((option, ind) => (
                <div key={ind}>
                  <div
                    className="report-dropdown-item"
                    onClick={(evt) => {
                      selectReport(option.uuid);
                      chosenReport &&
                        editReport &&
                        setChosenReport({
                          ...chosenReport,
                          name: editReport.name,
                        });
                    }}
                  >
                    <Icon
                      name="delete"
                      onClick={(evt) => {
                        evt.preventDefault();
                        evt.stopPropagation();
                        setEditReport(null);
                        removeReportItem(option);
                        chosenReport &&
                          setChosenReport({
                            ...chosenReport,
                            name: "",
                          });
                      }}
                    />
                    <div className="report-dropdown-item-label">
                      {editReport && editReport.uuid === option.uuid ? (
                        <form onSubmit={editReportItem}>
                          <input
                            type="text"
                            className="input-reset"
                            autoFocus
                            defaultValue={editReport.name}
                            onChange={(evt) => {
                              evt.preventDefault();
                              evt.stopPropagation();
                              setEditReport({
                                ...editReport,
                                name: evt.target.value,
                              });
                              setChosenReport({
                                ...editReport,
                                name: evt.target.value,
                              });
                            }}
                          />
                        </form>
                      ) : (
                        option.name
                      )}
                    </div>
                    <span>&nbsp;</span>
                    <Icon
                      name="external share"
                      onClick={(evt) => {
                        evt.preventDefault();
                        evt.stopPropagation();
                        ShareReportItem(option);
                      }}
                    />
                    {editReport && editReport.uuid === option.uuid ? (
                      <Icon
                        name="check"
                        onClick={(evt) => {
                          evt.preventDefault();
                          evt.stopPropagation();
                          editReportItem();
                        }}
                      />
                    ) : (
                      <Icon
                        name="edit"
                        onClick={(evt) => {
                          evt.preventDefault();
                          evt.stopPropagation();
                          setEditReport(option);
                        }}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <Button disabled circular size="tiny">
          {chosenReport && chosenReport.name && checkReports
            ? `Report: ${chosenReport.name}`
            : `Reports`}
        </Button>
      )}
    </div>
  );
};
