import FirebaseUsage from "../firebase.usage";
import {COLLECTIONS} from "../constants";
import {DefaultSubscribeCallback} from "../../store/middleware/middlewares";
import TaskModel, {TaskSnapshot} from "../../models/responses/task.model";
import store from "../../store/store";
import TaskStatusModel from "../../models/responses/task-status.model";
import {TaskListSectionModel} from "../../models/task-list-section.model";
import {orderBy, query} from "firebase/firestore";
import {CpmTaskModel} from "../../models/responses/cpm-task.model";
import {convertDateToIndex} from "../../utils/cpm-functions/cpm-app/functions/handleEvent";
import * as projectActions from "../../store/actions/project.actions";
import * as taskActions from "../../store/actions/task.actions";


const updateCPMMap = (cpmMap: any, taskList: TaskModel[], projectCalendars: any) => {
  let outCPMMap: Map<string, any> | null = cpmMap.size > 0 ? new Map(cpmMap) : null
  if (!outCPMMap) return
  if (outCPMMap.size === 0) return
  const timeStart = new Date().getTime() / 1000
  const dataDate = Math.floor(timeStart / 86400) * 86400
  taskList.forEach((task: TaskModel) => {
    // @ts-ignore
    const taskData: CpmTaskModel = outCPMMap.get(task.task_id)
    if (taskData) {
      let cpmTask: CpmTaskModel = {
        ...taskData,
        es: task.early_start_date ? convertDateToIndex(task.early_start_date.seconds, taskData.cal_id, projectCalendars) : taskData.es,
        ef: task.early_end_date ? convertDateToIndex(task.early_end_date.seconds, taskData.cal_id, projectCalendars) : taskData.ef,
        ls: task.late_start_date ? convertDateToIndex(task.late_start_date.seconds, taskData.cal_id, projectCalendars) : taskData.ls,
        lf: task.late_end_date ? convertDateToIndex(task.late_end_date.seconds, taskData.cal_id, projectCalendars) : taskData.lf,
        status_code: task.status,
        predStatus: task.predStatus,
      }
      // @ts-ignore
      if (cpmTask.status_code === TaskStatusModel.DECLARED_COMPLETE || cpmTask.status_code === TaskStatusModel.COMPLETE) {
        cpmTask.duration = 0
      } else if (cpmTask.status_code === TaskStatusModel.IN_PROGRESS || cpmTask.status_code === TaskStatusModel.SUSPENDED) {
          cpmTask.duration = Math.max(0,
              convertDateToIndex(Math.floor(task.expiryDate!.seconds / 1800) * 1800, taskData.cal_id, projectCalendars) -
              convertDateToIndex(dataDate, taskData.cal_id, projectCalendars))
      } else {
        // @ts-ignore
        cpmTask.duration = taskData.ef - taskData.es
      }
      // @ts-ignore
      outCPMMap.set(task.task_id, cpmTask)
    }
  })
  store.dispatch(projectActions.Actions.setCpmMap(outCPMMap))
  outCPMMap = null
}

const updateCPMMapLite = (cpmMap: any, taskList: TaskModel[], projectCalendars: any) => {
  if (cpmMap) return
  if (cpmMap.size === 0) return
  const timeStart = new Date().getTime() / 1000
  const dataDate = Math.floor(timeStart / 86400) * 86400
  taskList.forEach((task: TaskModel) => {
      const activeTask = store.getState().task.activeTask
      if (activeTask && activeTask.task_id === task.task_id) {
          store.dispatch(taskActions.Actions.setActiveTask(task))
      }
    // @ts-ignore
    const taskData: CpmTaskModel = outCPMMap.get(task.task_id)
    if (taskData) {
      let cpmTask: CpmTaskModel = {
        ...taskData,
        status_code: task.status,
        predStatus: task.predStatus,
      }
      // @ts-ignore
      if (cpmTask.status_code === TaskStatusModel.DECLARED_COMPLETE || cpmTask.status_code === TaskStatusModel.COMPLETE) {
        cpmTask.duration = 0
      } else if (cpmTask.status_code === TaskStatusModel.IN_PROGRESS || cpmTask.status_code === TaskStatusModel.SUSPENDED) {
        cpmTask.duration = Math.max(0,
            convertDateToIndex(Math.floor(task.expiryDate!.seconds / 1800) * 1800, taskData.cal_id, projectCalendars) -
            convertDateToIndex(dataDate, taskData.cal_id, projectCalendars))
      } else {
        // @ts-ignore
        cpmTask.duration = taskData.ef - taskData.es
      }
      cpmMap.set(task.task_id, cpmTask)
    }
  })
}


export const generateTaskFromSnapshot = (data: any) => {
  const changes = data.docChanges();
  let changeList : any[] = []
  changes.forEach((change: any) => {
      changeList.push(change.doc.data())
  })
  // console.log("changes", changes)
  updateCPMMapLite(store.getState().project.cpmMap, changeList, store.getState().project.activeProjectCalendars)

  return data.docs.map((doc: any) => {
    const d = doc.data();
    return {
      ...d,
      status: d.status.toLowerCase(),
      docRef: doc.ref
    } as TaskModel;
  });
};

export interface TaskListSubscriptionParameters {
  project_id: string;
  taskListType?: TaskListSectionModel;
  statuses?: Array<TaskStatusModel | string>;
  status: TaskStatusModel;
  taskForces: Array<string>;
  page: number;
  flow?: boolean;
  alert: boolean;
  searchValue?: string;
}

export interface RelatedTasksSubscriptionParameters {
  project_id: string;
  taskId: string;
}

export class TaskSubscriptions {
  static getTaskListRef(
    params: Pick<
      TaskListSubscriptionParameters,
      "project_id" | "page" | "taskListType" | "statuses" | "status"
    >
  ) {
    if (params.statuses && params.statuses!.length > 0) {
      params.statuses = params.statuses!.filter(
        (stat) => stat !== TaskStatusModel.RETIRED
      );
    } else {
      params.statuses = Object.values(TaskStatusModel).filter(
        (stat) => stat !== TaskStatusModel.RETIRED
      );
    }
    if (params.status === TaskStatusModel.COMPLETE) {
        return FirebaseUsage.getCompoundQueryAsQuery(COLLECTIONS.TASKS, [
            ["projectId", "==", params.project_id],
            ["status", "==", params.status],
        ]);
    } else if (params.status === TaskStatusModel.DECLARED_COMPLETE) {
        return FirebaseUsage.getCompoundQueryAsQuery(COLLECTIONS.TASKS, [
            ["projectId", "==", params.project_id],
            ["status", "==", params.status],
        ]);
    } else if (params.status === TaskStatusModel.IN_PROGRESS) {
        return FirebaseUsage.getCompoundQueryAsQuery(COLLECTIONS.TASKS, [
            ["projectId", "==", params.project_id],
            ["status", "==", params.status],
            ["flow", "==", true]
        ]);
    } else if (params.status === TaskStatusModel.NOT_STARTED) {
        return FirebaseUsage.getCompoundQueryAsQuery(COLLECTIONS.TASKS, [
            ["projectId", "==", params.project_id],
            ["status", "==", params.status],
            ["flow", "==", false]
        ]);
    } else if (params.taskListType === TaskListSectionModel.PENDING) {
        return FirebaseUsage.getCompoundQueryAsQuery(COLLECTIONS.TASKS, [
            ["projectId", "==", params.project_id],
            ["taskListType", "==", params.taskListType],
        ]);
    } else {
        return FirebaseUsage.getCompoundQueryAsQuery(COLLECTIONS.TASKS, [
            ["projectId", "==", params.project_id],
        ]);
    }
  }

  static async taskList(
    params: TaskListSubscriptionParameters,
    callback: DefaultSubscribeCallback<TaskModel[]>
  ) {
    let qRef = await TaskSubscriptions.getTaskListRef(params);
    if (params.status === TaskStatusModel.COMPLETE) {
      qRef = query(qRef, orderBy("act_end_date", "desc"));
    } else if (params.status === TaskStatusModel.DECLARED_COMPLETE) {
      qRef = query(qRef, orderBy("declaredCompleteTimestamp", "desc"));
    } else if (params.status === TaskStatusModel.IN_PROGRESS) {
      qRef = query(qRef, orderBy("late_start_date"));
    } else if (params.status === TaskStatusModel.NOT_STARTED) {
      qRef = query(qRef, orderBy("forecastDate"));
    } else if (params.taskListType === TaskListSectionModel.PENDING) {
        qRef = query(qRef, orderBy("taskCreatedTimestamp"));
    } else {
        qRef = query(qRef, orderBy("forecastDate"));
    }

    return await FirebaseUsage.queryListenerRef(
      qRef,
      (data: any) => callback(generateTaskFromSnapshot(data))
    );
  }

  static async completeTaskList( projectId: string, callback: DefaultSubscribeCallback<TaskModel[]>) {
    return FirebaseUsage.queryListener(COLLECTIONS.TASKS, [
        ["projectId", "==", projectId]
    ], (docs: TaskModel[]) => callback(generateTaskFromSnapshot(docs)));
  }

  static successorsList(
    params: RelatedTasksSubscriptionParameters,
    callback: DefaultSubscribeCallback<TaskModel[]>
  ) {
    const allTasks = store.getState().task.tasks
    const relationshipsList = store.getState().relationships.relationshipsList;
    const taskIdsQuery = relationshipsList
      .filter((relation) => relation.pred_task_id === params.taskId)
      .map((relation) => relation.task_id);

    if (!Boolean(taskIdsQuery.length)) {
      return () => callback([]);
    }

    const predTasks = allTasks.filter((task) => taskIdsQuery.includes(task.task_id))
    return callback(predTasks)
  }

  static predecessorsList(
    params: RelatedTasksSubscriptionParameters,
    callback: DefaultSubscribeCallback<TaskModel[]>
  ) {
    const allTasks = store.getState().task.tasks

    const relationshipsList = store.getState().relationships.relationshipsList;
    const taskIdsQuery = relationshipsList
      .filter((relation) => relation.task_id === params.taskId)
      .map((relation) => relation.pred_task_id);

    if (!Boolean(taskIdsQuery.length)) {
      return () => callback([]);
    }

    const predTasks = allTasks.filter((task) => taskIdsQuery.includes(task.task_id))
    return callback(predTasks)
  }

  static async taskSnapshot(
      dataVersionId: string,
      contractId: string,
      callback: DefaultSubscribeCallback<Map <string, TaskSnapshot>>) {
        let taskSnapshotMap = new Map<string, TaskSnapshot>()
        await FirebaseUsage.getCompoundQuery(COLLECTIONS.TASK_SNAPSHOTS, [
            ["dataVersionId", "==", dataVersionId],
            ["contractId", "==", contractId]])
            .then((snapshot) =>
                snapshot.docs.forEach((doc) => {
                    const data = doc.data()
                    taskSnapshotMap.set(data.taskCode, data as TaskSnapshot)
                }))
        return callback(taskSnapshotMap)
  }
}
