import {
    Button,
    FormCheckbox,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow
} from "semantic-ui-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import DatePicker from "react-datepicker";
import TrackedMilestoneModel from "../../../../../models/responses/tracked-milestone.model";
import FirebaseUsage from "../../../../../firebase/firebase.usage";
import {COLLECTIONS} from "../../../../../firebase/constants";
import {Actions} from "../../../../../store/actions/project.actions";
import { useDispatch } from "react-redux";


export default function ManageMilestoneModal({
     manageMilestones,
     setManageMilestones,
     trackedMilestones,
     setMilestoneVisibility
}) {

    const dispatch = useDispatch()
    const deleteTrackedMilestone = async (milestone: TrackedMilestoneModel) => {
        FirebaseUsage.deleteDocument(COLLECTIONS.TRACKED_MILESTONES, milestone.milestoneId).catch(err => console.log(err))
        dispatch(Actions.setTrackedMilestones(trackedMilestones.filter(currentState => currentState.milestoneId !== milestone.milestoneId)))
    }

    const editBaselineDate = async (milestone: TrackedMilestoneModel, newDate: Date) => {
        const newDateAsTimestamp = FirebaseUsage.timestamp(newDate)
        FirebaseUsage.updateDoc(COLLECTIONS.TRACKED_MILESTONES, milestone.milestoneId, {baselineDate: newDateAsTimestamp}).catch(err => console.log(err))
        dispatch(Actions.setTrackedMilestones(trackedMilestones.map(currentState =>
            currentState.milestoneId === milestone.milestoneId ? ({...currentState, baselineDate: newDateAsTimestamp}) : currentState
        )))
    }

    const setMilestoneContractStatus = async (milestone: TrackedMilestoneModel) => {
        const newStatus = !milestone.isContractual
        FirebaseUsage.updateDoc(COLLECTIONS.TRACKED_MILESTONES, milestone.milestoneId, {isContractual: newStatus}).catch(err => console.log(err))
        dispatch(Actions.setTrackedMilestones(trackedMilestones.map(currentState =>
            currentState.milestoneId === milestone.milestoneId ? ({...currentState, isContractual: newStatus}) : currentState
        )))
    }

  return (
        <Modal open={manageMilestones} size={"large"}>
            <Modal.Header>Manage tracked milestones</Modal.Header>
            <Modal.Content className="milestone-modal-content">
                <div className="milestone-modal-table-wrapper">
                    <Table className="milestone-modal-table">
                        <TableHeader className="milestone-modal-table-header">
                            <TableRow>
                                <TableHeaderCell width={2}>Visible</TableHeaderCell>
                                <TableHeaderCell>Milestone Name</TableHeaderCell>
                                <TableHeaderCell textAlign={"center"}>Contractual</TableHeaderCell>
                                <TableHeaderCell width={4} textAlign={"center"}>Baseline Date</TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {trackedMilestones.map((milestone: TrackedMilestoneModel) => (
                                <TableRow key={milestone.milestoneId}>
                                    <TableCell>
                                        <span style={{display: "flex", flexDirection: "row"}}>
                                          <FormCheckbox
                                              checked={!milestone.hidden}
                                              onClick={() => setMilestoneVisibility(milestone)}
                                          />
                                            &nbsp;
                                            <FontAwesomeIcon
                                                icon={faTrashCan} size={ "lg" }
                                                className={'delete-icon'}
                                                onClick={() => deleteTrackedMilestone(milestone)}
                                            />
                                        </span>
                                    </TableCell>
                                    <TableCell>
                                        {milestone.taskCode}&nbsp;-&nbsp;{milestone.milestoneName}
                                    </TableCell>
                                    <TableCell textAlign={"center"}>
                                        <FormCheckbox
                                            checked={milestone.isContractual}
                                            onClick={() => setMilestoneContractStatus(milestone)}
                                        />
                                    </TableCell>
                                    <TableCell textAlign={"center"}>
                                        <DatePicker
                                            className="baseline-date-picker"
                                            selected={milestone.baselineDate.toDate()}
                                            dateFormat="dd-MMM-yyyy"
                                            onChange={(date: Date) => editBaselineDate(milestone, date)}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        </Table>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button active
                            onClick={() => setManageMilestones(false)}
                    >Close</Button>
                </Modal.Actions>
            </Modal>
  )
}