import React, {useEffect} from "react";
import {useRecentUserItemsSelector} from "../../../../../store/selectors/ledger.selectors";
import {subsManager} from "../../../../../store/middleware/subs-manager/subs-manager";
import {Modal} from "semantic-ui-react";
import {useActiveProjectSelector, useCpmMapSelector} from "../../../../../store/selectors/project.selectors";
import ProjectMembersModel from "../../../../../models/responses/project-members.model";
import styles from '../SearchBar.module.scss';
import MessageModel, {MessageType} from "../../../../../models/responses/message.model";
import {CpmTaskModel} from "../../../../../models/responses/cpm-task.model";
import {useDispatch} from "react-redux";
import {SearchActions} from "../../../../../store/actions/search.actions";
import * as taskActions from "../../../../../store/actions/task.actions";
import * as projectActions from "../../../../../store/actions/project.actions";
import {useAllTaskListSelector} from "../../../../../store/selectors/task/task.selectors";

export default function RecentInput(props: {member: ProjectMembersModel, setOpen: any, setSubModalOpen?: any}) {

    const { member, setOpen } = props;
    const recentUserItems = useRecentUserItemsSelector();
    const activeProject = useActiveProjectSelector();
    const cpmMap = useCpmMapSelector();
    const dispatch = useDispatch();
    const allTasks = useAllTaskListSelector();

    const formatText = (message: MessageModel, task: CpmTaskModel | undefined) => {
        if (!task) return 'Event error - task not found';
        switch (message.type) {
            case MessageType.COM:
                return `Approved completion of task ${task.task_name}`;
            case MessageType.DEC:
                return `Declared complete task ${task.task_name}`;
            case MessageType.BLC:
                return `Blocked task ${task.task_name}`;
            case MessageType.UNB:
                return `Unblocked task ${task.task_name}`;
            case MessageType.STD:
                return `Started task ${task.task_name} on ${message.timestamp.toDate().toLocaleDateString()}`;
            case MessageType.TFJ:
                return `Joined task force ${task.task_name}`;
            case MessageType.TFL:
                return `Left task force ${task.task_name}`;
            case MessageType.FOR:
                return `Reforecasted task ${task.task_name} to ${message.timestamp.toDate().toLocaleDateString()}`;
            case MessageType.ISS:
                return `Logged an issue on task ${task.task_name}`;
            case MessageType.MSG:
                return `Left a comment on task ${task.task_name}`;
            case MessageType.SUS:
                return `Suspended task ${task.task_name}`;
            case MessageType.RST:
                return `Resumed task ${task.task_name}`;
            case MessageType.CST:
                return `Constrained the start of task ${task.task_name} to ${message.timestamp.toDate().toLocaleDateString()}`;
            case MessageType.FIL:
                return `Attached a file to task ${task.task_name}`;
            case MessageType.IMG:
                return `Attached an image to task ${task.task_name}`;
            case MessageType.EVL:
                return `Evaluated task ${task.task_name}`;
            case MessageType.COS:
                return `Start confirmed for task ${task.task_name}`;
            case MessageType.UST:
                return `Reject start of task ${task.task_name}. Task status reset to "not started"`;
            case MessageType.REJ:
                return `Rejected completion of task ${task.task_name}`;
            default:
                return 'Event error - type not found';
        }
    }

    useEffect(() => {
        subsManager.subscribeRecentLedgerList(member.userId, activeProject!.projectId);
    }, []);

    const calculateTimeSince = (time: Date) => {
        const currentTime = new Date();
        const timeDifference = currentTime.getTime() - time.getTime();
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days > 0) {
            return `${days} day${days > 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        } else {
            return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
        }
    }

    return (
        <Modal open={true} closeIcon onClose={() => {
            setOpen(null)
            subsManager.removeSubscription('recent-ledger-list')
        }}>
            <Modal.Header><h3>Recent action from <b>{`${member.userEmail}`}</b></h3></Modal.Header>
            <Modal.Content>
                {recentUserItems.length === 0 ? <p>No recent actions</p> :
                    <div className={styles.RecentUserItems}>
                        {recentUserItems.map((item, index) => {
                            const task: CpmTaskModel = cpmMap.get(item.taskId);
                            return (
                                <span key={index}
                                      onClick={() => {
                                          const taskData = allTasks.find(task => task.task_id === item.taskId);
                                          const searchValue = task.task_name.split(' - ')[0];
                                          dispatch(SearchActions.removeAllChip())
                                          dispatch(SearchActions.addChip([{ type: "filter", text: searchValue }]));
                                          dispatch(taskActions.Actions.setActiveTask(taskData || null));
                                          dispatch(projectActions.Actions.setLedgerOpen(true));
                                          setOpen(null)
                                          if (props.setSubModalOpen) {
                                              props.setSubModalOpen(false)
                                          }
                                      }}
                                >
                                    <p>{formatText(item, task)}</p>
                                    {item.suspendReason && <p
                                        style={{color: "red",
                                        fontSize: "13px"
                                    }}>
                                        {item.suspendReason}</p>}
                                    {item.text && <p style={{fontSize: "13px"}}>
                                        <i>"{item.text}"</i></p>}
                                    <p style={{fontSize: "12px"}}><b>
                                        {calculateTimeSince(item.logTimestamp?
                                        item.logTimestamp.toDate() :
                                        item.timestamp.toDate())}
                                        </b>
                                    </p>
                                </span>
                            )
                        })}
                    </div>}
            </Modal.Content>
        </Modal>
    )
}