import FirebaseUsage from "../firebase.usage";
import { COLLECTIONS } from "../constants";
import { DefaultSubscribeCallback } from "../../store/middleware/middlewares";
import MessageModel from "../../models/responses/message.model";

export class LedgerSubscriptions {
  static ledgerList(
    taskId: string,
    projectId: string,
    callback: DefaultSubscribeCallback<MessageModel[]>
  ) {
    return FirebaseUsage.queryListenerWithOrder(COLLECTIONS.LEDGER_ENTRY, [
        ["projectId", "==", projectId],
        ["taskId", "==", taskId]], ["logTimestamp", "desc"], (data: any) =>
        callback(data.docs.map((d: any) => d.data() as MessageModel)));
  }

  static recentLedgerList(userId: string, projectId: string, callback: DefaultSubscribeCallback<MessageModel[]>) {
      return FirebaseUsage.getQueryWithOrderAndLimit(COLLECTIONS.LEDGER_ENTRY,
          [['projectId', '==', projectId],
          ['userId', '==', userId]],
          ['logTimestamp', 'desc'], 5)
            .then(data => {
                callback(data.docs.map(el => el.data() as MessageModel));
            });
  }
}
