import styles from './ChangeAnalysisPage.module.scss';
import React from 'react';


export default function ChangeAnalysisPage() {
    return (
        <div className={styles.ChangeAnalysisPage}>

        </div>
    )
}