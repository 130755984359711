// import updatePredStatus from "./UpdatePredStatus";
import FirebaseUsage from "../../../../firebase/firebase.usage";
import {COLLECTIONS} from "../../../../firebase/constants";

export default async function cpmSchedule (
    noPredTasks: any[],
    noSuccTasks: any[],
    tasksMap: Map<any, any>,
    calendarsMap: Map<any, any>,
    projectId: string,
    latestDate: number,
    trackedMilestones: any[],
    genericUpperLimit: number,
    genericLowerLimit: number,
    runIndex,
    analysisMap
) {

    let cpmMap = new Map(tasksMap)
    const masterCalendarDict = async (seconds, calendarId) => {
        return parseInt(calendarsMap.get(`mcd:${seconds}:${calendarId}`))
    }

    const specificCalendarDict = async (index, calendarId) => {
        return parseInt(calendarsMap.get(`scd:${calendarId}:${index}`))
    }

    const masterWorkPatternDict = async (index, calendarId) => {
        return parseInt(calendarsMap.get(`mwp:${index}:${calendarId}`))
    }

    async function convertIndexToSeconds(index, calendarId) {
        const date = await specificCalendarDict(index, calendarId)
        if (isNaN(date)) {
            return await convertOutOfRangeIndexToDate(index, calendarId)
        } else {
            return date
        }
    }

    async function generateTask(taskId) {
        return cpmMap.get(taskId)
    }

    async function generateLink(linkId) {
        return cpmMap.get(linkId)
    }

    async function convertOutOfRangeIndexToDate(index, calendarId) {
        const maxDate = parseInt(calendarsMap.get(`swp:maxDate:${calendarId}`))
        const maxIndex = parseInt(calendarsMap.get(`swp:maxIndex:${calendarId}`))
        // const weeklyIndices = specificWorkPatternDict[calendarId].weeklyIndices
        const halfHours = parseInt(calendarsMap.get(`swp:wi:count:${calendarId}`))
        const weeks = Math.floor((index - maxIndex) / halfHours)
        const maxWeekIndex = parseInt(calendarsMap.get(`swp:maxWeekIndex:${calendarId}`))
        const relativeMaxWeekIndex = await masterWorkPatternDict(maxWeekIndex, calendarId)
        const relativeMaxDayIndex = parseInt(calendarsMap.get(`swp:maxDayIndex:${calendarId}`))
        const relativeMaxHalfHourIndex = parseInt(calendarsMap.get(`swp:wi:h:${calendarId}:${relativeMaxWeekIndex}`))
        const remainingHalfHours = index - maxIndex - (weeks * halfHours)
        const newIndex = remainingHalfHours + relativeMaxWeekIndex > halfHours ? (remainingHalfHours + relativeMaxWeekIndex) - halfHours : remainingHalfHours + relativeMaxWeekIndex
        const newWeekDayIndex = parseInt(calendarsMap.get(`swp:wi:d:${calendarId}:${newIndex}`))
        const additionalDays = relativeMaxWeekIndex + remainingHalfHours > halfHours ? 7 - relativeMaxDayIndex + newWeekDayIndex : newWeekDayIndex - relativeMaxDayIndex

        return maxDate + (weeks * 604800) + (additionalDays * 86400) + ((parseInt(calendarsMap.get(`swp:wi:h:${calendarId}:${newIndex}`)) - relativeMaxHalfHourIndex) * 1800)
    }

    async function getOutOfRangeLateralIndex (index, calendarId, lateralCalendarId, addOn) {
        if (calendarId === lateralCalendarId) {
            return index + addOn
        }
        const date = await convertOutOfRangeIndexToDate(index, calendarId)
        const maxDate = parseInt(calendarsMap.get(`swp:maxDate:${lateralCalendarId}`))
        const maxIndex = parseInt(calendarsMap.get(`swp:maxIndex:${lateralCalendarId}`))
        const weekCount = Math.floor((date - maxDate - 1800 - (3 * 86400)) / 604800)
        const weekHalfHourCount = parseInt(calendarsMap.get(`swp:wi:count:${lateralCalendarId}`))
        const weekIndexDatePlusWeeks = (((maxDate + (weekCount * 604800)) - 1800 - (3 * 86400)) -
            Math.floor(((maxDate + (weekCount * 604800)) - 1800 - (3 * 86400)) / 604800) * 604800) / 1800
        const correspondingCalendarIndexDatePlusWeeks = await masterWorkPatternDict(weekIndexDatePlusWeeks, lateralCalendarId)
        const weekIndexDate = ((date - 1800 - (3 * 86400)) - Math.floor((date - 1800 - (3 * 86400)) / 604800) * 604800) / 1800
        const correspondingCalendarIndexDate = await masterWorkPatternDict(weekIndexDate, lateralCalendarId)
        const indicesToAdd = correspondingCalendarIndexDatePlusWeeks < correspondingCalendarIndexDate ?
            correspondingCalendarIndexDate - correspondingCalendarIndexDatePlusWeeks :
            weekHalfHourCount - correspondingCalendarIndexDatePlusWeeks + correspondingCalendarIndexDate

        return maxIndex + (weekCount * weekHalfHourCount) + indicesToAdd + addOn
    }

    const handleTypes = {
        'TT_LOE': 0,
        'TT_FinMile': 1,
        'TT_Mile': 0,
        'TT_Task': 0,
        'TT_Rsrc': 0,
        'TT_WBS': 0,
        'TT_TASK': 0,
        'TT_RSRC': 0,
        'TT_MILE': 0,
        'TT_FINMILE': 1,
    }

    const handlePredTypes = {
        'TT_LOE': 0,
        'TT_FinMile': 0,
        'TT_Mile': 1,
        'TT_Task': 0,
        'TT_Rsrc': 0,
        'TT_WBS': 0,
        'TT_TASK': 0,
        'TT_RSRC': 0,
        'TT_MILE': 1,
        'TT_FINMILE': 0,
    }

    const handleTypesBp = {
        'TT_LOE': 0,
        'TT_FinMile': 0,
        'TT_Mile': -1,
        'TT_Task': -1,
        'TT_Rsrc': -1,
        'TT_WBS': 0,
        'TT_TASK': -1,
        'TT_RSRC': -1,
        'TT_MILE': -1,
        'TT_FINMILE': 0,
    }

    const handleTaskStatus = {
        'not started': 1,
        'in progress': 1,
        'completed': 0,
        'declared complete': 0,
    }

    const handleTypesFinish = {
        'TT_LOE': 1,
        'TT_FinMile': 1,
        'TT_Mile': 0,
        'TT_Task': 1,
        'TT_Rsrc': 1,
        'TT_WBS': 0,
        'TT_TASK': 1,
        'TT_RSRC': 1,
        'TT_MILE': 0,
        'TT_FINMILE': 1,
    }

    function getTaskDuration(duration: number, upperLimit: number, lowerLimit: number) {
        // use box-muller to get a random number
        upperLimit = upperLimit * duration
        lowerLimit = lowerLimit * duration
        const range = upperLimit - lowerLimit
        const randomGauss = () => {
            const theta = 2 * Math.PI * Math.random();
            const rho = Math.sqrt(-2 * Math.log(1 - Math.random()));
            return (rho * Math.cos(theta)) / 10.0 + 0.5;
        };
        const random = randomGauss();
        // return random < 0.5 ? Math.round(duration - (((0.5 - random) * 2) * (duration - lowerLimit))) :
        //     Math.round(duration + (((random - 0.5) * 2) * (upperLimit - duration)))
        return Math.round(lowerLimit + (range * random))
    }


    async function getLateralIndex(index, calendarId, lateralCalendarId, addOn) {
        if (calendarId === lateralCalendarId) {
            return index + addOn
        } else {
            const lateralIndex = await masterCalendarDict(await specificCalendarDict(index, calendarId), lateralCalendarId)
            if (!isNaN(lateralIndex)) {
                if (await specificCalendarDict(index, calendarId) === await specificCalendarDict(lateralIndex, lateralCalendarId)) {
                    return lateralIndex + addOn
                } else {
                    return lateralIndex
                }
            } else {
                return getOutOfRangeLateralIndex(index, calendarId, lateralCalendarId, addOn)
            }
        }
    }

    function checkRemainingDuration(task) {
        if (task.duration === 0) {
            return 0
        } else {
            return 1
        }
    }

    // Forward Pass
    async function calculateEsEf(link) {
        let predIndex
        let cd
        const predTask = await generateTask(link.pred_task_id)
        const succTask = await generateTask(link.task_id)

        const statusValue = Math.max(handleTaskStatus[succTask.status_code], handleTaskStatus[predTask.status_code])
        const lag = parseFloat(link.lag_hr_cnt)
        const handleNegativeLag = lag < 0 ? lag : 0

        if (link.pred_type === 'FS') {
            predIndex = Math.max(Math.round(predTask.ef + (lag * statusValue)), predTask.ad + predTask.duration - checkRemainingDuration(predTask) + handleNegativeLag)
            cd = 1 - Math.max(handleTypes[succTask.task_type], handlePredTypes[predTask.task_type], 1 - checkRemainingDuration(predTask))
        }
        else if (link.pred_type === 'FF') {
            predIndex = Math.max(Math.round(predTask.ef + (lag * statusValue) - succTask.duration), predTask.ad +
                predTask.duration - checkRemainingDuration(predTask) - succTask.duration + handleNegativeLag)
            cd = 1 - Math.max(handleTypes[succTask.task_type], handlePredTypes[predTask.task_type], 1 - checkRemainingDuration(predTask))
        }
        else if (link.pred_type === 'SS') {
            predIndex = Math.max(Math.round(predTask.es + (lag * statusValue)), predTask.ad + handleNegativeLag)
            cd = 0
        }
        else {
            predIndex = Math.max(Math.round(predTask.es + (lag * statusValue) - succTask.duration), predTask.ad - succTask.duration + handleNegativeLag)
            cd = 0
        }

        predIndex = await getLateralIndex(predIndex, predTask.cal_id, succTask.cal_id, cd)
        cpmMap.set(link.link_id, {...link, "ad": predIndex})

        if (predIndex > succTask.ad && predTask.task_type !== 'TT_LOE') {
            succTask.ad = predIndex
        }

        succTask.p_cnt -= 1

        if (succTask.p_cnt <= 0) {
            if (succTask.status_code === 'not started') {
                succTask.es = succTask.ad
            }
            if (succTask.duration === 0) {
                succTask.ef = succTask.status_code !== 'completed' ? succTask.ad : succTask.ef
            } else {
                succTask.ef = succTask.status_code !== 'completed' ? succTask.ad + succTask.duration: succTask.ef
            }
            if (succTask.ef === '') {
                if (succTask.duration === 0) {
                    succTask.ef = succTask.ad
                } else {
                    succTask.ef = succTask.ad + succTask.duration - 1
                }
            }

            cpmMap.set(succTask.task_code, {...succTask, p_cnt: succTask.p_sv})

            const successors = cpmMap.get(`${succTask.task_code}:succs`)
            for (const successor of successors) {
                await calculateEsEf(await generateLink(successor))
            }
        } else {
            cpmMap.set(succTask.task_code, succTask)
        }
    }

    // Get all tasks
    const allTasks = cpmMap.get(`${projectId}:tasks`)
    for (const task of allTasks) {
        const taskData = await generateTask(task)
        cpmMap.set(task, {...taskData,
            duration: getTaskDuration(taskData.duration, genericUpperLimit, genericLowerLimit),
        })
    }


    for (const task of noPredTasks) {
        const successors = cpmMap.get(`${task}:succs`)
        for (const successor of successors) {
            await calculateEsEf(await generateLink(successor))
        }
    }


    let outputObj = {}
    for (const milestone of trackedMilestones) {
        const task = cpmMap.get(milestone.taskId)
        outputObj[milestone.taskId] = await convertIndexToSeconds(task.ef, task.cal_id)
    }
    analysisMap.set(runIndex, {...outputObj})

    // console.log("CPM took " + (new Date().getTime() - timeStart) + "ms")

    return analysisMap
}
