enum EventTextModel {
  LEAVE_TASKFORCE = "Leave taskforce",
  JOIN_TASKFORCE = "Join taskforce",
  START_TASK = "Start task",
  RESUME_TASK = "Resume task",
  COMPLETE_TASK = "Complete task",
  BLOCK_TASK = "Block task",
  UNBLOCK_TASK = "Unblock task",
  SUSPEND_TASK = "Suspend task",
  REFORECAST_TASK = "Reforecast task end date",
  EVALUATE_TASK = "Evaluate task completion",
  EVENT_DESCRIPTION = "Event description",
  ADD_EVENT_LOG = "Add event log",
  LOG_ISSUE = "Log issue",
  APPROVE_NEW_TASK = "Approve new task",
  EVALUATE_TASK_START = "Evaluate task start",
  REFORECAST_TASK_START = "Reforecast task start",
}


export default EventTextModel;
