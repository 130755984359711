import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes, RouterProvider, useNavigate } from "react-router-dom";
import routingConstants from "./routingConstands";
import * as authorizationActions from "./store/actions/authorization.actions";
import FirebaseUsage from "./firebase/firebase.usage";
import { COLLECTIONS } from "./firebase/constants";
import { UserDocModel } from "./models/responses/user.model";
import LoaderPage from "./pages/LoaderPage/LoaderPage";
import AuthorizationThunk from "./store/thunk/authorization.thunk";
import { admins } from "./utils/admins";
import { useTypedSelector } from "./store/selectors/selectors.utils";
import firebaseConfig from "./config/firebase.config";
import {PendingMembershipsModel} from "./models/pending-memberships.model";
import ProjectModel from "./models/responses/project.model";
import ProjectMembersModel from "./models/responses/project-members.model";
import {MemberOrganizationEnum} from "./models/member-organization.enum";
import {GateKeeperEum} from "./models/gate-keeper.enum";
import SidebarExampleSidebar from "./pages/ProjectPage/components/UserSideBar/UserSideBar";
import {Actions} from "./store/actions/authorization.actions";


const AppRouting = () => {
  const dispatch = useDispatch();
  const checkedAuth = useTypedSelector(
    (state) => state.authorization.checkedAuth
  );
  const currentUser = useTypedSelector((state) => state.authorization.user);
  const sideBarOpen = useTypedSelector((state) => state.authorization.userSidebarOpen);

  const colorPalette = [
    '#e879f9', '#c084fc', '#f472b6', '#38bdf8', '#2dd4bf', '#f87171', '#fb923c', '#a3e635',
  ]

  useEffect(() => {
    if (checkedAuth && currentUser) {
      FirebaseUsage
        .getToken()
        .then(async (refreshedToken) => {
          try {
            await FirebaseUsage.updateDoc(COLLECTIONS.USERS, currentUser?.userId || "", {
                settings: {
                  ...currentUser.settings,
                  token: refreshedToken,
                },
              });
          } catch (e) {
            console.log("Error: Retrieve refreshedToken: ", e);
          }
        });
    }
  }, [checkedAuth, currentUser]);

  useEffect(() => {
    FirebaseUsage.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const accessToken = await user.getIdToken();
        const displayName = user.displayName;
        // const photoURL = user.photoURL;
        // let hasUser = false;
        // let userData: UserDocModel | any;
        // try {
        //   await FirebaseUsage.getQuery(COLLECTIONS.USERS, ["userEmail", "==", user.email]).
        //   then((snapshot) => {
        //       if (snapshot.docs.length) {
        //         // hasUser = true;
        //         userData = snapshot.docs[0] ? snapshot.docs[0].data() as UserDocModel : {userId: null, settings: {notifications: {}},};
        //       }
        //     }) } catch (e) {
        //   userData = {userId: user.uid, settings: {notifications: {}},};
        // }

        const userDataQuery = await FirebaseUsage.getQuery(COLLECTIONS.USERS, ["userEmail", "==", user.email]).
        then((snapshot) => snapshot.docs.map((doc) => doc.data() as UserDocModel));

        let userData: UserDocModel | any;

        if (userDataQuery.length) {
          userData = userDataQuery[0];
        }

        if (userData && !userData.userId) {
          userData.userId = user.uid;
        } else if (!userData) {
          userData = {userId: user.uid, settings: {notifications: {}},};
        }

        if (!userData.userColour) {
            userData.userColour = colorPalette[Math.floor(Math.random() * colorPalette.length)];
        }

        const newIsAdmin = user.email ? admins.includes(user.email) : false;
        let firebaseToken: any = null;
        try {
          if (!("Notification" in window)) {
            alert("This browser does not support desktop notification");
          } else if (Notification.permission === "granted") {
            firebaseToken = await FirebaseUsage.getTokenFromMessaging({
              vapidKey: firebaseConfig.vapidKey,
            });
          } else if (Notification.permission === "default") {
            Notification.requestPermission().then(() => {
              if (Notification.permission === "granted") {
                FirebaseUsage.getTokenFromMessaging({
                    vapidKey: firebaseConfig.vapidKey,
                  })
                  .then((currentToken) => {
                    if (currentToken) {
                      firebaseToken = currentToken;
                    }
                  });
              }
            });
          } else {
            alert("Notfications is not enabled please enable it ");
            firebaseToken = await FirebaseUsage.getTokenFromMessaging({
              vapidKey: firebaseConfig.vapidKey,
            });
          }
        } catch (error) {
          console.log("error", error);
        }

        // Check for pending memberships
        const pendingMemberships = await FirebaseUsage.getQuery(COLLECTIONS.PENDING_MEMBERSHIPS, ["email", "==", user.email])
            .then((snapshot) => snapshot.docs.map((doc) => doc.data() as PendingMembershipsModel));
        for (const membership of pendingMemberships) {
          const project = await FirebaseUsage.getDoc(COLLECTIONS.PROJECTS, membership.projectId)
              .then((doc) => doc.data() as ProjectModel);
          const projectGrade = project.grade;
          await FirebaseUsage.updateDoc(COLLECTIONS.PROJECTS, membership.projectId, {
            grade:
                user.uid === userData.userId ? {
                  ...projectGrade,
                  [user.uid]: membership.grade,
                } : {
                  ...projectGrade,
                  [user.uid]: membership.grade,
                  [userData.userId]: membership.grade,
                }
          });

          const projectMemberDoc = FirebaseUsage.getBlankDoc(COLLECTIONS.PROJECT_MEMBERS);
          const memberData: ProjectMembersModel = {
            is_admin: membership.grade === MemberOrganizationEnum.SUPER_ACTOR,
            is_ledger: false,
            is_pending: false,
            projectId: membership.projectId,
            grade: membership.grade,
            gateKeeper: GateKeeperEum.None,
            userEmail: user.email || "",
            memberId: projectMemberDoc.id,
            userId: userData.userId,
            groupId: "",
            tasks: 0,
            lastViewed: FirebaseUsage.timestamp(),
            totalViews: 0,
          };

          await FirebaseUsage.setDocument(COLLECTIONS.PROJECT_MEMBERS, projectMemberDoc.id, memberData);
          FirebaseUsage.deleteDocument(COLLECTIONS.PENDING_MEMBERSHIPS, membership.pendingId)
              .catch((error) => console.error(error));

          if (!userData.asMember) {
            userData.asMember = {};
          }
          userData.asMember[membership.projectId] = {
            grade: membership.grade,
            projectId: membership.projectId,
            memberId: projectMemberDoc.id,
          };
        }

        const userObj: UserDocModel = {
          userEmail: user.email || "",
          authenticated: true,
          isAdmin: newIsAdmin,
          enableSA: userData.enableSA || false,
          trial: false,
          isDeleteProjects:false,
          asMember: {},
          ...userData,
          lastLogin: FirebaseUsage.timestamp(),
          displayName: displayName,
          totalVisits: userData.totalVisits ? userData.totalVisits += 1 : 1,
          settings: {
            token: null,
            firebaseToken: firebaseToken,
            notifications: {
              preferences: false,
              waitingToStart: false,
              started: false,
              resumed: false,
              reforecasted: false,
              suspended: false,
              declaredComplete: false,
              evaluatedComplete: false,
              addedByOthers: false,
              ...(userData.settings ? userData.settings.notifications : {}),
            },
          },
        };

        try {
          userObj.settings.token =
            await FirebaseUsage.getToken();
        } catch (error) {
          console.error(error);
        }
        await FirebaseUsage.setDocument(COLLECTIONS.USERS, userObj.userId, userObj)
        dispatch(authorizationActions.Actions.auth(userObj));
      } else {
          // @ts-ignore
          dispatch(AuthorizationThunk.signInWithEmailLink());
      }
    });
  }, [checkedAuth]);

  return checkedAuth ? (
      <div style={{minWidth: "100vw"}}>
        <SidebarExampleSidebar
            visible={sideBarOpen}
            setVisible={(bool: boolean) => dispatch(Actions.setUserSidebarOpen(bool))}
            children={<RouterProvider router={routingConstants}/>}/>
      </div>
  ) : (
    <LoaderPage />
  );
};

export default AppRouting;
