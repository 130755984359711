import React, { useEffect, useMemo, useRef, useState } from 'react';
import { APIProvider, Map } from '@vis.gl/react-google-maps';
import {
    useActiveGeoSelector, useActiveProjectCalendarsSelector,
    useCpmMapSelector,
    useGeoDataSelector
} from "../../../../store/selectors/project.selectors";
import {
    useActiveTaskSelector, useAllTaskListSelector,
} from "../../../../store/selectors/task/task.selectors";
import GeoModel from "../../../../models/responses/geo.model";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import ImageModal from "../ImageModal/ImageModal";
import styles from "./GoogleMap.module.scss";
import MapFilter from "./components/MapFilter";
import {useMapSearchTextSelector, useSearchParamatersSelector} from "../../../../store/selectors/search.selectors";
import {useProjectMemberListSelector} from "../../../../store/selectors/team.selectors";
import TaskModel from "../../../../models/responses/task.model";
import filterTaskList from "../../../../utils/task-filtering";

export default  function GoogleMapVisGL() {
    const [openInfoWindow, setOpenInfoWindow] = useState("");
    const [fromDate, setFromDate] = useState<Date | null>(null);
    const [toDate, setToDate] = useState<Date | null>(null);
    const [modalImage, setModalImage] = useState<any>(null);
    const [resizeHeight, setResizeHeight] = useState<any>(null);
    const [resizing, setResizing] = useState<boolean>(false);
    const geoData = useGeoDataSelector();
    const activeTask = useActiveTaskSelector()
    const searchParameters = useSearchParamatersSelector();
    const mapSearchText = useMapSearchTextSelector()
    const [zoom, setZoom] = useState<number>(14);
    const markerClustererRef = useRef<MarkerClusterer | null>(null);
    const mapRef = useRef<google.maps.Map | null>(null);
    const cpmMap = useCpmMapSelector();
    const calendarMap = useActiveProjectCalendarsSelector();
    const projectMembers = useProjectMemberListSelector();
    const activeGeo = useActiveGeoSelector()
    const tasks = useAllTaskListSelector();

    const allTasks = useMemo(() => {
        return filterTaskList(tasks, searchParameters, cpmMap, calendarMap);
    }, [tasks, searchParameters])

    function handleMouseMove(e: any) {
        if (resizing) {
            setResizeHeight(e.clientY - 160);
        }
    }

    document.addEventListener('mouseup', (e) => {
        if (resizing) {
            setResizing(false);
            document.removeEventListener('mousemove', handleMouseMove, true)
            }
    }, true)

    document.addEventListener('mousemove', handleMouseMove, true)

    const minDate = useMemo(() => {
        let minDate = new Date().getTime()
        geoData.forEach((geo: GeoModel) => {
            if (geo.dateCaptured && geo.dateCaptured?.seconds * 1000 < minDate) {
                minDate = geo.dateCaptured.toDate().getTime()
            }
        });
        return new Date(minDate);
    }, [geoData])

    const maxDate = useMemo(() => {
        let maxDate = 0
        geoData.forEach((geo: GeoModel) => {
            if (geo.dateCaptured && geo.dateCaptured?.seconds * 1000 > maxDate) {
                maxDate = geo.dateCaptured.toDate().getTime()
            }
        });
        return new Date(maxDate);
    }, [geoData])

    const createMarkers = (geoDataInput: GeoModel[]) =>
        geoDataInput.map((position, i) => {
            const label = "📷";
            const pinGlyph = new google.maps.marker.PinElement({
                glyph: label,
                glyphColor: "white",
            })
            const marker = new google.maps.marker.AdvancedMarkerElement({
                position: { lat: position.gpsLat, lng: position.gpsLong },
                content: pinGlyph.element,
            });

            marker.addListener("click", () => {
                setModalImage(position);
            });
            return marker;
        });

    async function initMap(geoDataArray: GeoModel[]) {
        const { Map, InfoWindow } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
        const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

        const map = new google.maps.Map(
            document.getElementById("map") as HTMLElement,
            {
                zoom: zoom,
                center: getCentroids(geoDataArray).latLng,
                mapId: '49211e96821b32bc',
                gestureHandling: "greedy",
            }
        );

        mapRef.current = map;

        map.addListener("zoom_changed", () => {
            const zoom = map.getZoom();
            setZoom(zoom ? zoom : 14);
        });

        const markers = createMarkers(runFilters());

        if (geoDataArray.length > 0) {
            // fit zoom to markers
            const bounds = new google.maps.LatLngBounds();
            markers.forEach((marker) => {
                // @ts-ignore
                bounds.extend({lat: marker.position?.lat, lng: marker.position?.lng});
            });
            map.fitBounds(bounds);
        } else {
            map.setZoom(3);
            map.setCenter({lat: 51.509865, lng: -0.118092});
        }

        // Add a marker clusterer to manage the markers.
        markerClustererRef.current = new MarkerClusterer({ markers, map });
    }

    function getCentroids(geoDataArray: GeoModel[]) {
        let maxLat: number | null = null;
        let minLat: number | null = null;
        let maxLng: number | null = null;
        let minLng: number | null = null;
        geoDataArray.forEach((geo) => {
            if (maxLat === null || geo.gpsLat > maxLat) {
                maxLat = geo.gpsLat;
            }
            if (minLat === null || geo.gpsLat < minLat) {
                minLat = geo.gpsLat;
            }
            if (maxLng === null || geo.gpsLong > maxLng) {
                maxLng = geo.gpsLong;
            }
            if (minLng === null || geo.gpsLong < minLng) {
                minLng = geo.gpsLong;
            }
        });
        return { latLng: {
            lat: (maxLat! + minLat!) / 2,
            lng: (maxLng! + minLng!) / 2
            }, delta: maxLat! - minLat!
        };
    }

    const applyFiltering = (filteredGeoData: GeoModel[]) => {
        if (markerClustererRef.current) {
            markerClustererRef.current.clearMarkers();
            if (filteredGeoData.length > 0) {
                const centroids = getCentroids(filteredGeoData).latLng;
                const markers = createMarkers(filteredGeoData);
                const bounds = new google.maps.LatLngBounds();
                markers.forEach((marker) => {
                    // @ts-ignore
                    bounds.extend({ lat: marker.position?.lat, lng: marker.position?.lng });
                });
                markerClustererRef.current.addMarkers(markers);
                mapRef.current?.setCenter(centroids);
                mapRef.current?.fitBounds(bounds);
            }
        }
    }

    function runFilters () {
        if (activeGeo)
            return [activeGeo.geo];
        let filteredGeoData = geoData;
        if (fromDate) {
            if (toDate) {
                filteredGeoData = filteredGeoData.filter((geo: GeoModel) => {
                    if (!geo.dateCaptured) {
                        return false;
                    }
                    const date = geo.dateCaptured.toDate().getTime();
                    return date >= fromDate.getTime() && date <= toDate.getTime() + 86400000;
                });
            } else {
                filteredGeoData = filteredGeoData.filter((geo: GeoModel) => {
                    if (!geo.dateCaptured) {
                        return false;
                    }
                    const date = geo.dateCaptured.toDate().getTime();
                    return date >= fromDate.getTime();
                });
            }
        } else if (toDate) {
            filteredGeoData = filteredGeoData.filter((geo: GeoModel) => {
                if (!geo.dateCaptured) {
                    return false;
                }
                const date = geo.dateCaptured.toDate().getTime();
                return date <= toDate.getTime() + 86400000;
            });
        }

        if (activeTask) {
            filteredGeoData = filteredGeoData.filter((geo: GeoModel) => geo.taskId === activeTask.task_id)
        } else {
            if (searchParameters.taskForces.length > 0) {
                filteredGeoData = filteredGeoData.filter((geo: GeoModel) => {
                    const task = allTasks.find((task: TaskModel) => task.task_id === geo.taskId);
                    return task !== undefined
                });
            }
            if (searchParameters.searchText !== '') {
                filteredGeoData = filteredGeoData.filter((geo: GeoModel) => {
                    const task = allTasks.find((task: TaskModel) => task.task_id === geo.taskId);
                    return task !== undefined
                });
            }
        }
        if (mapSearchText !== '') {
            filteredGeoData = filteredGeoData.filter((geo: GeoModel) => {
                const task = cpmMap.get(geo.taskId);
                const user = projectMembers.filter((member) => member.userId === geo.userId)[0];
                const userEmail = user ? user.userEmail : '';
                const searchText = task ? task.task_name + " " + task.wbsPath + " " + geo.text + " " + userEmail : geo.text + " " + userEmail
                return searchText.toLowerCase().includes(mapSearchText.toLowerCase())
            });
        }
        return filteredGeoData;
    }

    useEffect(() => {
        const filteredGeo = runFilters();
        applyFiltering(filteredGeo)
    }, [mapSearchText, geoData, activeTask, toDate, fromDate, activeGeo, searchParameters])

    return (
        <div className={styles.GoogleMapContainer}
             style={resizeHeight && {height: `${resizeHeight}px`}}
        >
            <APIProvider apiKey={'AIzaSyBWP8mtZNfdei0zyJuOLr2gm4MK8fIhivs'}
                         onLoad={() => initMap(geoData)}
            >
                <Map id="map" mapId={'49211e96821b32bc'} controlSize={0.5} defaultZoom={14}
                     defaultCenter={{lat: 53.96177, lng: -1.10449}} onZoomChanged={(event) => console.log(event)}>
                </Map>
            </APIProvider>
            <div className={styles.GoogleMapContainerResize}
                 onMouseDown={() => setResizing(true)}></div>
            {modalImage &&
                <ImageModal
                    imageId={modalImage.imageId}
                    setModalOpen={setModalImage}
                    geoData={modalImage}
                    task={cpmMap.get(modalImage.taskId)}
                    allTasks={allTasks}
                />}
            <MapFilter minDate={minDate} maxDate={maxDate} setFromDate={setFromDate} setToDate={setToDate}
                       fromDate={fromDate} toDate={toDate}/>
        </div>
    );
}
