import React, { useCallback, useEffect, useState, useRef } from "react";
import { Button, Dropdown, Form, Input, Modal, Popup } from "semantic-ui-react";
import {
  useUserGradeSelector,
  useUserSelector,
} from "../../../../../store/selectors/authorization.selectors";
import { TaskForce } from "./TaskForce/TaskForce";
import { ProjectTeam } from "./ProjectTeam/ProjectTeam";
import {
  usePendingProjectMemberListSelector,
  useProjectMemberListSelector,
  useTeamLoadingSidebarSelector,
} from "../../../../../store/selectors/team.selectors";
import { useDispatch } from "react-redux";
import { TeamThunk } from "../../../../../store/thunk/team.thunk";
import { MemberOrganizationEnum } from "../../../../../models/member-organization.enum";
import { useForm } from "../../../../../utils/user-form.hook";
import Validations from "../../../../../utils/validations";
import { useActiveTaskSelector } from "../../../../../store/selectors/task/task.selectors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { Timestamp } from "firebase/firestore";
import * as taskActions from "../../../../../store/actions/task.actions";
import {
  addGroupToProject,
  joinTaskForce,
  leaveTaskForce,
} from "../../../../../utils/taskforce.utils";
import { TaskThunk } from "../../../../../store/thunk/task.thunk";
import {subsManager} from "../../../../../store/middleware/subs-manager/subs-manager";
import {useActiveProjectSelector} from "../../../../../store/selectors/project.selectors";

export interface AddNewMemberFormValue {
  whoAdded: string;
  userEmail: string;
  userId: string;
  type: string;
  organization: MemberOrganizationEnum;
  groupId: string;
}

export const HeaderMember = () => {
  let memberList: any[] = useProjectMemberListSelector();
  const user = useUserSelector();
  const loading = useTeamLoadingSidebarSelector();
  const dispatch = useDispatch();
  const [showModal, setOPenModal] = useState<boolean>(false);
  const [isMemberGroup, setIsMemberGroup] = useState<boolean>(false);
  const [isEnteredEmailUserMember, setIsEnteredEmailUserMember] =
    useState(false);
  const [isEnteredEmail, setIsEnteredEmail] = useState(false);
  const userGrade = useUserGradeSelector();
  const activeTask = useActiveTaskSelector();
  const searchInputRef: any = useRef();
  const pendingMemberList = usePendingProjectMemberListSelector();
  const activeProject = useActiveProjectSelector();

  memberList = memberList.concat(pendingMemberList);

  const { useField, handleSubmit } = useForm<AddNewMemberFormValue>({
    form: "add_new_member",
    onSubmit: (value) => addNewProjectMember(value),
  });
  const userEmailFieldProps = useField("userEmail", "", 500);
  const organizationFieldProps = useField(
    "organization",
    isMemberGroup ? MemberOrganizationEnum.GROUPS : MemberOrganizationEnum.ACTOR
  );

  const toggleOrganizationModalCallback = useCallback(() => {
    const value = searchInputRef.current.inputRef.current.value;
    if (value.startsWith("@") && value.length > 1) {
      setIsMemberGroup(true);
      organizationFieldProps.setValue(MemberOrganizationEnum.GROUPS);
    } else {
      setIsMemberGroup(false);
      organizationFieldProps.setValue(MemberOrganizationEnum.ACTOR);
    }
    setOPenModal(!showModal);
  }, [showModal, organizationFieldProps, setIsMemberGroup]);

  useEffect(
    () => {
      const isEmail = Validations.email(userEmailFieldProps.input.value);
      const isSameDomain = Validations.sameEmailDomain(
        userEmailFieldProps.input.value,
        (user && user.userEmail) || ""
      );
      if (isEmail && userGrade[MemberOrganizationEnum.SUPER_ACTOR]) {
        // @ts-ignore
        const isMemberExist = memberList.some((member) =>
          member.userEmail.includes(userEmailFieldProps.input.value)
        );
        setIsEnteredEmailUserMember(isMemberExist);
        setIsEnteredEmail(isEmail);
      } else if (!isEmail && userGrade[MemberOrganizationEnum.SUPER_ACTOR]) {
        setIsEnteredEmail(false);
        setIsEnteredEmailUserMember(false);
      }

      if (
        isEmail &&
        userGrade[MemberOrganizationEnum.LEAD_ACTOR] &&
        isSameDomain
      ) {
        // @ts-ignore
        const isMemberExist = memberList.some((member) =>
          member.userEmail.includes(userEmailFieldProps.input.value)
        );
        setIsEnteredEmailUserMember(isMemberExist);
        setIsEnteredEmail(isEmail);
      } else if (
        !isEmail &&
        userGrade[MemberOrganizationEnum.LEAD_ACTOR] &&
        isSameDomain
      ) {
        setIsEnteredEmail(false);
        setIsEnteredEmailUserMember(false);
      }
    },
    []
  );

  const addNewTaskforceMember = useCallback(
    (data: AddNewMemberFormValue) => {
      if (activeTask) {
        if (
          memberList.some((member) => member.userEmail === data.userEmail) &&
          activeTask
        ) {
          dispatch({
            type: taskActions.ActionNames.TASK_TOOLBAR_LOADING,
            payload: { taskId: activeTask.task_id },
          });
          console.log("data", data)
          if (data.type === 'group') {
            // @ts-ignore
            dispatch(TaskThunk.addTaskForce(activeTask.task_id,data.userEmail,data.whoAdded,data.type,data.groupId, activeTask!.taskListType));
          } else {
            joinTaskForce(activeTask.task_id, data.userId || data.groupId, data.whoAdded, Timestamp.now()).then(
              () => {
                dispatch({
                  type: taskActions.ActionNames.TASK_TOOLBAR_SUCCESS,
                  payload: { taskId: activeTask.task_id },
                });
              }
              );
            }
        } else {
          console.log("There is no such email in project members");
        }
        userEmailFieldProps.setValue("");
        isMemberGroup
          ? organizationFieldProps.setValue(MemberOrganizationEnum.GROUPS)
          : organizationFieldProps.setValue(MemberOrganizationEnum.ACTOR);
      }
    },
    [
      dispatch,
      activeTask,
      memberList,
      isMemberGroup,
      organizationFieldProps,
      userEmailFieldProps,
    ]
  );

  const removeTaskForceMember = useCallback(
    (data: AddNewMemberFormValue) => {
      if (activeTask) {
        if (
          memberList.some((member) => member.userEmail === data.userEmail) &&
          activeTask
        ) {
          dispatch({
            type: taskActions.ActionNames.TASK_TOOLBAR_LOADING,
            payload: { taskId: activeTask.task_id },
          });
          leaveTaskForce(activeTask.task_id, data.userId, data.whoAdded, Timestamp.now()).then(
            () => {
              dispatch({
                type: taskActions.ActionNames.TASK_TOOLBAR_SUCCESS,
                payload: { taskId: activeTask.task_id },
              });
            }
          );
        } else {
          console.log("There is no such email in project members");
        }
        userEmailFieldProps.setValue("");
        isMemberGroup
          ? organizationFieldProps.setValue(MemberOrganizationEnum.GROUPS)
          : organizationFieldProps.setValue(MemberOrganizationEnum.ACTOR);
      }
    },
    [
      dispatch,
      activeTask,
      memberList,
      isMemberGroup,
      organizationFieldProps,
      userEmailFieldProps,
    ]
  );

  const addNewProjectMember = useCallback(
    (data: AddNewMemberFormValue) => {
      if (isMemberGroup) {
        addGroupToProject(data.userEmail, activeProject!.projectId)
            .catch((error) => console.error(error));
      } else {
        dispatch(
          // @ts-ignore
          TeamThunk.addNewProjectMember(data.userEmail, data.organization)
        );
      }
      toggleOrganizationModalCallback();
      userEmailFieldProps.setValue("");
      isMemberGroup
        ? organizationFieldProps.setValue(MemberOrganizationEnum.GROUPS)
        : organizationFieldProps.setValue(MemberOrganizationEnum.ACTOR);
      searchInputRef.current.inputRef.current.value = "";
      userEmailFieldProps.setValue("");
    },
    [
      dispatch,
      isMemberGroup,
      organizationFieldProps,
      toggleOrganizationModalCallback,
      userEmailFieldProps,
    ]
  );

  if (!user) {
    return null;
  }

  return (
    <>
      <div className="header-member-list-container">
        <div
          style={{
            position: "sticky",
            top: "0",
            zIndex: "200",
            backgroundColor: "white",
          }}
        >
          <TaskForce
            addNewTaskforceMember={addNewTaskforceMember}
            searchValue={userEmailFieldProps.input.value}
          />

          <p className="header-ledger-title">Talent Pool</p>
          {(userGrade[MemberOrganizationEnum.SUPER_ACTOR] ||
            userGrade[MemberOrganizationEnum.LEAD_ACTOR]) && (
            <Form
              className="header-ledger-post-commit-form"
              onSubmit={toggleOrganizationModalCallback}
            >
              <Form.Field>
                <Popup
                  trigger={
                    <Input
                      className="full-width full-width-search"
                      ref={searchInputRef}
                      onChange={(e) => {
                        e.persist();
                        userEmailFieldProps.input.onChange(e);
                      }}
                      loading={loading}
                      action={
                        (!loading &&
                          isEnteredEmail &&
                          !isEnteredEmailUserMember && (
                            <FontAwesomeIcon
                              icon={faSearch}
                              className="fa-search"
                            />
                          )) ||
                        (!loading && (
                          <FontAwesomeIcon
                            className="fa-search disabled"
                            icon={faSearch}
                          />
                        )) ||
                        null
                      }
                      placeholder={"Search / add people or groups"}
                    />
                  }
                  on="click"
                  open={isEnteredEmailUserMember || isEnteredEmail}
                  flowing
                >
                  <div>
                    <p>
                      {isEnteredEmail
                        ? "Add new project member"
                        : "User already assigned"}
                    </p>
                    <Button
                      color="red"
                      onClick={() => {
                        searchInputRef.current.inputRef.current.value = "";
                        userEmailFieldProps.setValue("");
                      }}
                    >
                      Clear
                    </Button>
                    <Button
                      color="green"
                      onClick={() => toggleOrganizationModalCallback()}
                    >
                      Add
                    </Button>
                  </div>
                </Popup>
              </Form.Field>
            </Form>
          )}
        </div>

        <ProjectTeam
          addNewTaskforceMember={addNewTaskforceMember}
          removeTaskforceMember={removeTaskForceMember}
          searchValue={userEmailFieldProps.input.value}
        />
      </div>

      <Modal
        size="mini"
        open={showModal}
        onClose={toggleOrganizationModalCallback}
      >
        <Modal.Header>
          {isMemberGroup ? "Add Group" : "Select member grade"}
        </Modal.Header>
        <Modal.Content>
          <Dropdown
            className="full-width"
            selection
            placeholder={
              isMemberGroup
                ? "Select groups organization"
                : "Select member organization"
            }
            value={organizationFieldProps.input.value}
            options={Object.keys(MemberOrganizationEnum)
              .filter((grade) => {
                if (isMemberGroup) {
                  return (
                    MemberOrganizationEnum[grade] ===
                    MemberOrganizationEnum.GROUPS
                  );
                } else {
                  if (userGrade[MemberOrganizationEnum.LEAD_ACTOR]) {
                    (MemberOrganizationEnum[grade] ===
                      MemberOrganizationEnum.OBSERVER ||
                      MemberOrganizationEnum[grade] ===
                        MemberOrganizationEnum.ACTOR) &&
                      MemberOrganizationEnum[grade] !==
                        MemberOrganizationEnum.GROUPS;
                  } else {
                    return (
                      MemberOrganizationEnum[grade] !==
                      MemberOrganizationEnum.GROUPS
                    );
                  }
                }
              })
              .map((organization, i) => ({
                key: `${organization}-${i}`,
                value: MemberOrganizationEnum[organization],
                text:
                  MemberOrganizationEnum[organization] ===
                  MemberOrganizationEnum.ACTOR
                    ? "Team-Member"
                    : MemberOrganizationEnum[organization] ===
                      MemberOrganizationEnum.LEAD_ACTOR
                    ? "Lead"
                    : MemberOrganizationEnum[organization] ===
                      MemberOrganizationEnum.SUPER_ACTOR
                    ? "Administator"
                    : MemberOrganizationEnum[organization],
                onClick: (e) =>
                  organizationFieldProps.input.onChange(
                    e,
                    MemberOrganizationEnum[organization]
                  ),
              }))}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={toggleOrganizationModalCallback}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            positive
            icon="checkmark"
            labelPosition="right"
            content={isMemberGroup ? "Add group" : "Add member"}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};
