import React, {useCallback, useEffect, useState} from "react";
import {useTypedSelector} from "../../../../../store/selectors/selectors.utils";
import {TaskPredecessors} from "./TaskPredecessors";
import {TaskSuccessors} from "./TaskSuccessors";
import {TaskListSection} from "./TaskListSection/TaskListSection";
import {TaskListSectionModel} from "../../../../../models/task-list-section.model";
import InfiniteScroll from "react-infinite-scroller";
import {useDispatch} from "react-redux";
import * as taskActions from "../../../../../store/actions/task.actions";
import * as projectActions from "../../../../../store/actions/project.actions";
import {Dropdown, Loader} from "semantic-ui-react";
import {
    useActiveDataVersionSelector,
    useActiveProjectCalendarsSelector,
    useActiveProjectSelector,
    useCpmMapSelector, useDataVersionsSelector,
    useProjectViewSelector
} from "../../../../../store/selectors/project.selectors";
import {
    useIncludeMilestonesSelector,
    useSearchParamatersSelector
} from "../../../../../store/selectors/search.selectors";
import TaskModel from "../../../../../models/responses/task.model";
import GoogleMapVisGL from "../../../components/GoogleMap/GoogleMapVisGL";
import filterTaskList from "../../../../../utils/task-filtering";
import TaskStatusModel from "../../../../../models/responses/task-status.model";
import {
    useAllTaskListSelector, useNextSnapshotSelector, useTaskSnapshotSelector
} from "../../../../../store/selectors/task/task.selectors";
import {subsManager} from "../../../../../store/middleware/subs-manager/subs-manager";
import {TaskType} from "../../../../../models/task-type";
import {convertIndexToSeconds} from "../../../../../utils/cpm-functions/cpm-app/functions/handleEvent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPersonRunningFast} from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";

export const SprintTaskList = () => {
    const [view, setView] = useState<"future" | "past">("future");
    const [sortCriteria, setSortCriteria] = useState<"index" | "deadline" | "target">("index");
    const activeProject = useActiveProjectSelector();
    const dispatch = useDispatch();
    const projectView = useProjectViewSelector();

    let now = new Date();
    now.setMinutes(0, 0, 0);

    const loading = useTypedSelector((state) => state.task.taskListLoading);
    const moreTasks = useTypedSelector((state) => state.task.moreTasks);
    const searchParams = useSearchParamatersSelector();
    const allTasks = useAllTaskListSelector();
    const includeMilestones = useIncludeMilestonesSelector();
    const dataVersions = useDataVersionsSelector();
    // const [activeDataVersion, setActiveDataVersion] = useState<any>(dataVersions[0]);

    const [filteredArchivedTaskList, setFilteredArchivedTaskList] = useState<TaskModel[]>([]);
    const [filteredDeclaredTaskList, setFilteredDeclaredTaskList] = useState<TaskModel[]>([]);
    const [filteredInProgressTaskList, setFilteredInProgressTaskList] = useState<TaskModel[]>([]);
    const [archivedOpen, setArchivedOpen] = useState(false);
    const [declaredOpen, setDeclaredOpen] = useState(false);
    const [inProgressOpen, setInProgressOpen] = useState(true);
    const [archivedTaskCount, setArchivedTaskCount] = useState(0);
    const [declaredTaskCount, setDeclaredTaskCount] = useState(0);
    const [inProgressTaskCount, setInProgressTaskCount] = useState(0);
    const [tasksDueToStartCount, setTasksDueToStartCount] = useState(0);
    const [tasksDueToFinishCount, setTasksDueToFinishCount] = useState(0);
    const [tasksStartedCount, setTasksStartedCount] = useState(0);
    const [tasksFinishedCount, setTasksFinishedCount] = useState(0);
    const [filtersLoaded, setFiltersLoaded] = useState(false);
    const [usingTwoSnapshots, setUsingTwoSnapshots] = useState<any>(null);
    const taskSnapshot = useTaskSnapshotSelector();
    const nextSnapshot = useNextSnapshotSelector();
    const projectCalendars = useActiveProjectCalendarsSelector();
    const cpmMap = useCpmMapSelector();
    const activeDataVersion = useActiveDataVersionSelector();

    useEffect(() => {
        if (taskSnapshot.size === 0) {
            subsManager.subscribeTaskSnapshot(activeDataVersion.dataVersionId || "", activeProject?.contractId || "");
            if (usingTwoSnapshots !== null) {
                console.log('other data version', dataVersions[usingTwoSnapshots].dataVersionId)
                subsManager.subscribeNextSnapshot(dataVersions[usingTwoSnapshots].dataVersionId, activeProject?.contractId || "");
            }
        }
    }, [taskSnapshot]);

    useEffect(() => {
        if (taskSnapshot.size > 0 && (usingTwoSnapshots === null || nextSnapshot.size > 0)) {
            const periodStart = new Date((Math.floor(activeDataVersion.dataVersionDate.seconds / 86400) * 86400) * 1000);
            const cutOffDate = new Date(periodStart.getTime() + (7 * 86400000));
            let archivedTaskList: TaskModel[] = [];
            let declaredTaskList: TaskModel[] = [];
            let inProgressTaskList: TaskModel[] = [];
            let archivedTaskCount = 0;
            let declaredTaskCount = 0;
            let inProgressTaskCount = 0;
            let tasksStartCount = 0;
            let tasksFinishCount = 0;
            let startedTasksCount = 0;
            let finishedTasksCount = 0;
            let totalStartedTasks = 0;
            let totalFinishedTasks = 0;
            let totalFinishedEarly = 0;
            filterTaskList(allTasks, searchParams, cpmMap, projectCalendars)
                .filter((task: TaskModel) => {
                        const taskSnapshotData = taskSnapshot.get(task.task_code);
                        if (!taskSnapshotData) return false;
                        if (taskSnapshotData.forecastStartDate.toDate().getTime() >= cutOffDate.getTime()
                            && !task.act_end_date && !task.declaredCompleteTimestamp) return false;
                        if (taskSnapshotData.actStartDate && !taskSnapshotData.flow) return false;
                        if (!includeMilestones && task.task_type !== TaskType.TT_RSRC && task.task_type !== TaskType.TT_TASK) return false;
                        return !taskSnapshotData.actEndDate;
                    }
                )
                .forEach((task: TaskModel) => {
                    const taskSnapshotData = taskSnapshot.get(task.task_code);
                    const nextSnapshotData = nextSnapshot.get(task.task_code);
                    if (taskSnapshotData && (!usingTwoSnapshots || nextSnapshotData)) {
                        const taskActualEndDate = usingTwoSnapshots !== null ? nextSnapshotData!.actEndDate || task.act_end_date || task.declaredCompleteTimestamp : task.act_end_date || task.declaredCompleteTimestamp;
                        const taskActualStartDate = usingTwoSnapshots !== null ? nextSnapshotData!.actStartDate : task.act_start_date;
                        if (task.status === TaskStatusModel.COMPLETE) {
                            archivedTaskList.push(task);
                            archivedTaskCount++;
                        } else if (task.status === TaskStatusModel.DECLARED_COMPLETE) {
                            declaredTaskList.push(task);
                            declaredTaskCount++;
                        } else {
                            inProgressTaskList.push(task);
                            inProgressTaskCount++;
                        }
                        if (taskSnapshotData.forecastStartDate.toDate().getTime() > periodStart.getTime()) {
                            tasksStartCount++;
                        }
                        if (taskActualStartDate && !taskSnapshotData.actStartDate) {
                            startedTasksCount++;
                        }
                        if (taskSnapshotData.forecastEndDate.toDate().getTime() < cutOffDate.getTime()) {
                            tasksFinishCount++;
                            if (taskActualEndDate) {
                                finishedTasksCount++;
                            }
                        } else if (taskActualEndDate) {
                            finishedTasksCount++;
                        }
                    }
                });
            if (sortCriteria === "index") {
                inProgressTaskList.sort((a, b) => {
                    const aTask = cpmMap.get(a.task_id);
                    const bTask = cpmMap.get(b.task_id);
                    if (!aTask || !bTask) return 0;
                    return aTask.index! - bTask.index!
                });
            } else if (sortCriteria === "deadline") {
                inProgressTaskList.sort((a, b) => {
                    const aTask = cpmMap.get(a.task_id);
                    const bTask = cpmMap.get(b.task_id);
                    const aLateEndDate = convertIndexToSeconds(aTask.lf, aTask.cal_id, projectCalendars);
                    const bLateEndDate = convertIndexToSeconds(bTask.lf, bTask.cal_id, projectCalendars);
                    return aLateEndDate - bLateEndDate;
                });
            } else if (sortCriteria === "target") {
                inProgressTaskList = inProgressTaskList.sort((a, b) => {
                    if (a.targetFinishDate) {
                        if (b.targetFinishDate) {
                            return a.targetFinishDate.seconds - b.targetFinishDate.seconds;
                        }
                        return -1;
                    }
                    if (b.targetFinishDate) {
                        return 1;
                    }
                    const aTask = cpmMap.get(a.task_id);
                    const bTask = cpmMap.get(b.task_id);
                    const aEarly = convertIndexToSeconds(aTask.ef, aTask.cal_id, projectCalendars);
                    const bEarly = convertIndexToSeconds(bTask.ef, bTask.cal_id, projectCalendars);
                    return aEarly - bEarly;
                });
            }

            setFilteredArchivedTaskList(archivedTaskList);
            setFilteredDeclaredTaskList(declaredTaskList);
            setFilteredInProgressTaskList(inProgressTaskList);
            setArchivedTaskCount(archivedTaskCount);
            setDeclaredTaskCount(declaredTaskCount);
            setInProgressTaskCount(inProgressTaskCount);
            setTasksDueToStartCount(tasksStartCount);
            setTasksDueToFinishCount(tasksFinishCount);
            setTasksStartedCount(startedTasksCount);
            setTasksFinishedCount(finishedTasksCount);
            setFiltersLoaded(true);
        }
    }, [searchParams, sortCriteria, taskSnapshot, nextSnapshot, allTasks]);

    const handleChangedSnapshot = (version: any, index) => {
        if (version.dataVersionId === activeDataVersion?.dataVersionId) return;
        setFiltersLoaded(false);
        // setActiveDataVersion(version);
        dispatch(taskActions.Actions.setNextSnapshot(new Map()));
        dispatch(taskActions.Actions.setTaskSnapshot(new Map()));
        // subsManager.subscribeTaskSnapshot(version.dataVersionId, activeProject?.contractId || "");
        if (index !== 0) {
            // subsManager.subscribeNextSnapshot(dataVersions[index - 1].dataVersionId, activeProject?.contractId || "");
            setUsingTwoSnapshots(index - 1);
        } else {
            setUsingTwoSnapshots(null);
        }
        // setActiveDataVersion(version)
        dispatch(projectActions.Actions.setActiveDataVersion(version));
    }

    const openPredecessors = useTypedSelector(
        (state) => state.task.predecessors.open
    );
    const openSuccessors = useTypedSelector(
        (state) => state.task.successors.open
    );
    const loadMoreHandler = useCallback(
        (page: number) => {
            if (view === "future") {
                if (!loading.QueuedIsLoading && moreTasks.queued) {
                    dispatch(
                        taskActions.Actions.loadMoreTaskList(
                            page,
                            TaskListSectionModel.QUEUED
                        )
                    );
                }
            } else {
                if (
                    !loading.ConfirmedCompleteIsLoading &&
                    moreTasks.confirmedComplete
                ) {
                    dispatch(
                        taskActions.Actions.loadMoreTaskList(
                            page,
                            TaskListSectionModel.CONFIRMED_COMPLETE
                        )
                    );
                }
            }
        },
        [
            dispatch,
            loading.ConfirmedCompleteIsLoading,
            loading.QueuedIsLoading,
            moreTasks.confirmedComplete,
            moreTasks.queued,
            view,
        ]
    );

    if (openPredecessors) {
        return <TaskPredecessors />;
    }

    if (openSuccessors) {
        return <TaskSuccessors />;
    }

    return (
        <div style={{minWidth: "100%"}}>
            <div className="sprint-summary-container">
                <div className="sprint-titles"><div>Sprint: </div>{dataVersions.length > 0 ?
                    <div style={{zIndex: 350}} className="sprint-dropdown-div">
                        <Dropdown
                            selection
                            fluid
                            scrolling
                            className={"sprint-date-dropdown"}
                            // icon={null}
                              text={activeDataVersion ?
                                moment(activeDataVersion?.dataVersionDate.toDate()).format("MMM Do, YYYY") :
                                  moment(activeProject?.lastSnapshotTimestamp?.toDate()).format("MMM Do, YYYY")}
                            options={dataVersions.map((version, i) => ({
                                key: version.dataVersionId,
                                value: version.dataVersionId,
                                text: 'P'+(dataVersions.length - i).toString() + ' | ' + moment(version.dataVersionDate.toDate()).format("MMM Do, YYYY"),
                                onClick: () => handleChangedSnapshot(version, i)
                                })
                            )}
                              />
                        {/*    <Dropdown.Menu>*/}
                        {/*        {dataVersions.map((version, i) => (*/}
                        {/*            <Dropdown.Item*/}
                        {/*                key={version.dataVersionId}*/}
                        {/*                onClick={() => {*/}
                        {/*                    handleChangedSnapshot(version, i);*/}
                        {/*                }}*/}
                        {/*                style={{fontSize: "12px"}}*/}
                        {/*                text={'P'+(dataVersions.length - i).toString() + ' | ' + moment(version.dataVersionDate.toDate()).format("MMM Do, YYYY")}*/}
                        {/*            >*/}
                        {/*                /!*{moment(version.dataVersionDate.toDate()).format("MMM Do, YYYY")}*!/*/}
                        {/*            </Dropdown.Item>*/}
                        {/*        ))}*/}
                        {/*    </Dropdown.Menu>*/}
                        {/*</Dropdown>*/}
                    </div> :
                moment(activeProject?.lastSnapshotTimestamp?.toDate()).format("MMM Do, YYYY")}
                </div>
                <div style={{fontSize: "30px", marginTop: "-5px"}}>{' | '}</div>
                <FontAwesomeIcon icon={faPersonRunningFast} size={"2xl"} className="sprint-summary-icon"/>
                <span className="sprint-summary-figures">
                    <div className="sprint-summary-total">
                        Started:
                        <div className="sprint-total-div">
                            {tasksStartedCount > 0 &&
                            <div className="sprint-progress-bar"
                                 style={{minWidth: `${tasksStartedCount / tasksDueToStartCount * 100}%`}}>
                                {tasksStartedCount / tasksDueToStartCount >= 0.5 ? <p>
                                    {`${tasksStartedCount} / ${tasksDueToStartCount}`}</p> : null}
                            </div>}
                            {tasksDueToStartCount > 0 ? tasksStartedCount / tasksDueToStartCount < 0.5 ? <p>
                                {`${tasksStartedCount} / ${tasksDueToStartCount}`}</p> : null : <p>0 / 0</p>}
                        </div>
                    </div>
                    <div className="sprint-summary-total"
                         style={{
                             marginLeft: "-20px",
                             paddingRight: "15px"
                         }}
                    >
                        Completed:
                        <div className="sprint-total-div">
                            {tasksFinishedCount > 0 &&
                                <div className="sprint-progress-bar"
                                     style={{minWidth: `${tasksFinishedCount / tasksDueToFinishCount * 100}%`}}>
                                    {tasksFinishedCount / tasksDueToFinishCount >= 0.5 ? <p>
                                        {`${tasksFinishedCount} / ${tasksDueToFinishCount}`}</p> : null}
                                </div>}
                            {tasksDueToFinishCount > 0 ? tasksFinishedCount / tasksDueToFinishCount < 0.5 ? <p>
                                {`${tasksFinishedCount} / ${tasksDueToFinishCount}`}</p> : null : <p>0 / 0</p>}
                        </div>
                    </div>
                </span>
            </div>
            <div className="tasks-sort-header"
                 style={{
                     backgroundColor: "rgb(255 255 255 / 80%)",
                     zIndex: "200"
                 }}>
                <div className="priority-task">
                    <div
                        className={"priority " + (sortCriteria === "index" ? "active" : "")}
                        onClick={() => setSortCriteria("index")}
                    >
                        <span className="priority-text">Priority</span>&nbsp;
                    </div>
                    <div className="task-desc">Deliverable</div>
                </div>
                <div className="right-filters">
                    <div
                        className={"time " + (sortCriteria === "target" ? "active" : "")}
                        onClick={() => setSortCriteria("target")}
                    >
                        <div className="time-text">Target</div>
                        &nbsp;
                    </div>
                    <div className={"critical " + (sortCriteria === "deadline" ? "active" : "")}
                         onClick={() => setSortCriteria("deadline")}
                    >Deadline
                    </div>
                </div>
            </div>
            {filtersLoaded ?
            <div className="lazy-load-container sprint">
                {projectView === "task-map" && <GoogleMapVisGL/>}
                <InfiniteScroll
                    pageStart={1}
                    loadMore={loadMoreHandler}
                    initialLoad={false}
                    hasMore={
                        view === "future"
                            ? !loading.QueuedIsLoading && moreTasks.queued
                            : !loading.ConfirmedCompleteIsLoading && moreTasks.confirmedComplete
                    }
                    useWindow={false}
                >
                    <div className="lazy-load-content" style={{zIndex: "100"}}>
                        {filteredArchivedTaskList.length > 0 ? (
                            <TaskListSection
                                text="Archive"
                                moreTasks={moreTasks.confirmedComplete}
                                isLoading={false}
                                headline={"Archive"}
                                title={
                                    "Archive"
                                }
                                taskList={filteredArchivedTaskList}
                                view={view}
                                setView={setView}
                                total={archivedTaskCount + declaredTaskCount + inProgressTaskCount}
                                quality={false}
                                type={"task-sprint"}
                                open={archivedOpen}
                                setOpen={setArchivedOpen}
                                taskSnapshot={taskSnapshot}
                            />
                        ) : null}
                        {filteredDeclaredTaskList.length > 0 ? (
                            <TaskListSection
                                text="Ready for Sign-off"
                                moreTasks={moreTasks.confirmedComplete}
                                isLoading={false}
                                headline={"Ready for Sign-off"}
                                title={"Ready for Sign-off"}
                                taskList={filteredDeclaredTaskList}
                                view={view}
                                setView={setView}
                                total={archivedTaskCount + declaredTaskCount + inProgressTaskCount}
                                quality={false}
                                type={"task-sprint"}
                                open={declaredOpen}
                                setOpen={setDeclaredOpen}
                                taskSnapshot={taskSnapshot}
                            />
                        ) : null}
                        {filteredInProgressTaskList.length > 0 ? (
                            <TaskListSection
                                text="Work in progress"
                                moreTasks={moreTasks.confirmedComplete}
                                isLoading={false}
                                headline={"Work in progress"}
                                title={"Work in progress"}
                                taskList={filteredInProgressTaskList}
                                view={view}
                                setView={setView}
                                total={archivedTaskCount + declaredTaskCount + inProgressTaskCount}
                                quality={false}
                                type={"task-sprint"}
                                open={inProgressOpen}
                                setOpen={setInProgressOpen}
                                taskSnapshot={taskSnapshot}
                            />
                        ) : null}
                        {(view === "future"
                            ? loading.QueuedIsLoading
                            : loading.ConfirmedCompleteIsLoading) && (
                            <div className="load-more-items-wrap" key={0}>
                                <Loader active/>
                            </div>
                        )}
                    </div>
                </InfiniteScroll>
            </div> : <Loader active content={"Loading sprint..."} size={"large"}/>
            }
        </div>
    );
};


