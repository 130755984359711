export default function getWorkDays(calData) {
    function generatePeriods(times, order) {
        if (!times) return null
        let periods: any[] = []
        if (order === 'f') {
            let i = 0
            while (i < times.length) {
                periods.push({
                    "start": times[times.length - 1 - i],
                    "finish": times[times.length - 2 - i],
                    "periodRank": i / 2
                })
                i += 2
            }
        } else {
            let i = 0
            while (i < times.length) {
                periods.push({
                    "start": times[i],
                    "finish": times[i + 1],
                    "periodRank": i / 2
                })
                i += 2
            }
        }
        return periods
    }

    // Get days of the week
    let re1 = new RegExp('(?<=\\(\\d+\\|\\|(\\w*DaysOfWeek\\w*)\\(\\)\\((\\x7F\\x7F\\s{4})?)(.*?)(?=\\(\\d+\\|\\|[A-Za-z]|\\){6})', 'g')
    let daysOfWeekStr = calData.match(re1)
    // let daysOfWeekArray = daysOfWeekStr[0].split(/\(?\x7F\x7F\s{4}\(/)
    let daysOfWeekArray = daysOfWeekStr[0].split(/\d\(\)/)
    let re2 = new RegExp('(?<=\\(\\d+\\|\\|\\d+\\(s\\|)([\\s\\S]*?)(?=\\|f)|(?<=\\|f\\|)([\\s\\S]*?)(?=\\))', 'g')
    let re3 = new RegExp('(?<=\\(\\d+\\|\\|\\d+\\(f\\|)([\\s\\S]*?)(?=\\|s)|(?<=\\|s\\|)([\\s\\S]*?)(?=\\))', 'g')
    let reGeneric = new RegExp('(?<=\\(\\d+\\|\\|\\d+\\((s|s)\\|)([\\s\\S]*?)(?=\\|(f|s))|(?<=\\|(f|s)\\|)([\\s\\S]*?)(?=\\))', 'g')
    let daysOfWeekTimes = daysOfWeekArray.map(day =>
        !day.match(re2) ? generatePeriods(day.match(re3), "f") : generatePeriods(day.match(re2), 's')
    )
    let workDayList = {
        "sunday": daysOfWeekTimes[1],
        "monday": daysOfWeekTimes[2],
        "tuesday": daysOfWeekTimes[3],
        "wednesday": daysOfWeekTimes[4],
        "thursday": daysOfWeekTimes[5],
        "friday": daysOfWeekTimes[6],
        "saturday": daysOfWeekTimes[7]
    }

    // Get Exceptions
    let re4 = new RegExp('(?<=\\(\\d+\\|\\|Exceptio.?.?\\(\\)\\((\\x7F)?)([\\s\\S]*?)(?=\\)\\)\\)\\)\\))', 'g')
    let exceptionsStr = calData.match(re4)
    // let exceptionsList = exceptionsStr ? exceptionsStr[0].split(/\x7F\x7F\s{4}\(|\(\d+\|\|\d+/) : null
    let exceptionsList = exceptionsStr ? exceptionsStr[0].replace(/[\s,\x7F]/g, "")
        .split(/\(\d+\|\|\d+\([^sf]/)
        .filter(line => line !== ""): null
    let exceptionsDictList = exceptionsStr ? exceptionsList.map(exception =>
        !exception.match(reGeneric) ?
        {
            'periods': [{start: null, end: null, periodRank: 0}],
            'date': parseInt(exception.match(/(?<=\|)(\d+?)(?=\)\()/)[0])
        } :
        exception.match(re2) ? {
            'periods': generatePeriods(exception.match(re2), 's'),
            'date': parseInt(exception.match(/(?<=\|)(\d+?)(?=\)\()/)[0])
        } : {
            'periods': generatePeriods(exception.match(re3), 'f'),
            'date': parseInt(exception.match(/(?<=\|)(\d+?)(?=\)\()/)[0])
        }) : null
    return {"workDayList": workDayList, "exceptionsList": exceptionsDictList}
}
