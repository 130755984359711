import React, {useEffect} from "react";
import { useTypedSelector } from "../../../../store/selectors/selectors.utils";
import moment from 'moment';
import { Line } from "react-chartjs-2";
import rocketIcon from '../.././../../images/rocketIcon.svg';
import store from "../../../../store/store";
import generateCurves from "../../../../utils/generate-curves";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    LineController,
    BarController,
} from 'chart.js';
import {useSearchParamatersSelector} from "../../../../store/selectors/search.selectors";
import filterTaskList from "../../../../utils/task-filtering";
import {useActiveProjectCalendarsSelector, useCpmMapSelector} from "../../../../store/selectors/project.selectors";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
    PointElement,
    LineElement,
    LineController,
    BarController,
);

const generateChartLine = (data: any[]) => {
    return {
        datasets: data.map(el => ({
            fill: false,
            spanGaps: true,
            lineTension: 0.1,
            // backgroundColor: 'rgba(75,192,192,0.4)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            // pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            // pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            tension: 0.4,
            ...el
        })
        )

    };
};


export function FlowChart () {
    const [earlyCurve, setEarlyCurve]: any[] = React.useState([]);
    const [lateCurve, setLateCurve]: any[] = React.useState([]);
    // const [rocketLine, setRocketLine]: any[] = React.useState([]);
    const [racingLine, setRacingLine]: any[] = React.useState([]);
    const searchParams = useSearchParamatersSelector();
    const cpmMap = useCpmMapSelector();
    const calendarsMap = useActiveProjectCalendarsSelector();

    // if (!props.expended) return null;
    console.log(store.getState())

    const activeProject = useTypedSelector(state => state.project.activeProject);
    const customViewStart = activeProject?.customViewStart;
    const customViewFinish = activeProject?.customViewFinish;

    function generateCurveData (curveArray: any[]) {
        return curveArray.map((el, i) => {
                const date = moment(new Date(el.date.seconds * 1000)).startOf('day').toString()
                const startOfMonth = moment(new Date(el.date.seconds * 1000)).startOf('month').toString();
                if (date === startOfMonth) {
                    return {x: moment(new Date(el.date.seconds * 1000)).format('MMM-YY'), y: el.cumTasks}
                } else
                if (i === 0) {
                    return {x: moment(new Date(el.date.seconds * 1000)).format('MMM-YY'), y: el.cumTasks}
                } else
                if (i === curveArray.length - 1) {
                    return {x: moment(new Date(el.date.seconds * 1000)).add(1, 'month').format('MMM-YY'), y: el.cumTasks}
                }
                return null
            }
        ).filter(el => el !== null);
    }


    useEffect(() => {
        if (activeProject) {
            const taskList = store.getState().task.tasks
            const filteredTaskList = filterTaskList(taskList, searchParams, cpmMap, calendarsMap);
            generateCurves(filteredTaskList, activeProject.projectId).then(res => {
                let resOut = res;
                if (customViewStart) {
                    if (customViewFinish) {
                        resOut = {
                            early: res.early.filter(el => el.date.seconds >= customViewStart.seconds && el.date.seconds <= customViewFinish.seconds),
                            late: res.late.filter(el => el.date.seconds >= customViewStart.seconds && el.date.seconds <= customViewFinish.seconds),
                            expected: res.expected.filter(el => el.date.seconds >= customViewStart.seconds && el.date.seconds <= customViewFinish.seconds),
                        }
                    } else {
                        resOut = {
                            early: res.early.filter(el => el.date.seconds >= customViewStart.seconds),
                            late: res.late.filter(el => el.date.seconds >= customViewStart.seconds),
                            expected: res.expected.filter(el => el.date.seconds >= customViewStart.seconds),
                        }
                    }
                } else if (customViewFinish) {
                    resOut = {
                        early: res.early.filter(el => el.date.seconds <= customViewFinish.seconds),
                        late: res.late.filter(el => el.date.seconds <= customViewFinish.seconds),
                        expected: res.expected.filter(el => el.date.seconds <= customViewFinish.seconds),
                    }
                }
                const earliestDate = customViewStart || res.early[0].date.seconds;``
                const latestDate = customViewFinish || res.late[res.late.length - 1].date.seconds;
                const numberOfWeeks = Math.ceil((latestDate - earliestDate) / 604800);
                if (numberOfWeeks > 100) {
                    setEarlyCurve(generateCurveData(resOut.early))
                    setLateCurve(generateCurveData(resOut.late))
                    setRacingLine(generateCurveData(resOut.expected))
                } else {
                    setEarlyCurve(resOut.early.map(el => ({x: moment(new Date(el.date.seconds * 1000)).format('DD-MMM-YY'), y: el.cumTasks})));
                    setLateCurve(resOut.late.map(el => ({x: moment(new Date(el.date.seconds * 1000)).format('DD-MMM-YY'), y: el.cumTasks})));
                    setRacingLine(resOut.expected.map(el => ({x: moment(new Date(el.date.seconds * 1000)).format('DD-MMM-YY'), y: el.cumTasks})));
                }
            })
        }
    }, [activeProject, searchParams]);

    // const rocketImage = useMemo(() => {
    //     const outImage = new Image(25, 25);
    //         outImage.src = rocketIcon;
    //         outImage.className = 'rocket-icon-chart';
    //         return outImage;
    // }, []);

    // rocketImage.src = `${faRocket.icon[4]}`;
    // const rocketIcon = new Icon(faRocket.icon);

    const data = activeProject ? generateChartLine(
                [
                    // {
                    //     data: rocketLine.map(el => el ? parseFloat(el.toFixed(2)) : el),
                    //     borderColor: '#5D63FF',
                    //     label: `Progress Date - ${moment().format('DD MMM YYYY')}`,
                    //     pointStyle: rocketImage,
                    //     pointHitRadius: 40,
                    // },
                    {
                        data: earlyCurve,
                        borderColor: '#5eff34',
                        label: 'Early Curve',
                        pointStyle: 'line',
                    },
                    {
                        data: lateCurve,
                        borderColor: '#ff4455',
                        label: 'Late Curve',
                        pointStyle: 'line',
                    },
                    {
                        data: racingLine,
                        borderColor: '#5D63FF',
                        borderDash: [4, 6],
                        label: 'Racing Line',
                        pointStyle: 'line',
                    },
                ]
            ) :
        {}

    const options = {
        scales: {
            y: {
                gridLines: {
                    tickMarkLength: 0,
                },
                display: true,
                ticks: {
                    suggestedMin: 0,
                    tickMarkLength: 0,
                    suggestedMax: 100,
                    beginAtZero: true,   // minimum value will be 0.
                    mirror: true,
                    padding: 0,
                }
            },
            x: {
                // ticks: {
                //     labels: {
                //         callback: function(value, index, values) {
                //             console.log(value, index, values, "values")
                //             return index % 2 === 0 ? value : null;
                //         }
                //     }
                // }
                ticks: {
                    callback: function(value, index, ticks) {
                        return index !== 0 ? earlyCurve.length > 0 ? earlyCurve[index].x : null : null;
                    }
                }
                // labels: {
                //     filter: function (value, index, values) {
                //         return index !== 0;
                //     }
                // }
            }},
        legend: {
            labels: {
                usePointStyle: true,
                filter: function (legendItem) {
                    return legendItem.datasetIndex !== 0;
                }
            }
        }
        // elements: {
        //     point: {
        //         pointStyle: 'circle',
        // }}
    }

    return (
        <div className="ContainerExpended">
            <div className={"Content"}>
                {/*
                // @ts-ignore */}
                <Line height={90} data={data} options={options} />
            </div>
        </div>
    )
}
