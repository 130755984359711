import {Button, Form, FormField, FormGroup, Modal, Table} from "semantic-ui-react";
import { TabPane, Tab } from 'semantic-ui-react'
import React, { useState} from "react";
import styles from './MonteCarloModal.module.scss'
import {ProjectThunk} from "../../../../store/thunk/project.thunk";
import {useDispatch} from "react-redux";
import {
    useActiveProjectCalendarsSelector,
    useCpmMapSelector,
    useMonteCarloIndexSelector,
    useMonteCarloResultsSelector,
    useTrackedMilestonesSelector
} from "../../../../store/selectors/project.selectors";
import * as projectActions from "../../../../store/actions/project.actions";
import TrackedMilestoneModel from "../../../../models/responses/tracked-milestone.model";
import moment from "moment";
import {convertIndexToSeconds} from "../../../../utils/cpm-functions/cpm-app/functions/handleEvent";
import BoxChart from "./BoxChart";


export default function MonteCarloModal(props) {
    const [iterations, setIterations] = useState(1000)
    const [upperLimit, setUpperLimit] = useState(2)
    const [lowerLimit, setLowerLimit] = useState(0.8)
    const [simulationModel, setSimulationModel] = useState('Monte Carlo')
    const [simulationRunning, setSimulationRunning] = useState(false)
    const dispatch = useDispatch()
    const monteCarloIndex = useMonteCarloIndexSelector()
    const results = useMonteCarloResultsSelector()
    const [showResults, setShowResults] = useState(false)
    const trackedMilestones = useTrackedMilestonesSelector()

    const runSimulation = () => {
        ProjectThunk.runMonteCarlo(props.projectId, dispatch, props.trackedMilestones, upperLimit, lowerLimit, iterations)
            .catch(err => console.log(err))
    }

    const resultsTable = () => {
        let output: any = []
        const { percentiles, averages } = results
        trackedMilestones.forEach((milestone: TrackedMilestoneModel) => {
            output.push(
                <Table.Row key={milestone.taskId}>
                    <Table.Cell>{milestone.milestoneName}</Table.Cell>
                    <Table.Cell textAlign={"center"}>{
                        moment(milestone.baselineDate ? milestone.baselineDate.toDate()
                            : milestone.constraintDate ? milestone.constraintDate.toDate() : new Date()
                        ).format('DD MMM YYYY')}
                    </Table.Cell>
                    <Table.Cell textAlign={"center"}>{
                        percentiles[milestone.taskId].deterministic ? moment(new Date(percentiles[milestone.taskId].deterministic * 1000)).format('DD MMM YYYY') : 'N/A'
                    }</Table.Cell>
                    <Table.Cell textAlign={"center"}>{moment(new Date(averages[milestone.taskId] * 1000)).format('DD MMM YYYY')}</Table.Cell>
                    <Table.Cell textAlign={"center"}>{moment(new Date(percentiles[milestone.taskId].p20 * 1000)).format('DD MMM YYYY')}</Table.Cell>
                    <Table.Cell textAlign={"center"}>{moment(new Date(percentiles[milestone.taskId].p50 * 1000)).format('DD MMM YYYY')}</Table.Cell>
                    <Table.Cell textAlign={"center"}>{moment(new Date(percentiles[milestone.taskId].p80 * 1000)).format('DD MMM YYYY')}</Table.Cell>
                    <Table.Cell textAlign={"center"}>{moment(new Date(percentiles[milestone.taskId].max * 1000)).format('DD MMM YYYY')}</Table.Cell>
                    <Table.Cell textAlign={"center"}>{moment(new Date(percentiles[milestone.taskId].min * 1000)).format('DD MMM YYYY')}</Table.Cell>
                </Table.Row>
            )
        })

        const tableTabContent = (
            <div style={{maxHeight: "80vh", overflow: "auto"}}>
                <Table style={{fontSize: "12px", border: "none"}} selectable>
                    <Table.Header>
                        <Table.Row style={{position: "sticky", top: 0}}>
                            <Table.HeaderCell>Milestone</Table.HeaderCell>
                                <Table.HeaderCell textAlign={"center"}>Baseline</Table.HeaderCell>
                                <Table.HeaderCell textAlign={"center"}>Deterministic</Table.HeaderCell>
                                <Table.HeaderCell textAlign={"center"}>Mean</Table.HeaderCell>
                                <Table.HeaderCell textAlign={"center"}>P20</Table.HeaderCell>
                                <Table.HeaderCell textAlign={"center"}>P50</Table.HeaderCell>
                                <Table.HeaderCell textAlign={"center"}>P80</Table.HeaderCell>
                                <Table.HeaderCell textAlign={"center"}>Max</Table.HeaderCell>
                                <Table.HeaderCell textAlign={"center"}>Min</Table.HeaderCell>
                            </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {output}
                    </Table.Body>
                </Table>
            </div>
        )

        const panes = [
            {
                menuItem: 'Table',
                render: () => <TabPane style={{margin: 0, padding: 5}} attached={false}>{tableTabContent}</TabPane>,
            },
            {
                menuItem: 'Chart',
                render: () => <TabPane attached={false}
                    style={{margin: 0, padding: 2}}
                >
                    <BoxChart
                        height={window.innerHeight * 0.6}
                        width={window.innerWidth * 0.91}
                        results={results}
                    />
                </TabPane>,
            },
        ]

        const TabExampleBorderless = () => (
            <Tab
                menu={{ borderless: true, attached: false, tabular: false }}
                panes={panes}
            />
        )
    return TabExampleBorderless()
    }

    return (
        <Modal size={showResults ? "fullscreen" : "small"} closeIcon open={props.showModal} onClose={() => {
            props.setShowModal(false)
            dispatch(projectActions.Actions.setMonteCarloIndex(0))
        }}>
            <Modal.Header>
                Monte Carlo Simulation
            </Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    {simulationRunning ? showResults && results ?
                    <div>
                        <h4>Simulation Results</h4>
                        {resultsTable()}
                    </div> :
                    <div className={styles.Loading}>
                        <p>Running Simulation</p>
                        <p>{`${monteCarloIndex} of ${iterations}`}</p>
                        <progress
                            style={{width: "100%", height: "20px"}}
                            max="100" value={Math.floor((monteCarloIndex / iterations) * 100).toString()}/>
                    </div> :
                    <Form>
                        <div className={styles.SimulationModelForm}>
                            <label style={{padding: "5px"}}>Simulation Model</label>
                            <FormGroup inline className={styles.ThisFormField}>
                                <FormField>
                                    <label>Monte Carlo</label>
                                    <input
                                        type="radio"
                                        id={'Monte Carlo'}
                                        checked={simulationModel === 'Monte Carlo'}
                                        onChange={(e) => setSimulationModel(e.target.id)}
                                    />
                                </FormField>
                                <FormField>
                                    <label>Machine Learning</label>
                                    <input
                                        disabled
                                        type="radio"
                                        id={'Machine Learning'}
                                        checked={simulationModel === 'Machine Learning'}
                                        onChange={(e) => setSimulationModel(e.target.id)}
                                    />
                                </FormField>
                            </FormGroup>
                        </div>
                        <div className={styles.IterationsForm}>
                            <label style={{padding: "5px"}}>Simulation Settings</label>
                            <Form.Group style={{marginTop: "5px", paddingLeft: "10px"}}>
                                <Form.Field>
                                    <label>Number of Iterations</label>
                                    <input
                                        style={{width: "120px"}}
                                        type="number"
                                        min={10}
                                        max={10000}
                                        value={iterations}
                                        onChange={(e) => setIterations(parseInt(e.target.value))}
                                    />
                                </Form.Field>
                            </Form.Group>
                        </div>
                        <div className={styles.UncertaintyForm}>
                            <label>Duration Uncertainty Parameters</label>
                            <Form.Group>
                                <Form.Field>
                                    <label>Generic Upper Limit</label>
                                    <input
                                        type="number"
                                        value={upperLimit}
                                        min={1}
                                        step={0.05}
                                        onChange={(e) => setUpperLimit(parseFloat(e.target.value))}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Generic Lower Limit</label>
                                    <input
                                        type="number"
                                        value={lowerLimit}
                                        min={0}
                                        max={1}
                                        step={0.05}
                                        onChange={(e) => setLowerLimit(parseFloat(e.target.value))}
                                    />
                                </Form.Field>
                            </Form.Group>
                        </div>
                    </Form>
                }
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => props.setShowModal(false)}>Close</Button>
                {results && !showResults && <Button onClick={() => {
                    setShowResults(true)
                    setSimulationRunning(true)
                }}>
                    Show Previous Results
                </Button>}
                <Button disabled={simulationRunning && iterations !== monteCarloIndex} positive onClick={() => {
                    if (simulationRunning && iterations === monteCarloIndex) {
                        setShowResults(true)
                        return
                    }
                    setSimulationRunning(true)
                    runSimulation()
                }}>{simulationRunning && iterations === monteCarloIndex ? 'Show Results' : 'Run Simulation'}</Button>
            </Modal.Actions>
        </Modal>
    )
}