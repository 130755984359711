import {Button, Dropdown, Modal} from "semantic-ui-react";
import {addNewTrackedMilestone} from "../../../../../utils/addNewTrackedMilestone";
import React from "react";

export default function TaskPickerModal (props: any) {
    const {
        showTaskPicker,
        setShowTaskPicker,
        incompleteTasks,
        activeProject,
        dispatch,
        setTrackedMilestone,
        setTrackedMilestoneVisibility,
        trackedMilestone,
        trackedMilestoneVisibility
    } = props.props

    return (
        <Modal open={showTaskPicker} onClose={() => setShowTaskPicker(false)}>
            <Modal.Header>Select a Task or Milestone to watch</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    Search the activity ID of the task or milestone you want to watch
                </Modal.Description>
                <Dropdown
                    clearable
                    fluid
                    multiple
                    search
                    selection
                    options={incompleteTasks
                        .map((el) => ({ key: el.key, text: el.text, value: el.value }))}
                    placeholder="Select Activity"
                    onChange={(e, data) => {
                        e.preventDefault()
                        setTrackedMilestone(data.value)
                    }}
                />
                &nbsp;
                <Dropdown
                    clearable
                    fluid
                    search
                    selection
                    options={[{key: "everyone", text: "Everyone", value: "everyone"},
                        {key: "me", text: "Just me", value: "me"}]}
                    placeholder="Visibile to..."
                    onChange={(e, data) => {
                        e.preventDefault()
                        setTrackedMilestoneVisibility(data.value)
                    }}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => {
                    setShowTaskPicker(false)
                    setTrackedMilestone(null)
                }}>Cancel</Button>
                <Button onClick={() => {
                    addNewTrackedMilestone(trackedMilestone,
                        trackedMilestoneVisibility === "everyone",
                        activeProject!.projectId,
                        dispatch).catch((e) => console.log(e))
                    setShowTaskPicker(false)
                    setTrackedMilestone(null)
                    setTrackedMilestoneVisibility("everyone")
                }} positive>
                    Add
                </Button>
            </Modal.Actions>
        </Modal>
    )
}