import {ActionsUnion, createAction} from "@martin_hotell/rex-tils";
import MessageModel from "../../models/responses/message.model";

export enum ActionNames {
    SET_LEDGER_LIST = '[Ledger] Set Ledger List',
    SET_RECENT_USER_ITEMS = '[Ledger] Set Recent User Items',
}


export const Actions = {
    setLedgerList: (ledgerList: MessageModel[]) => createAction(ActionNames.SET_LEDGER_LIST, {ledgerList}),
    setRecentUserItems: (recentUserItems: MessageModel[]) => createAction(ActionNames.SET_RECENT_USER_ITEMS, {recentUserItems}),
};

export type Actions = ActionsUnion<typeof Actions>;
