import React, {useEffect, useState} from 'react';
import TaskModel from "../../../../../models/responses/task.model";
import styles from '../GanttChart.module.scss';
import {TabPane, Tab, Input, Table, Dropdown} from 'semantic-ui-react'
import {TaskType} from "../../../../../models/task-type";
import {useTypedSelector} from "../../../../../store/selectors/selectors.utils";
import WbsTree from "../../WbsTree/WbsTree";

export default function TaskInfo(props: {task: TaskModel}) {
    const task = props.task;
    const wbsList = useTypedSelector((state) => state.wbs.wbsList)
    const [openWbsTree, setOpenWbsTree] = useState(false);
    const [wbsPlaceholder, setWbsPlaceholder] = useState(task.wbsPath === '' ? task.wbs : task.wbsPath + ' / ' + task.wbs);

    const taskTypeConverter = (taskType: TaskType) => {
        switch (taskType) {
            case TaskType.TT_TASK:
                return "Task Dependent";
            case TaskType.TT_MILE:
                return "Start Milestone";
            case TaskType.TT_FIN_MILE:
                return "Finish Milestone";
            case TaskType.TT_RSRC:
                return "Resource Dependent";
            case TaskType.TT_LOE:
                return "Level of Effort";
            default:
                return "Task Dependent";
        }
    }

    useEffect(() => {
        setWbsPlaceholder(task.wbsPath === '' ? task.wbs : task.wbsPath + ' / ' + task.wbs);
    }, [task]);

    const panes = [
        { menuItem: 'Details', render: () =>
                <TabPane>
                    <div>
                        <div className={styles.TaskDetailRow}>
                            <span className={styles.TaskDetailSmall}>
                                <label>Task Type:</label>
                                <Input
                                    type="text" value={taskTypeConverter(task.task_type)} readOnly/>
                            </span>
                            <span className={styles.TaskDetailSmall}>
                                <label>Task Code:</label>
                                <Input
                                    type="text" value={task.task_code}/>
                            </span>
                            <span className={styles.TaskDetail}>
                                <label>Task Name:</label>
                                <Input
                                    type="text" value={task.task_name}/>
                            </span>
                        </div>
                        <div className={styles.TaskDetailRow}>
                            <span className={styles.TaskDetail}>
                                <label>WBS:</label>
                                <Input
                                    type="text" value={wbsPlaceholder} readOnly
                                    onClick={() => setOpenWbsTree(true)}
                                />
                            </span>
                        </div>
                    </div>
                </TabPane>
        },

        {
            menuItem: 'Relationships', render: () =>
                <TabPane>
                    <div>
                        <div className={styles.TaskRelationshipsContainer}>
                            <span className={styles.TaskDetail}>
                                <label>Predecessors:</label>
                                <div className={styles.RelationshipListContainer}>
                                <table>
                                    <th>
                                        <tr>
                                            <td>Task Code</td>
                                            <td>Task Name</td>
                                            <td>Type</td>
                                            <td>Lag</td>
                                        </tr>
                                    </th>
                                    <tbody>
                                    </tbody>
                                </table>
                                </div>

                            </span>
                            <span className={styles.TaskDetail}>
                                <label>Successors:</label>
                            </span>
                        </div>
                    </div>
                </TabPane>
        },
        {menuItem: 'Tab 3', render: () => <TabPane>Tab 3 Content</TabPane>},
    ]

    return (
        <div className={styles.TaskInfoContainer}>
            <Tab panes={panes} />
            {openWbsTree && <WbsTree
                setOpenWbsTree={setOpenWbsTree}
                taskId={task.task_id}
                existingWbs={task.wbs_id}
                setWbsPlaceholder={setWbsPlaceholder}
            />}
        </div>
    )
}