// import generateCurves from "./racing-line-calc/functions/generateCurves";
import generateUploadData from "./set-cpm-map/functions/generateData";
import FirebaseUsage from "../../firebase/firebase.usage";
import cpmScheduleRefresh from "./cpm-app/functions/cpmScheduleRefresh";
import racingLine from "./racing-line-calc/functions/racingLineCalc2";
import createIndexedCalendarArrays from "./set-calendars-app/functions/createIndexedCalendars";
import ProjectModel from "../../models/responses/project.model";
import handleEvent, {convertIndexToSeconds} from "./cpm-app/functions/handleEvent";
import updateEarliestDate from "./set-calendars-app/functions/updateEarliestDate";
import * as projectActions from "../../store/actions/project.actions";
import store from "../../store/store";
import cpmScheduleSpecific from "./cpm-app/functions/cpmScheduleSpecific";
import cpmScheduleSimple from "./cpm-app/functions/simpleCPM";
import TrackedMilestoneModel from "../../models/responses/tracked-milestone.model";
import cpmMonteCarlo from "./monte-carlo/functions/monteCarloCPM";
import generateCpmTask from "./set-cpm-map/functions/generateCpmTask";
import calculateDCMA from "./quality-functions/calculateDCMA";
import {CpmTaskModel} from "../../models/responses/cpm-task.model";
import TaskModel from "../../models/responses/task.model";
// import FirebaseProjectFunctions from "../../firebase/firebase-functions/project-service";

class CpmFunctionsController {

    static async setCalendars(req, db, dispatch) {
        try {
            const { project_id } = req
            let calendarsMap = new Map()

            const tasks = await updateEarliestDate(db, project_id)

            // get calendars from firestore
            const calendars = await FirebaseUsage.getQuery('calendar', ['project_id', '==', project_id])
            const calendarData = calendars.docs.map(doc => ({
                ...doc.data(), calendarId: doc.id
            }))
            const { earliestDate, latestDate }: any = await FirebaseUsage.getDoc('projects', project_id).then((doc) => doc.data() as ProjectModel)
            calendarsMap.set(`earliestDate:${project_id}`, earliestDate / 1000)
            calendarsMap.set(`latestDate:${project_id}`, latestDate / 1000)
            const { masterCalendarDict, masterWorkPatternDict, specificCalendarDict, specificWorkPatternDict, workHoursDict } = createIndexedCalendarArrays(calendarData, latestDate, earliestDate)

            // console.log("workHoursDict", workHoursDict)
            for (let key in workHoursDict) {
                const value = workHoursDict[key]
                calendarsMap.set(`whd:${key}`, value)
            }

            // @ts-ignore
            async function masterCalendarDictUpload(calendarObject: {}, secondKey: number | string){
                for (let key in calendarObject) {
                    const value = calendarObject[key]
                    const uniqueKey = `mcd:${secondKey}:${key}`
                    calendarsMap.set(uniqueKey, value)
                }
            }
            await masterCalendarDict.forEach(masterCalendarDictUpload)

            for (const i of masterWorkPatternDict) {
                const index = i[0]
                const workPatternObject = i[1]
                for (let key in workPatternObject) {
                    const value = workPatternObject[key]
                    const uniqueKey = `mwp:${index}:${key}`
                    calendarsMap.set(uniqueKey, value)
                }
            }

            const calendarArray: any[] = []
            for (const calendar of specificCalendarDict) {
                const indices = Array.from(specificCalendarDict.get(calendar[0]))
                const calendarKey = calendar[0]
                calendarArray.push(calendarKey)
                indices.map(async (i: any) => {
                    const index = i[0]
                    const seconds = i[1]
                    calendarsMap.set(`scd:${calendarKey}:${index}`, `${seconds}`)
                })
            }
            calendarsMap.set(`calendars:${project_id}`, calendarArray)

            for (let calendar in specificWorkPatternDict) {
                const workPatternObject = specificWorkPatternDict[calendar]
                const { weeklyIndices, maxDate, maxIndex, maxWeekIndex, maxDayIndex } = workPatternObject
                calendarsMap.set(`swp:maxDate:${calendar}`, maxDate)
                calendarsMap.set(`swp:maxIndex:${calendar}`, maxIndex)
                calendarsMap.set(`swp:maxWeekIndex:${calendar}`, maxWeekIndex)
                calendarsMap.set(`swp:maxDayIndex:${calendar}`, maxDayIndex)
                for (const i of weeklyIndices) {
                    const index = i[0]
                    const hour = i[1][0]
                    const day = i[1][1]
                    calendarsMap.set(`swp:wi:h:${calendar}:${index}`, hour)
                    calendarsMap.set(`swp:wi:d:${calendar}:${index}`, day)
                }
                calendarsMap.set(`swp:wi:count:${calendar}`, weeklyIndices.size - 1)
            }
            const setCalendars = async () => dispatch(projectActions.Actions.setActiveProjectCalendars(calendarsMap))
            setCalendars().catch((err) => console.log(err))
            return {calendarsMap, tasks, latestDate} as any
        }
        catch (err) {
            console.error(err);
            return err;
        }
    }

    static async setCPM(req : {project_id: string, initialRun: boolean, calendarsMap: Map<string | number, any>, tasks: Map<any, any>}, db) {
        try {
            const { project_id, initialRun, calendarsMap, tasks } = req
            return await generateUploadData(project_id, db, initialRun, calendarsMap, tasks) as any
        }
        catch (err) {
            console.error(err);
            return err;
        }
    }
    static async runCPM(req, db, dispatch, cpmRefresh) {
        try {
            const { project_id, initialRun } = req
            const setCalendars = await CpmFunctionsController.setCalendars({project_id: project_id}, db, dispatch)
            const {calendarsMap, tasks, latestDate} = setCalendars
            const {tasksMap, allTasks} = await CpmFunctionsController.setCPM({project_id: project_id, initialRun: initialRun, calendarsMap: calendarsMap, tasks: tasks}, db)
            const noPredTasks = tasksMap.get(`start:${project_id}`)
            const noSuccTasks = tasksMap.get(`end:${project_id}`)
            let cpmMap = await cpmScheduleRefresh(noPredTasks, noSuccTasks, tasksMap, calendarsMap, project_id, db, latestDate, cpmRefresh)
            // FirebaseProjectFunctions.updatePredStatuses({projectId: project_id}).catch((err) => console.log(err))
            cpmMap.set(`${project_id}:tasks`, allTasks)
            const setCpmMapState = async () => dispatch(projectActions.Actions.setCpmMap(cpmMap))
            setCpmMapState().catch((err) => console.log(err))
            return {calendarsMap: calendarsMap, cpmMap: cpmMap, allTasks: allTasks, firestoreTasks: tasks} as any
        }
        catch (err) {
            console.error(err);
            return err;
        }
    }

    static async getRacingLines(taskList, project_id, dataDate, scdMap, tasksMap, specific) {
        const allSCDMap = scdMap
        const calendars = await allSCDMap.get(`calendars:${project_id}`);
        const latestEnd = tasksMap.get(`globalLf:${project_id}`);
       return await racingLine(dataDate, latestEnd, tasksMap, scdMap, taskList, calendars, project_id, specific)
    }

    static async calcRacingLine(req, dispatch) {
        const timeNow = Date.now()
        const dataDate = Math.floor(new Date().getTime() / 1000 / 86400) * 86400
        const db = FirebaseUsage.database()
        try {
            const {project_id, initial_run, cpm_refresh} = req; // get the username from the request
            const cpmResult = await CpmFunctionsController.runCPM({project_id: project_id, initialRun: initial_run === "true"}, db, dispatch, cpm_refresh)
            let taskList = cpmResult.allTasks
            let tasksMap = cpmResult.cpmMap
            const fireStoreTasks = cpmResult.firestoreTasks
            let targetFronts = store.getState().project.activeProject!.totalFronts
            if (cpm_refresh || !targetFronts) {
                targetFronts = await CpmFunctionsController.getRacingLines(taskList, project_id, dataDate, cpmResult.calendarsMap, tasksMap, false)
            }
            // await generateCurves(racingLines.cumulativeMap, db, racingLines.end, project_id, dataDate).catch((err) => console.log(err))
            const calendars = store.getState().project.activeProject!.calendars
            const qualityData = await calculateDCMA(fireStoreTasks, tasksMap, calendars, targetFronts)
            store.dispatch(projectActions.Actions.setActiveProjectQuality(qualityData))
            return "Success"
        } catch (err) {
            console.error(err);
            return err;
        }
    }

    static async calcRacingLineLocal(req) {
        const timeNow = Date.now()
        const dataDate = Math.floor(new Date().getTime() / 1000 / 86400) * 86400
        try {
            const { project_id, taskList, tasksMap, calendarsMap } = req; // get the username from the request
            await CpmFunctionsController.getRacingLines(taskList, project_id, dataDate, calendarsMap, tasksMap, true)
            // await generateCurves(racingLines.cumulativeMap, db, racingLines.end, project_id, dataDate).catch((err) => console.log(err))
            return "Success"
        } catch (err) {
            console.error(err);
            return err;
        }
    }

    static async runTaskCPM(req: {
        project_id: string,
        task_id: string,
        event_type: string,
        seconds: number,
        user_id: string,
        user_email: string,
        expiry_date: number,
        suspend_reason: string,
        reason_text: any
    }, dispatch) {
        try {
            const dataDate = Math.floor(new Date().getTime() / 1000 / 86400) * 86400
            const {project_id, task_id, event_type, seconds, user_id, user_email, expiry_date, suspend_reason, reason_text} = req; // get the username from the request
            const db = FirebaseUsage.database()
            let tasksMap = store.getState().project.cpmMap
            const calendarsMap = store.getState().project.activeProjectCalendars
            const eventResult = await handleEvent(
                event_type,
                task_id,
                seconds,
                db,
                user_id,
                project_id,
                user_email,
                expiry_date,
                suspend_reason,
                tasksMap,
                calendarsMap,
                reason_text,
            )
            tasksMap = eventResult.tasksMap
            const taskData = eventResult.taskData
            const noPredTasks = [task_id]
            const latestDate = calendarsMap.get(`latestDate:${project_id}`)
            const allTasks = tasksMap.get(`${project_id}:tasks`)
            const noSuccTasks = tasksMap.get(`end:${project_id}`)
            if (this.eventTypeHandler(event_type)) {
                // const cpmMap = await cpmScheduleSpecific(noPredTasks, noSuccTasks, tasksMap, calendarsMap, project_id, db, latestDate)
                const cpmMap = await cpmScheduleSimple(noPredTasks, noSuccTasks, tasksMap, calendarsMap, project_id, db, latestDate)
                await this.getRacingLines(allTasks, project_id, dataDate, calendarsMap, tasksMap, true)
                dispatch(projectActions.Actions.setCpmMap(cpmMap))
            } else {
                dispatch(projectActions.Actions.setCpmMap(tasksMap))
            }
            return taskData
        } catch (err) {
            console.error(err);
            return err;
        }
    }

    static async runMonteCarlo(
        trackedMilestones: TrackedMilestoneModel[],
        upperLimit: number,
        lowerLimit: number,
        project_id: string,
        dispatch,
        iterations: number
    ) {
        try {
            let tasksMap = store.getState().project.cpmMap
            const calendarsMap = store.getState().project.activeProjectCalendars
            const noPredTasks = tasksMap.get(`start:${project_id}`)
            const latestDate = calendarsMap.get(`latestDate:${project_id}`)
            const noSuccTasks = tasksMap.get(`end:${project_id}`)
            let analysisMap = new Map()

            const runIteration = async (i: number) => {
                analysisMap = await cpmMonteCarlo(
                    noPredTasks,
                    noSuccTasks,
                    tasksMap,
                    calendarsMap,
                    project_id,
                    latestDate,
                    trackedMilestones,
                    upperLimit,
                    lowerLimit,
                    i,
                    analysisMap
                    )
                    .then((res) => {
                        dispatch(projectActions.Actions.setMonteCarloIndex(i + 1))
                        if (i === iterations - 1) {
                            let resultsMap = new Map()
                            analysisMap.forEach((value, key) => {
                                for (let k in value) {
                                    if (resultsMap.has(k)) {
                                        resultsMap.set(k, [...resultsMap.get(k), value[k]])
                                    } else {
                                        resultsMap.set(k, [value[k]])
                                    }
                                }
                            })

                            // calculate the average of each milestone
                            let averages: any = {}
                            resultsMap.forEach((value, key) => {
                                averages[key] = value.reduce((a, b) => a + b) / value.length
                            })

                            // calculate the P20, P50, P80 of each milestone
                            let percentiles: any = {}
                            let names: any = {}
                            resultsMap.forEach((value, key) => {
                                const cpmTask: CpmTaskModel = tasksMap.get(key)
                                names[key] = cpmTask.task_name
                                value.sort((a, b) => a - b)
                                percentiles[key] = {
                                    p20: value[Math.floor(value.length * 0.2)],
                                    p50: value[Math.floor(value.length * 0.5)],
                                    p80: value[Math.floor(value.length * 0.8)],
                                    median: value[Math.floor(value.length * 0.5)],
                                    min: value[0],
                                    max: value[value.length - 1],
                                    deterministic: convertIndexToSeconds(cpmTask.es, cpmTask.cal_id, calendarsMap)
                                }
                            })
                            dispatch(projectActions.Actions.setMonteCarloResults({averages, percentiles, resultsMap, names}))
                        }
                        return res
                    })
            }

            for (let i = 0; i < iterations; i++) {
                setTimeout(() => runIteration(i), 0)
            }
            // console.log(analysisMap, "analysisMap")
            //     // )})
            // console.log("Monte Carlo complete, time taken", Date.now() - timeNow, "ms")

            return true
        } catch (err) {
            console.error(err);
            return err;
        }
    }

    static async addTask(task: TaskModel, projectId: string) {
        console.log("Adding task", task)
        const dataDate = Math.floor(new Date().getTime() / 1000 / 86400) * 86400
        let tasksMap = store.getState().project.cpmMap
        const calendarsMap = store.getState().project.activeProjectCalendars
        const calendarDict = store.getState().project.activeProject!.calendarDict
        const latestDate = calendarsMap.get(`latestDate:${projectId}`)
        tasksMap = await generateCpmTask(calendarsMap, task, tasksMap, latestDate, calendarDict)
        const db = FirebaseUsage.database()
        const taskPreds = tasksMap.get(`${task.task_id}:preds`)
        const noPredTasks = taskPreds.length === 0 ?
            [task.task_id] :
            taskPreds.map((pred: string) => tasksMap.get(pred).pred_task_id)
        const noSuccTasks = tasksMap.get(`end:${projectId}`)
        const allTasks = tasksMap.get(`${projectId}:tasks`)
        tasksMap = await cpmScheduleSimple(noPredTasks, noSuccTasks, tasksMap, calendarsMap, projectId, db, latestDate)
        await this.getRacingLines(allTasks, projectId, dataDate, calendarsMap, tasksMap, true)
    }

    static eventTypeHandler(eventType) {
        // handler to determine if CPM should be run following event
        switch (eventType) {
            case "start":
                return true
            case "unstart":
                return true
            case "confirmStart":
                return false
            case "finish":
                return true
            case "suspend":
                return false
            case "resume":
                return false
            case "confirm":
                return false
            case "duration":
                return true
            case "reject":
                return true
            case "block":
                return false
            case "unblock":
                return false
            case "constrain":
                return true
            case "issue":
                return false
            case "rename":
                return false
            case "approveNew":
                return true
            default:
                return false
        }
    }
}
export default CpmFunctionsController;