import {useTypedSelector} from "../../../../store/selectors/selectors.utils";
import React from 'react';
import {WbsModel} from "../../../../models/responses/wbs.model";
import {List, ListHeader, ListIcon, ListItem, ListList, Modal} from "semantic-ui-react";
import styles from './WbsTree.module.scss';
import FirebaseUsage from "../../../../firebase/firebase.usage";
import {subsManager} from "../../../../store/middleware/subs-manager/subs-manager";

export default function WbsTree(props: any) {
    const wbsList = useTypedSelector((state) => state.wbs.wbsList);
    const [wbsTree, setWbsTree] = React.useState<any[]>(wbsList.map((wbs) =>
    wbs.level <= 1 ? {...wbs, expanded: true} : {...wbs, expanded: false}));

    const level0Wbs = wbsTree.filter((wbs) => wbs.level === 0);

    const handleExpand = (wbsId: string) => {
        const newWbsTree = wbsTree.map((wbs) => {
            if (wbs.wbs_id === wbsId) {
                return {...wbs, expanded: !wbs.expanded};
            }
            return wbs;
        });
        setWbsTree(newWbsTree);
    }

    const generateWbs = (wbsItem: WbsModel)=> {
        const children = wbsItem.expanded ? wbsItem.children.map((child) => {
            const childWbs = wbsTree.find((wbs) => wbs.wbs_id === child);
            return childWbs ? generateWbs(childWbs) : null;
        }).filter((child) => child !== null) : null;

        return (
            <ListItem className={styles.WbsListItem}>
                <ListIcon name={wbsItem.expanded && wbsItem.children.length ? 'caret down' : wbsItem.children.length ? 'caret right' : 'file outline'}
                          className={styles.ExpandCollapseIcon}
                            onClick={() => {
                                if (wbsItem.children.length) handleExpand(wbsItem.wbs_id)
                            }}
                />
                <List.Content>
                    <ListHeader className={styles.WbsHeading}
                                onClick={() => handleWbsChange(wbsItem)}
                    >{wbsItem.wbs_name}</ListHeader>
                    <ListList>
                        {children}
                    </ListList>
                </List.Content>
            </ListItem>
        )
    }

    const handleWbsChange = async (wbsItem: WbsModel) => {
        const existingWbs = wbsList.find((wbs) =>
            wbs.wbs_id === props.existingWbs);
        const parentWbs = wbsList.find((wbs) =>
            wbs.wbs_id === wbsItem.parent_wbs_id);
        await FirebaseUsage.updateDoc('wbs', wbsItem.wbs_id, {tasks: [...wbsItem.tasks, props.taskId]})
            .catch((error) => console.error(error));
        if (existingWbs) {
            await FirebaseUsage.updateDoc('wbs', existingWbs.wbs_id, {
                tasks: existingWbs.tasks.filter((taskId) => taskId !== props.taskId)})
                .catch((error) => console.error(error));
        }
        await FirebaseUsage.updateDoc('tasks', props.taskId, {
            wbs_id: wbsItem.wbs_id,
            wbsPath: wbsItem.wbsPath,
            wbs: wbsItem.wbs_name,
            parent_wbs_name: {
                parent_wbs_id: parentWbs ? parentWbs.parent_wbs_id : null,
                wbs_name: parentWbs ? parentWbs.wbs_name : null,
                wbs_id: parentWbs ? parentWbs.wbs_id : null
            }
        }).catch((error) => console.error(error));
        props.setWbsPlaceholder(wbsItem.wbsPath + ' / ' + wbsItem.wbs_name);
        props.setOpenWbsTree(false);
        subsManager.subscribeWbsList(wbsItem.projectId);
    }

    return (
        <Modal
            size='large'
            open={true}
            className={styles.WbsTreeModal}
            closeIcon={true}
            onClose={() => props.setOpenWbsTree(false)}>
            <Modal.Header><h4>Select Task WBS</h4></Modal.Header>
                <div className={styles.WbsTreeContainer}>
                    <List className={styles.WbsTree} link>
                        {level0Wbs.map((wbs) => generateWbs(wbs))}
                    </List>
                </div>
        </Modal>
    )
}