import TaskStatusModel from "./task-status.model";
import firebase from "firebase/app";
import { SuspendReasons } from "../suspend-reason.enum";
import { Maybe } from "@martin_hotell/rex-tils";
import { WbsModel } from "./wbs.model";
import { RelationshipsModel } from "./relationships.model";
import { CheckList } from "../checklist-model";
import { TaskListSectionModel } from "../task-list-section.model";
import { TaskType } from "../task-type";
import { Timestamp } from "firebase/firestore";

export enum ProcessedFrom {
  QUEUED = "Queued",
  IN_PROCESS = "In Process",
}

export enum TaskConstraintType {
  // Start on
  START_ON = "CS_MSO",

  // Start on or after
  START_ON_OR_AFTER = "CS_MSOA",

  // Start on or before
  START_ON_OR_BEFORE = "CS_MSOB",

  // Finish on
  FINISH_ON = "CS_MEO",

  // Finish on or after
  FINISH_ON_OR_AFTER = "CS_MEOA",

  //Finish On or Before
  FINISH_ON_OR_BEFORE = "CS_MEOB",

  // Mandatory start
  MANDATORY_START = "CS_MANDSTART",

  // Mandatory finish
  MANDATORY_FINISH = "CS_MANDFIN",

  //As Late As Possible
  AS_LATE_AS_POSSIBLE = "CS_ALAP",

  NONE = ''
}

export class TaskSnapshot {
  taskId: string;
  projectId: string;
  actEndDate: Timestamp | null;
  actStartDate: Timestamp | null;
  earlyEndDate: Timestamp;
  earlyStartDate: Timestamp;
  lateEndDate: Timestamp;
  lateStartDate: Timestamp;
  forecastStartDate: Timestamp;
  forecastEndDate: Timestamp;
  remainingDuration: number;
  targetDuration: number;
  contractId: string;
  taskCode: string;
  flow: boolean;
  index: number;
}

export default class TaskModel<TI = string> {
  task_name: string;
  taskListType: TaskListSectionModel | ProcessedFrom | null;
  evaluated: boolean;
  projectId: string;
  status: TaskStatusModel;
  taskForce: any[];
  task_id: TI;
  wbs: string;
  wbs_id: string;
  blocked: boolean;
  remainingDuration: number;
  targetDuration: number;
  expiryDate: Timestamp | null;
  enteredWorkInProcessTime: Timestamp | null;
  processedFrom: ProcessedFrom | null | TaskListSectionModel;
  suspended: boolean;
  suspendReason?: SuspendReasons | string | null;
  task_code: string;
  progress?: {userId: string, date: Timestamp, progress: number} [];
  flow: boolean;
  parent_wbs_name: any;
  checklist: CheckList[];
  importedChecklist: CheckList[];
  checkListLock?: boolean;
  calendar_id: string;
  task_type: TaskType;
  blActualStartDate?: Timestamp | null;
  blActEndDate?: Timestamp | null;
  blEarlyStartDate?: Timestamp | null;
  blEarlyEndDate?: Timestamp | null;
  blLateStartDate?: Timestamp | null;
  blLateEndDate?: Timestamp | null;
  blRemainingDuration?: number;
  blTargetDuration?: number;
  overdueReason?: string;
  targetFinishDate?: Timestamp;
  late_end_date: Timestamp;
  late_start_date: Timestamp;
  act_start_date: Maybe<Timestamp>;
  act_end_date: Maybe<Timestamp>;
  early_end_date: Timestamp;
  early_start_date: Timestamp;
  wbsPath: string;

  relations: RelationshipsModel[];
  predStatus: number;

  forecastDate?: Timestamp;
  forecastStart?: Timestamp;
  forecastFinish?: Timestamp;
  declaredCompleteTimestamp?: Timestamp | null;
  declaredCompleteBy?: string;
  confirmedCompleteTimestamp?: Timestamp | null;
  confirmedCompleteBy?: string;

  constraint_type: TaskConstraintType | null;
  constraint_date: Timestamp | null;
  float: number;
  index?: number;
  users?: any[];
}
