import FirebaseUsage from "../../../../firebase/firebase.usage";
import {COLLECTIONS} from "../../../../firebase/constants";
import ProjectModel from "../../../../models/responses/project.model";
import store from "../../../../store/store";

export default async function updateEarliestDate(db, projectId) {
    const tasks = store.getState().task.tasks
    let tasksMap = new Map()
    const projectData: ProjectModel = await FirebaseUsage.getDoc(COLLECTIONS.PROJECTS, projectId).then(doc => doc.data() as ProjectModel)
    let earliestDate = projectData.earliestDate ? projectData.earliestDate : new Date().getTime()
    let latestDate = projectData.latestDate ? projectData.latestDate : new Date().getTime()
    for (const task of tasks) {
        tasksMap.set(task.task_id, task)
        if (task.act_start_date) {
            const startDate = task.act_start_date.toDate().getTime()
            if (startDate < earliestDate) {
                earliestDate = startDate
            }
        }
        if (task.act_end_date) {
            const endDate = task.act_end_date.toDate().getTime()
            if (endDate > latestDate) {
                latestDate = endDate
            }
        } else if (task.late_end_date) {
            const endDate = task.late_end_date.toDate().getTime()
            if (endDate > latestDate) {
                latestDate = endDate
            }
        }
    }
    console.log('earliestDate', new Date(earliestDate))
    console.log('latestDate', new Date(latestDate))
    await FirebaseUsage.updateDoc(COLLECTIONS.PROJECTS, projectId, {
        earliestDate: earliestDate,
        latestDate: latestDate
    })
    return tasksMap
}
