import React from 'react';
import { Group } from '@visx/group';
import { ViolinPlot, BoxPlot, computeStats } from '@visx/stats';
import { LinearGradient } from '@visx/gradient';
import { scaleBand, scaleLinear } from '@visx/scale';
import genStats, { Stats } from '@visx/mock-data/lib/generators/genStats';
import { getSeededRandom, getRandomNormal } from '@visx/mock-data';
import { withTooltip, Tooltip, defaultStyles as defaultTooltipStyles } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { PatternLines } from '@visx/pattern';
import {
    useTrackedMilestonesSelector
} from "../../../../store/selectors/project.selectors";
import TrackedMilestoneModel from "../../../../models/responses/tracked-milestone.model";
import moment from "moment";

// seeded randomness
const seededRandom = getSeededRandom(0.1);
const randomNormal = getRandomNormal.source(getSeededRandom(0.789))(4, 3);
const data: Stats[] = genStats(5, randomNormal, () => 10 * seededRandom());

// accessors
const x = (d: Stats) => d.boxPlot.x;
const min = (d: Stats) => d.boxPlot.min;
const max = (d: Stats) => d.boxPlot.max;
const median = (d: Stats) => d.boxPlot.median;
const firstQuartile = (d: Stats) => d.boxPlot.firstQuartile;
const thirdQuartile = (d: Stats) => d.boxPlot.thirdQuartile;
const outliers = (d: Stats) => d.boxPlot.outliers;

interface TooltipData {
    name?: string;
    min?: number | string;
    median?: number | string;
    max?: number | string;
    firstQuartile?: number | string;
    thirdQuartile?: number | string;
}

export type StatsPlotProps = {
    width: number;
    height: number;
    results: any;
};

export default withTooltip<StatsPlotProps, TooltipData>(
    ({
         width,
         height,
         results,
         tooltipOpen,
         tooltipLeft,
         tooltipTop,
         tooltipData,
         showTooltip,
         hideTooltip,
     }: StatsPlotProps & WithTooltipProvidedProps<TooltipData>) => {
        // bounds
        const xMax = width;
        const yMax = height - 120;
        const trackedMilestones = useTrackedMilestonesSelector();

        const {resultsMap} = results;

        let resultData: any[] = []
        trackedMilestones.forEach((milestone: TrackedMilestoneModel) => {
            const resultArray = resultsMap.get(milestone.taskId)
            try {
                const milestoneData = computeStats(
                    resultArray.map((value: any) => value - results.percentiles[milestone.taskId].median))
                resultData.push({
                    ...milestoneData,
                    boxPlot: {...milestoneData.boxPlot,
                        x: results.names[milestone.taskId],
                        taskId: milestone.taskId,
                        p20: results.percentiles[milestone.taskId].p20,
                        p80: results.percentiles[milestone.taskId].p80,
                        p50: results.percentiles[milestone.taskId].p50
                    }
                })
            } catch (e) {
                // resultData.push({
                //     binData: [{value: resultArray[0], count: resultArray.length}],
                //     boxPlot: {
                //         min: resultArray[0],
                //         max: resultArray[0],
                //         median: resultArray[0],
                //         firstQuartile: resultArray[0],
                //         thirdQuartile: resultArray[0],
                //         outliers: []
                //     }
                // })
            }
        })

        console.log(resultData, data)

        // scales
        const xScale = scaleBand<string>({
            range: [0, xMax],
            round: true,
            domain: resultData.map(x),
            padding: 0.4,
        });

        const values = resultData.reduce((allValues, { boxPlot }) => {
            allValues.push(boxPlot.min, boxPlot.max);
            return allValues;
        }, [] as number[]);
        const minYValue = Math.min(...values);
        const maxYValue = Math.max(...values);

        const yScale = scaleLinear<number>({
            range: [yMax, 0],
            round: true,
            domain: [minYValue, maxYValue],
        });

        const boxWidth = xScale.bandwidth();
        const constrainedWidth = Math.min(80, boxWidth);
        console.log(constrainedWidth)

        return width < 10 ? null : (
            <div style={{ position: 'relative' }}>
                <svg width={width} height={height}>
                    {/*<LinearGradient id="statsplot" to="#8b6ce7" from="#87f2d4" />*/}
                    <LinearGradient id="statsplot" to="rgb(51, 51, 51)" from="#87f2d4" />
                    <rect x={0} y={0} width={width} height={height} fill="url(#statsplot)" rx={2} />
                    <PatternLines
                        id="hViolinLines"
                        height={3}
                        width={3}
                        stroke="#ced4da"
                        strokeWidth={1}
                        // fill="rgba(0,0,0,0.3)"
                        orientation={['horizontal']}
                    />
                    <Group top={30}>
                        {resultData.map((d: Stats | any, i) => (
                            <g key={i}>
                                <ViolinPlot
                                    data={d.binData}
                                    stroke="#dee2e6"
                                    left={xScale(x(d))!}
                                    width={constrainedWidth}
                                    valueScale={yScale}
                                    fill="url(#hViolinLines)"
                                />
                                <BoxPlot
                                    min={min(d)}
                                    max={max(d)}
                                    left={xScale(x(d))! + 0.3 * constrainedWidth}
                                    firstQuartile={firstQuartile(d)}
                                    thirdQuartile={thirdQuartile(d)}
                                    median={median(d)}
                                    boxWidth={constrainedWidth * 0.4}
                                    fill="#FFFFFF"
                                    fillOpacity={0.3}
                                    stroke="#FFFFFF"
                                    strokeWidth={2}
                                    valueScale={yScale}
                                    outliers={outliers(d)}
                                    minProps={{
                                        onMouseOver: () => {
                                            showTooltip({
                                                tooltipTop: yScale(min(d)) ?? 0 + 40,
                                                tooltipLeft: xScale(x(d))! + constrainedWidth + 5,
                                                tooltipData: {
                                                    min: moment(new Date((min(d) + results.percentiles[d.boxPlot.taskId].median) * 1000)).format("DD MMM YYYY"),
                                                    name: x(d),
                                                },
                                            });
                                        },
                                        onMouseLeave: () => {
                                            hideTooltip();
                                        },
                                    }}
                                    maxProps={{
                                        onMouseOver: () => {
                                            showTooltip({
                                                tooltipTop: yScale(max(d)) ?? 0 + 40,
                                                tooltipLeft: xScale(x(d))! + constrainedWidth + 5,
                                                tooltipData: {
                                                    max: moment(new Date((max(d) + results.percentiles[d.boxPlot.taskId].median) * 1000)).format("DD MMM YYYY"),
                                                    name: x(d),
                                                },
                                            });
                                        },
                                        onMouseLeave: () => {
                                            hideTooltip();
                                        },
                                    }}
                                    boxProps={{
                                        onMouseOver: () => {
                                            showTooltip({
                                                tooltipTop: yScale(median(d)) ?? 0 + 40,
                                                tooltipLeft: xScale(x(d))! + constrainedWidth + 5,
                                                tooltipData: {
                                                    ...d.boxPlot,
                                                    max: moment(new Date((max(d) + results.percentiles[d.boxPlot.taskId].median) * 1000)).format("DD MMM YYYY"),
                                                    thirdQuartile: moment(new Date((thirdQuartile(d) + results.percentiles[d.boxPlot.taskId].median) * 1000)).format("DD MMM YYYY"),
                                                    median: moment(new Date((median(d) + results.percentiles[d.boxPlot.taskId].median) * 1000)).format("DD MMM YYYY"),
                                                    firstQuartile: moment(new Date((firstQuartile(d) + results.percentiles[d.boxPlot.taskId].median) * 1000)).format("DD MMM YYYY"),
                                                    min: moment(new Date((min(d) + results.percentiles[d.boxPlot.taskId].median) * 1000)).format("DD MMM YYYY"),
                                                    name: x(d),
                                                },
                                            });
                                        },
                                        onMouseLeave: () => {
                                            hideTooltip();
                                        },
                                    }}
                                    medianProps={{
                                        style: {
                                            stroke: 'white',
                                        },
                                        onMouseOver: () => {
                                            showTooltip({
                                                tooltipTop: yScale(median(d)) ?? 0 + 40,
                                                tooltipLeft: xScale(x(d))! + constrainedWidth + 5,
                                                tooltipData: {
                                                    median: moment(new Date((median(d) + results.percentiles[d.boxPlot.taskId].median) * 1000)).format("DD MMM YYYY"),
                                                    name: x(d),
                                                },
                                            });
                                        },
                                        onMouseLeave: () => {
                                            hideTooltip();
                                        },
                                    }}
                                />
                                <line
                                    x1={xScale(x(d))! + constrainedWidth / 2 - 5}
                                    y1={yScale(d.boxPlot.p50 - results.percentiles[d.boxPlot.taskId].median)}
                                    x2={xScale(x(d))! + constrainedWidth / 2 + 5}
                                    y2={yScale(d.boxPlot.p50 - results.percentiles[d.boxPlot.taskId].median)}
                                    stroke="white"
                                    strokeWidth={2}
                                />
                                <line
                                    x1={xScale(x(d))! + constrainedWidth / 2 - 5}
                                    y1={yScale(d.boxPlot.p80 - results.percentiles[d.boxPlot.taskId].median)}
                                    x2={xScale(x(d))! + constrainedWidth / 2 + 5}
                                    y2={yScale(d.boxPlot.p80 - results.percentiles[d.boxPlot.taskId].median)}
                                    stroke="white"
                                    strokeWidth={2}
                                />
                                <line
                                    x1={xScale(x(d))! + constrainedWidth / 2 - 5}
                                    y1={yScale(d.boxPlot.p20 - results.percentiles[d.boxPlot.taskId].median)}
                                    x2={xScale(x(d))! + constrainedWidth / 2 + 5}
                                    y2={yScale(d.boxPlot.p20 - results.percentiles[d.boxPlot.taskId].median)}
                                    stroke="white"
                                    strokeWidth={2}
                                />
                                <foreignObject
                                    x={xScale(x(d))! - 10}
                                    y={height - 120}
                                    width={constrainedWidth + 20}
                                    height={100}
                                ><div style={{
                                    height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"
                                }}>
                                <p style={{
                                    fontSize: "10px", textAlign: "center", verticalAlign: "middle", color: "white"
                                }}>{d.boxPlot.x}</p>
                                    </div>
                                </foreignObject>
                            </g>
                        ))}
                    </Group>
                </svg>

                {tooltipOpen && tooltipData && (
                    <Tooltip
                        top={tooltipTop}
                        left={tooltipLeft}
                        style={{ ...defaultTooltipStyles, backgroundColor: '#283238', color: 'white' }}
                    >
                        <div>
                            <strong>{tooltipData.name}</strong>
                        </div>
                        <div style={{ marginTop: '5px', fontSize: '12px' }}>
                            {tooltipData.max && <div>Max: {tooltipData.max}</div>}
                            {tooltipData.thirdQuartile && <div>3rd quartile: {tooltipData.thirdQuartile}</div>}
                            {tooltipData.median && <div>Median: {tooltipData.median}</div>}
                            {tooltipData.firstQuartile && <div>1st quartile: {tooltipData.firstQuartile}</div>}
                            {tooltipData.min && <div>Min: {tooltipData.min}</div>}
                        </div>
                    </Tooltip>
                )}
            </div>
        );
    },
);