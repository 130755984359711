import React, {useEffect, useState} from "react";
import {Input, Loader, Modal, TextArea} from "semantic-ui-react";
import {useDispatch} from "react-redux";
import FirebaseUsage from "../../../../../firebase/firebase.usage";
import {COLLECTIONS} from "../../../../../firebase/constants";
import {useUserSelector} from "../../../../../store/selectors/authorization.selectors";
import fetch from "node-fetch";
import styles from '../UserSideBar.module.scss';
import moment from "moment";
import { leapfrog } from 'ldrs'

leapfrog.register()

export default function UserTickets(props: {setOpen: any}) {

    const { setOpen } = props;
    const user = useUserSelector();
    const [tickets, setTickets] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [selectedTicket, setSelectedTicket] = useState<any>(null);
    const dispatch = useDispatch();
    const [attachments, setAttachments] = useState<any[]>([])
    const [fullScreenImage, setFullScreenImage] = useState<any>(null);

    const fetchUserTickets = async () => {
        await fetch('https://us-central1-flowledgerprod.cloudfunctions.net/get-jira-update/?userEmail=' + user!.userEmail)
            .then(async () => {
                const tickets = user!.isAdmin ? await FirebaseUsage.getCollection(COLLECTIONS.ISSUE_LOGS)
                    .then((res) => res.docs.map((doc) => doc.data())) : await FirebaseUsage.getQuery(COLLECTIONS.ISSUE_LOGS, ['userEmail', '==', user?.userEmail || ''])
                    .then((res) => res.docs.map((doc) => doc.data()))
                setTickets(tickets.filter((ticket: any) => ticket.ticketId).sort((a: any, b: any) => {
                    if (a.updateDate) {
                        if (b.updateDate) {
                            return moment(b.updateDate).toDate().getTime() - moment(a.updateDate).toDate().getTime();
                        }
                        return 1;
                    } else {
                        if (b.updateDate) {
                            return -1;
                        } else {
                            return moment(b.createDate).toDate().getTime() - moment(a.createDate).toDate().getTime();
                        }
                    }}));
            })
        return;
    }

    useEffect(() => {
        fetchUserTickets().then(() => setLoading(false));
    }, []);


    const fetchAttachments = async (ticket: any) => {
        let attachmentUrls: any[] = [];
        for (let attachment of ticket.attachments) {
            const url = await FirebaseUsage.downloadImageAsBlob({
                storagePath: `issue_attachments/${attachment.id}`,
                type: attachment.fileName.split('.').pop()
            })
            attachmentUrls.push({src: url, fileName: attachment.fileName});
        }
        setAttachments(attachmentUrls);
    }

    useEffect(() => {
        if (!selectedTicket) {
            setAttachments([]);
            return;
        }
        if (selectedTicket.attachments.length > 0) {
            fetchAttachments(selectedTicket).catch((e) => console.error(e));
        }
    }, [selectedTicket]);

    const calculateTimeSince = (thisDate: Date) => {
        if (!thisDate) return 'never';
        const time = moment(thisDate).toDate();
        const currentTime = new Date();
        const timeDifference = currentTime.getTime() - time.getTime();
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days > 0) {
            return `${days} day${days > 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        } else {
            return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
        }
    }

    const statusConverter = (status: string) => {
        switch (status) {
            case 'To Do':
                return {header: 'Open', style: styles.OpenTicket};
            case 'Closed':
                return {header: 'Closed', style: styles.ClosedTicket};
            case 'Done':
                return {header: 'Closed', style: styles.ClosedTicket};
            case 'In Progress':
                return {header: 'In Progress', style: styles.InProgressTicket};
            case 'Open':
                return {header: 'Open', style: styles.OpenTicket};
            case 'Reopened':
                return {header: 'Reopened', style: styles.ReopenedTicket};
            case 'Work In Progress':
                return {header: 'Work In Progress', style: styles.InProgressTicket};
            case 'Pending':
                return {header: 'Open', style: styles.OpenTicket};
            default:
                return {header: status, style: styles.OpenTicket};
        }
    }

    const priorityConverter = (priority: string) => {
        switch (priority) {
            case 'Low':
                return {header: 'Low', style: styles.LowPriority};
            case 'Medium':
                return {header: 'Medium', style: styles.MediumPriority};
            case 'High':
                return {header: 'High', style: styles.HighPriority};
            case 'Highest':
                return {header: 'Highest', style: styles.HighestPriority};
            default:
                return {header: priority, style: styles.LowPriority};
        }
    }

    const formatContent = (content: any, i: number) => {
        switch (content.type) {
            case 'paragraph':
                return formatCommentText(content.content)
            case 'mention':
                return content.attrs.text + ' '
            case 'text':
                return content.text + ' '
            case 'listItem':
                return formatCommentText(content.content, 'listItem', i)
            case 'orderedList':
                return formatCommentText(content.content)
            default:
                return ''
        }
    }

    const formatCommentText = (content: any, subType?: string, i?: number) => {
        let outText: any = subType === 'listItem' ? [<span>{(i! + 1).toString()}.</span>] : [];
        let index = i ? i + 1 : 0;
        for (const item of content) {
            outText.push(<div className={styles.CommentText}>{formatContent(item, index)}</div>)
            index++;
        }
        return subType && subType === 'listItem' ?
            <span style={{display: "flex", flexDirection: "row"}}>{outText}</span> :
            outText;
    }


    return (
        <Modal open={true} size={selectedTicket ? 'fullscreen' : 'large'} closeIcon onClose={() => setOpen(null)}>
            <Modal.Header><h3>Support requests for <b>{`${user!.userEmail}`}</b></h3></Modal.Header>
            <Modal.Content>
                {loading ?
                    <div className={styles.TicketLoader}>
                        <h3>Loading tickets</h3><l-leapfrog
                        size="30"
                        speed="2.5"
                        color="black"
                    ></l-leapfrog>
                    </div> :
                    <div className={styles.RequestModalContainer}>
                    <div className={styles.RequestModalDiv}>
                        {tickets.map((ticket, index) => {
                            return (
                                <div key={`ticket-${index}`} className={styles.TicketDiv + (selectedTicket && selectedTicket.ticketId === ticket.ticketId ? ' ' + styles.SelectedTicket : '')}
                                     onClick={() => {
                                         if (selectedTicket && selectedTicket.ticketId === ticket.ticketId) {
                                            setSelectedTicket(null);
                                            return;
                                        }
                                         setSelectedTicket(ticket)
                                     }}
                                >
                                    <h4 className={styles.Description}>{
                                        statusConverter(ticket.status).header === 'Closed' ?
                                            <s>{`${ticket.ticketId} - ${ticket.description}`}</s> :
                                            `${ticket.ticketId} - ${ticket.description}`}</h4>
                                    <div className={styles.AboutPreview}>{ticket.about}</div>
                                    <p style={{fontSize: "12px"}}><b>
                                        {`${moment(ticket.createDate).format('MMMM Do YYYY, h:mm:ss a')}`}{ticket.updateDate && ` - updated ${calculateTimeSince(ticket.updateDate)}`}
                                    </b></p>
                                </div>
                            )
                        })}
                    </div>
                    {selectedTicket && <div className={styles.TicketPreview}>
                        <span>
                            <h3>{selectedTicket.description}</h3>
                            <div className={styles.IssueTagDiv + ' ' + statusConverter(selectedTicket.status).style}>
                                {statusConverter(selectedTicket.status).header}</div>
                        </span>
                        <p><b>User:</b> {selectedTicket.userEmail}</p>
                        <span>
                            <p><b>Priority:</b></p> <div className={styles.IssueTagDiv + ' ' + priorityConverter(selectedTicket.priority).style}>
                                {selectedTicket.priority}
                            </div>
                        </span>
                        <p>{selectedTicket.about}</p>
                        {selectedTicket.attachments.length > 0 && <div className={styles.AttachmentContainer}>
                            <h5>Attachments:</h5>
                            <div className={styles.AttachmentGrid}>
                                {attachments.map((attachment, index) => {
                                    return (
                                        <div key={`attachment-${index}`} className={styles.AttachmentDiv} onClick={() => setFullScreenImage(attachment)}>
                                            <img src={attachment.src} alt={attachment.fileName} className={styles.AttachmentImage}/>
                                            <p>{attachment.fileName}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>}
                        <div className={styles.AddACommentDiv}>
                            <h5>Add a comment:</h5>
                            <TextArea
                                className={styles.CommentInput}
                                type={"text"}
                                placeholder="Comment..." />
                        </div>
                        {selectedTicket.comments && selectedTicket.comments.length > 0 && <div className={styles.CommentContainer}>
                            <h5>Comments:</h5>
                            {selectedTicket.comments.sort((a: any, b: any) =>
                                moment(b.createDate).toDate().getTime() - moment(a.createDate).toDate().getTime())
                                .map((comment: any, index: number) => {
                                return (
                                    <div key={`comment-${index}`} className={styles.CommentDiv}>
                                        <p><b>{comment.author.displayName}</b> - {moment(comment.createDate).format('MMMM Do YYYY, h:mm:ss a')}</p>
                                        {formatCommentText(comment.content)}
                                    </div>
                                )
                            })}
                        </div>}
                    </div>}
                </div>}
                {fullScreenImage &&
                    <Modal open={true}
                           closeIcon
                           onClose={() => setFullScreenImage(null)}
                           className={styles.ImageModal}
                           style={{
                               width: "auto",
                               height: "auto",
                    }}>
                        <Modal.Content style={{
                            margin: 0,
                            padding: 0,
                        }}>
                            <img src={fullScreenImage.src} alt={fullScreenImage.fileName} className={styles.FullScreenImage}/>
                        </Modal.Content>
                    </Modal>
                }
            </Modal.Content>
        </Modal>
    )
}