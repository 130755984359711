
export default function generateWBSPath(wbsMap, wbsId) {
    let wbsItem = wbsMap.get(wbsId)
    if (!wbsItem) {
        return {wbsPath: ''}
    }
    wbsItem = wbsMap.get(wbsItem.data.parent_wbs_id)
    if (!wbsItem || wbsItem.data.proj_node_flag === 'Y') {
        return {wbsPath: ''}
    }
    let wbsPath = wbsItem.data.wbs_name
    let wbsParent = wbsItem
    if (!wbsParent) {
        return {wbsPath: ''}
    }

    while (wbsParent.data.proj_node_flag === 'N') {
        wbsItem = wbsMap.get(wbsItem.data.parent_wbs_id)
        wbsParent = wbsMap.get(wbsItem.data.parent_wbs_id)
        if (!wbsParent || !wbsItem) {
            return {wbsPath: wbsPath}
        }
        wbsPath = wbsItem.data.wbs_name + ' / ' + wbsPath
    }
    return {wbsPath: wbsPath}
}